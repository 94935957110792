import React, { useState } from "react";
import PropTypes from "prop-types";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { Box, Typography, TextField } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const VehicleList = ({ vehicles, onEdit, onDelete, showVehicleForm }) => {
  const [searchText, setSearchText] = useState("");

  const columns = [
    { field: "alias", headerName: "Alias", width: 200 },
    { field: "modelo", headerName: "Modelo", width: 200 },
    { field: "marca", headerName: "Marca", width: 200 },
    { field: "placas", headerName: "Placas", width: 200 },
    { field: "no_Economico", headerName: "No. Económico", width: 200 },
    // {
    //   field: 'actions',
    //   type: 'actions',
    //   headerName: 'Acciones',
    //   width: 150,
    //   getActions: (params) => [
    //     <GridActionsCellItem
    //       icon={<EditIcon />}
    //       label="Editar"
    //       onClick={() => onEdit(params.row)}
    //     />,
    //     <GridActionsCellItem
    //       icon={<DeleteIcon />}
    //       label="Eliminar"
    //       onClick={() => onDelete(params.row)}
    //     />,
    //   ],
    // },
  ];

  // Agregar la columna de acciones si showVehicleForm es false
  if (!showVehicleForm) {
    columns.unshift({
      // primera columna
      field: "imei",
      headerName: "IMEI",
      width: 200,
    });

    columns.push({
      field: "actions",
      type: "actions",
      headerName: "Acciones",
      width: 150,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Editar"
          onClick={() => onEdit(params.row)}
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Eliminar"
          onClick={() => onDelete(params.row)}
        />,
      ],
    });
  }

  // Filtrar las filas según el texto de búsqueda
  const filteredVehicles = vehicles.filter((vehicle) => {
    // Comprobamos que cada valor de la fila se pueda convertir a string y que contenga la búsqueda
    return Object.values(vehicle).some((value) => {
      // Convertir el valor a string y comprobar si incluye el texto de búsqueda
      const strValue = value ? value.toString().toLowerCase() : "";
      return strValue.includes(searchText.toLowerCase());
    });
  });

  console.log("Texto de búsqueda:", searchText); // Debugging para ver el texto de búsqueda
  console.log("Vehículos filtrados:", filteredVehicles); // Debugging para ver los vehículos filtrados

  return (
    <Box sx={{ width: "100%", mt: 4 }}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography variant="h6" gutterBottom>
            Listado de Vehículos
          </Typography>
        </Box>

        {/* Campo de búsqueda pequeño y alineado a la derecha */}
        <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
          <TextField
            label="Buscar"
            variant="outlined"
            size="small" // Campo de búsqueda pequeño
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </Box>
      </Box>

      <Box sx={{ height: 500, width: "100%" }}>
        <DataGrid
          rows={filteredVehicles}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5, 10, 25]}
          disableSelectionOnClick
          sx={{
            "& .MuiDataGrid-cell:hover": {
              color: "primary.main",
            },
          }}
        />
      </Box>
    </Box>
  );
};

VehicleList.propTypes = {
  vehicles: PropTypes.array.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default VehicleList;
