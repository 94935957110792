// src/components/Index.js
import React, { useState, useEffect } from "react";
import VehicleForm from "./VehicleForm";
import VehicleList from "./VehicleList";
import { Box, Typography, Paper } from "@mui/material";
import { vehiculoService } from "../../services/apiService";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { ToastContainer, toast } from "react-toastify";

const Index = () => {
  const [editId, setEditId] = useState(null);
  const [imei, setImei] = useState("");
  const [alias, setAlias] = useState("");
  const [modelo, setModelo] = useState("");
  const [marca, setMarca] = useState("");
  const [placas, setPlacas] = useState("");
  const [no_Economico, setNoEconomico] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [vehicles, setVehicles] = useState([]);
  const [confirmDialogOpen, setConfirmDialogOpen] = React.useState(false);
  const [selectedVehiculo, setSelectedVehiculo] = React.useState(null);
  const showVehicleForm = process.env.REACT_APP_SUPERUSERACTIVATE === "false";

  // Metodo para agregar/editar nuevo vehiculo usando servicioVehiculo
  const handleSaveVehicle = async (vehicleData) => {
    try {
      if (editMode) {
        // Si es modo edición, agrega el ID del vehículo al objeto `vehicleData`
        const dataToSend = {
          ...vehicleData, // Incluye todos los demás campos
          id: editId,
          status: 1, // Agrega el ID del vehículo que se está editando
        };

        // Si es modo edición, actualiza el vehículo
        const response = await vehiculoService.updateVehiculo(dataToSend);
        if (response.statusCode === 200) {
          toast.success("Vehículo actualizado exitosamente");
        }
      } else {
        // Si no es edición, crea un nuevo vehículo
        const response = await vehiculoService.createVehiculo(vehicleData);
        if (response.statusCode === 200) {
          toast.success("Vehículo creado exitosamente");
        }
      }
      loadVehiculos(); // Recargar la lista de vehículos después de agregar o editar
      setEditMode(false); // Establecer en `false` después de guardar para preparar para la creación de nuevo vehículo
    } catch (error) {
      console.error("Error al guardar vehículo:", error);
      toast.error("Error al guardar el vehículo");
    }
  };
  //Metodo para Listar todos los vehiculos usando ServicioVehiculo
  const loadVehiculos = async () => {
    try {
      const response = await vehiculoService.getVehiculos("", 1);
      if (response.statusCode === 200) {
        setVehicles(response.data);
      }
    } catch (error) {
      console.error("Error cargando vehículos:", error);
    }
  };
  // Metodos para confirmar y eliminar Vehiculo
  const confirmDeleteVehiculo = (vehiculo) => {
    setSelectedVehiculo(vehiculo);
    setConfirmDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    setConfirmDialogOpen(false); // cierre del diálogo
    if (selectedVehiculo) {
      await handleDelete(selectedVehiculo); // Llama al método para eliminar
      setSelectedVehiculo(null); // Resetea el vehículo seleccionado
    }
  };

  const handleCancelDelete = () => {
    setConfirmDialogOpen(false); // Cierra el diálogo sin hacer nada
    setSelectedVehiculo(null);
  };

  const handleDelete = async (vehiculo) => {
    try {
      const response = await vehiculoService.deleteVehiculo(vehiculo.id);
      console.log(response);
      if (response.statusCode === 200) {
        toast.success("Vehículo eliminado exitosamente");
        loadVehiculos();
      }
    } catch (error) {
      console.error("Error eliminando Vehiculo:", error);
      toast.error("Error al eliminar el vehículo");
    }
  };

  //metodo para editar Vehiculo usando el servicioVehiculo

  const handleEdit = async (vehiculo) => {
    //Actualizamos estados de nombre en el formulario de captura
    setImei(vehiculo.imei);
    setAlias(vehiculo.alias);
    setModelo(vehiculo.modelo);
    setMarca(vehiculo.marca);
    setPlacas(vehiculo.placas);
    setNoEconomico(vehiculo.no_Economico);
    setEditId(vehiculo.id);
    setEditMode(true);
  };

  // Cargar vehiculos al montar
  useEffect(() => {
    loadVehiculos();
  }, []);

  return (
    <Paper sx={{ p: 2 }}>
      <Box sx={{ padding: 3 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Gestión de Vehículos
        </Typography>
        {!showVehicleForm && (
          <VehicleForm
            onSaveVehicle={handleSaveVehicle}
            initialValues={
              editMode
                ? {
                    IMEI: imei,
                    Alias: alias,
                    Modelo: modelo,
                    Marca: marca,
                    Placas: placas,
                    No_Economico: no_Economico,
                  }
                : null
            }
          />
        )}
        <VehicleList
          vehicles={vehicles}
          onEdit={handleEdit}
          onDelete={confirmDeleteVehiculo}
          showVehicleForm = {showVehicleForm}
        />
      </Box>

      <Dialog open={confirmDialogOpen} onClose={handleCancelDelete}>
        <DialogTitle>Confirmar eliminación</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro de que deseas eliminar el vehículo "
            {selectedVehiculo?.alias}"?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleConfirmDelete} color="secondary" autoFocus>
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer />
    </Paper>
  );
};

export default Index;
