// GoogleMapView.js
import React, { useState, useEffect, useRef,memo,useMemo } from 'react';
import {
  GoogleMap,
  InfoWindow,
  Marker,
  OverlayView,
  Polygon,useGoogleMap,Circle
} from '@react-google-maps/api';
import { Box, Paper, FormControl, InputLabel, 
  Select, MenuItem, Checkbox, ListItemText,Typography,
  Autocomplete,
  TextField,
  FormControlLabel,
  Divider } from '@mui/material';
import { createVehicleMarkerHTML } from '../../utils/markerUtils';

import { getAlertIcon } from '../../utils/alertUtils';
import useGeocercas from '../../services/useGeocercas';
import { createPopupContent } from '../../utils/popupUtils';
import { RenderPolygon, RenderCircle } from '../../utils/MapShapes';

import { vehiculoService, eventoService } from '../../services/apiService';

const ActiveAlertOverlay = memo(({ alert, onClose }) => {
  const position = useMemo(() => {
    if (!alert) return null;
    return {
      lat: alert?.ubicacion?.lat,
      lng: alert?.ubicacion?.lng,
    };
  }, [alert]);

  if (!alert || !position) return null;

  return (
    <OverlayView
      position={{
        lat: alert.ubicacion.lat,
        lng: alert.ubicacion.lng,
      }}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      getPixelPositionOffset={(width, height) => ({
        x: -(width / 2),
        y: -height,
      })}
    >
      <div className="alert-container" style={{ position: 'relative', width: 'auto', height: 'auto', display: 'inline-block', transform: 'translate(-50%, -100%)', animation: 'fadeIn 0.3s ease-in-out' }}>
        <style>
          {`
            @keyframes fadeIn {
              from { opacity: 0; transform: translate(-50%, -90%); }
              to { opacity: 1; transform: translate(-50%, -100%); }
            }
            @keyframes bounce {
              0%, 100% { transform: translateY(0); }
              50% { transform: translateY(-5px); }
            }
            .alert-container {
              position: relative;
              padding-bottom: 15px; /* Espacio para la flecha */
            }
            .vehicle-icon_2 {
              animation: bounce 1s ease-in-out infinite;
            }
            .alert-icons2 {
              display: flex; 
              align-items: center !important;  
              justify-content: center !important;
              position: relative;
            }
            .popup-card {
              position: relative;
              border-radius: 8px;
              box-shadow: 0 2px 4px rgba(0,0,0,0.2);
              margin-bottom: 15px; /* Espacio para la flecha */
            }
            /* Triángulo principal */
            .popup-card:after {
              content: '';
              position: absolute;
              bottom: -15px; /* Ajustado para hacer la flecha más visible */
              left: 50%;
              transform: translateX(-50%);
              border-left: 15px solid transparent;
              border-right: 15px solid transparent;
              border-top: 15px solid white;
            }
            /* Sombra del triángulo */
            .popup-card:before {
              content: '';
              position: absolute;
              bottom: -16px;
              left: 50%;
              transform: translateX(-50%);
              border-left: 16px solid transparent;
              border-right: 16px solid transparent;
              border-top: 16px solid rgba(0,0,0,0.1);
              z-index: -1;
            }
          `}
        </style>
        
        <div style={{ position: 'absolute', top: 0, right: 0, zIndex: 10 }}>
          <button className="close-button clear-alert-button" onClick={onClose}>
            &times;
          </button>
        </div>
        
        <div dangerouslySetInnerHTML={{ __html: createPopupContent(alert) }} />
        
        <div className="alert-icons2">
          <div className="vehicle-icon_2" style={{ fontSize: '32px' }}>🚗</div>
        </div>
      </div>
    </OverlayView>
  );
});

  const createCustomMarker = (alert) => {
    const div = document.createElement('div');
    div.className = 'custom-marker';
    div.innerHTML = createVehicleMarkerHTML(alert);
    return div;
  };


const ActiveMarkerOverlay = ({ alert, onClose }) => {
  if (!alert) return null;

  return (
    <OverlayView
      position={{ lat: alert.ubicacion.lat, lng: alert.ubicacion.lng }}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      getPixelPositionOffset={(width, height) => ({
        x: -(width / 2),
        y: -height,
      })}
    >
      <div className="active-marker-container">
        <div className="pulse-animation" />
        <div className="vehicle-icon">🚗</div>
        <img
          src={getAlertIcon(alert.alerta)}
          alt={alert.alerta}
          className="alert-icon"
        />
        <button onClick={onClose} className="close-button">
          &times;
        </button>
      </div>
    </OverlayView>
  );
};

const UserLocationMarker = ({ position, map }) => {
  useEffect(() => {
    if (!map || !window.google) return;   

    const homeIcon = document.createElement('div');
    homeIcon.innerHTML = `
      <div class="user-marker-container">
        <div class="pulse"></div>
        <div style="
          font-size: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
        ">
          🏠
        </div>
      </div>
    `;

    const marker = new window.google.maps.marker.AdvancedMarkerElement({
      position: position,
      map: map,
      content: homeIcon,
      title: 'Tu ubicación actual'
    });

    return () => {
      marker.setMap(null);
    };
  }, [position, map]);

  return null;
};

const VehicleMarkers = ({ alerts = [], map }) => {
  const markersRef = useRef({});
  const infoWindowRef = useRef(null);
  const [selectedAlert, setSelectedAlert] = React.useState(null);

  useEffect(() => {
    alerts.forEach((alert) => {
      const { id, ubicacion, unidad, estado } = alert;
      const position = { lat: parseFloat(ubicacion.lat), lng: parseFloat(ubicacion.lng) };

      if (isNaN(position.lat) || isNaN(position.lng)) return;

      if (!markersRef.current[id]) {
       
        const marker = new window.google.maps.marker.AdvancedMarkerElement({
                    map,
                    position,
                    title: `Unidad: ${unidad}`,
                    content: createCustomMarker(alert),
                  });

        marker.addListener('click', () => {
          setSelectedAlert(alert);
        });

        markersRef.current[id] = marker;
      } else {
        markersRef.current[id].setPosition(position);
      }
    });

    // Remover marcadores inexistentes
    Object.keys(markersRef.current).forEach((id) => {
      if (!alerts.find((alert) => alert.id === id)) {
        markersRef.current[id].setMap(null);
        delete markersRef.current[id];
      }
    });

    return () => {
      Object.values(markersRef.current).forEach((marker) => marker.setMap(null));
      markersRef.current = {};
    };
  }, [alerts, map]);

  return selectedAlert ? (
    <InfoWindow
      position={{ lat: parseFloat(selectedAlert.ubicacion.lat), lng: parseFloat(selectedAlert.ubicacion.lng) }}
      onCloseClick={() => setSelectedAlert(null)}
    >
       <div className="custom-popup-content" dangerouslySetInnerHTML={{ __html: createPopupContent(selectedAlert) }} />

      {/* <div dangerouslySetInnerHTML={{ __html: createPopupContent(selectedAlert) }} /> */}
    </InfoWindow>
  ) : null;
};


const MapResizer = ({ expandMap, map }) => {
    useEffect(() => {
      if (map) {
        window.google.maps.event.trigger(map, 'resize');
      }
    }, [expandMap, map]);
  
    return null;
  }; 

  const calcularCentroGeocerca = (geocerca) => {
    if (geocerca.puntos.type === 'Point') {
      return {
        lat: Number(geocerca.puntos.puntos[0].latitud),
        lng: Number(geocerca.puntos.puntos[0].longitud),
      };
    } else {
      const points = geocerca.puntos.puntos;
      const lat = points.reduce((sum, p) => sum + Number(p.latitud), 0) / points.length;
      const lng = points.reduce((sum, p) => sum + Number(p.longitud), 0) / points.length;
      return { lat, lng };
    }
  };

  function CenterMapOnGeocerca({ geocercas, selectedGeocercas }) {
    const map = useGoogleMap();
  
    useEffect(() => {
      if (selectedGeocercas.length === 1) {
        const selectedGeocerca = geocercas.find(g => g.id === selectedGeocercas[0]);
        if (selectedGeocerca) {
          let center;
          if (selectedGeocerca.puntos.type === 'Point') {
            center = {
              lat: Number(selectedGeocerca.puntos.puntos[0].latitud),
              lng: Number(selectedGeocerca.puntos.puntos[0].longitud),
            };
          } else {
            // Para polígonos, calcular el centro
            const points = selectedGeocerca.puntos.puntos;
            const lat = points.reduce((sum, p) => sum + Number(p.latitud), 0) / points.length;
            const lng = points.reduce((sum, p) => sum + Number(p.longitud), 0) / points.length;
            center = { lat, lng };
          }
          if (map) {
            map.panTo(center);
            map.setZoom(15); // Zoom level 15 para mejor vista
          }
        }
      }
    }, [selectedGeocercas, geocercas, map]);
  
    return null;
  }

  
  const MapViewUpdater = ({ center, activeAlert, map }) => {
    useEffect(() => {
      if (map && center) {
        const formattedCenter = Array.isArray(center)
          ? { lat: center[0], lng: center[1] }
          : center;
  
        if (Number.isFinite(formattedCenter.lat) && Number.isFinite(formattedCenter.lng)) {
          console.log('Panning to:', formattedCenter);
          map.panTo(formattedCenter);
        } else {
          console.error('Coordenadas inválidas:', formattedCenter);
        }
      }
    }, [center, map]);
  
    useEffect(() => {
      if (map && activeAlert) {
        const { lat, lng } = activeAlert.ubicacion;
        if (Number.isFinite(lat) && Number.isFinite(lng)) {
          map.panTo({ lat, lng });
        } else {
          console.error('Coordenadas inválidas en activeAlert:', activeAlert.ubicacion);
        }
      }
    }, [activeAlert, map]);
  
    return null;
  };

  const animateMarker = (marker, toPosition, duration = 1000) => {
    console.log('Animating marker:', marker);
    
    // Obtener la posición actual del marcador
    const fromPosition = marker.position;
    
    // Verificar que 'fromPosition' y 'toPosition' existen
    if (!fromPosition || !toPosition) {
      console.error('fromPosition o toPosition son undefined:', fromPosition, toPosition);
      return;
    }
    
    // Obtener latitudes y longitudes
    const fromLat = typeof fromPosition.lat === 'function' ? fromPosition.lat() : fromPosition.lat;
    const fromLng = typeof fromPosition.lng === 'function' ? fromPosition.lng() : fromPosition.lng;
    const toLat = typeof toPosition.lat === 'function' ? toPosition.lat() : toPosition.lat;
    const toLng = typeof toPosition.lng === 'function' ? toPosition.lng() : toPosition.lng;

    // Validar que todas las coordenadas sean números
    if (isNaN(fromLat) || isNaN(fromLng) || isNaN(toLat) || isNaN(toLng)) {
      console.error('Coordenadas inválidas:', { fromLat, fromLng, toLat, toLng });
      return;
    }
    
    let startTime = null;
  
    const animateStep = (currentTime) => {
      if (!startTime) {
        startTime = currentTime;
      }
      const progress = (currentTime - startTime) / duration;
      if (progress < 1) {
        const deltaLat = fromLat + (toLat - fromLat) * progress;
        const deltaLng = fromLng + (toLng - fromLng) * progress;
        
        // Crear una nueva instancia de LatLng con los valores interpolados
        const newPosition = new window.google.maps.LatLng(deltaLat, deltaLng);
        
        // Actualizar la posición del marcador
        marker.position = newPosition;
        
        requestAnimationFrame(animateStep);
      } else {
        // Asegurar que la posición final es exacta
        const finalPosition = new window.google.maps.LatLng(toLat, toLng);
        marker.position = finalPosition;
      }
    };
  
    requestAnimationFrame(animateStep);
  };



const GoogleMapView = ({
  center,
  alerts,
  activeAlert,
  onClearActiveAlert,
  expandMap,
  onLoadComplete,
  viewMode
}) => {
  const [userLocation, setUserLocation] = useState(null);
  const mapRef = useRef(null);
  const [selectedGeocercas, setSelectedGeocercas] = useState([]);
  const { geocercas } = useGeocercas();
  const GOOGLE_MAP_ID = 'map-monitor';

  const [selectAllGeocercas, setSelectAllGeocercas] = useState(true);
  const [selectAllVehiculos, setSelectAllVehiculos] = useState(true);
  const [selectedVehiculos, setSelectedVehiculos] = useState([]);
  const [lastSelectedGeocerca, setLastSelectedGeocerca] = useState(null);
  const [vehiculos, setVehiculos] = useState([]);


  //console.log('GoogleMapView center:', center);
  const formattedCenter = Array.isArray(center)
  ? { lat: center[0], lng: center[1] }
  : center;

console.log('GoogleMapView center:', formattedCenter);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        (error) => console.error('Error getting user location:', error)
      );
    }
  }, []);

  useEffect(() => {
    if (geocercas.length > 0) {
      setSelectedGeocercas(geocercas.map(g => g.id));
    }
  }, [geocercas]);

  const onLoad = (map) => {
    mapRef.current = map;
    if (onLoadComplete) onLoadComplete('map');
  };

  const mapContainerStyle = {
    width: '100%',
    height: '100%',
    minHeight: '430px'
  };

  // const handleGeocercaSelection = (event) => {
  //   const value = event.target.value;
    
  //   // Handle "Select All" option
  //   if (value.includes('all')) {
  //     if (selectedGeocercas.length === geocercas.length) {
  //       // If all were selected, deselect all
  //       setSelectedGeocercas([]);
  //     } else {
  //       // Select all
  //       setSelectedGeocercas(geocercas.map(g => g.id));
  //     }
  //   } else {
  //     // Handle individual selections
  //     setSelectedGeocercas(value);
      
  //     // If all items are now selected, add "all" to selection
  //     if (value.length === geocercas.length) {
  //       setSelectedGeocercas([...value, 'all']);
  //     }
  //   }
  // };

  const handleToggleSelectAllGeocercas = (e) => {
    e.preventDefault();
    setSelectAllGeocercas(!selectAllGeocercas);
    setSelectedGeocercas(selectAllGeocercas ? [] : geocercas.map(g => g.id));
  };

  const handleToggleSelectAllVehiculos = (e) => {
    e.preventDefault();
    setSelectAllVehiculos(!selectAllVehiculos); 
    setSelectedVehiculos(selectAllVehiculos ? [] : vehiculos.map(v => v.id));
  };

  // 4. Agregar useEffect para cargar vehículos
  useEffect(() => {
    const loadVehiculos = async () => {
      try {
        const response = await vehiculoService.getVehiculos();
        const vehiculosData = response.data || [];
        setVehiculos(vehiculosData);
        setSelectedVehiculos(vehiculosData.map(v => v.id));
        setSelectAllVehiculos(true);
      } catch (error) {
        console.error('Error cargando vehículos:', error);
      }
    };
    loadVehiculos();
  }, []);

  // const GeofencePointMarker = ({ position, nombre }) => {
  //   const mapRef = useGoogleMap(); // Get map context
  
  //   useEffect(() => {
  //     if (!mapRef || !window.google) return;
  
  //     const marker = new window.google.maps.marker.AdvancedMarkerElement({
  //       position,
  //       map: mapRef,
  //       title: nombre,
  //       content: createGeocercaMarker(nombre)
  //     });
  
  //     return () => {
  //       marker.setMap(null);
  //     };
  //   }, [position, nombre, mapRef]);
  
  //   return null;
  // };

  // const GeofencePointMarker = ({ position, nombre, radius }) => {
  //   const mapRef = useGoogleMap();
  
  //   useEffect(() => {
  //     if (!mapRef || !window.google) return;
  
  //     const element = document.createElement('div');
  //     element.innerHTML = `
  //       <div class="label-container">
  //         <div class="label-content">
  //           <span class="label-icon">📍</span>
  //           <span class="label-text">${nombre}</span>
  //         </div>
  //       </div>
  //     `;
  
  //     const marker = new window.google.maps.marker.AdvancedMarkerElement({
  //       position,
  //       map: mapRef,
  //       content: element,
  //       zIndex: 2
  //     });
  
  //     return () => {
  //       marker.setMap(null);
  //     };
  //   }, [position, nombre, mapRef]);
  
  //   return (
  //     <Circle 
  //       center={position}
  //       radius={radius}
  //       options={{
  //         fillColor: '#4285F4',
  //         strokeColor: '#4285F4',
  //         fillOpacity: 0.1,
  //         strokeOpacity: 0.4,
  //         strokeWeight: 2,
  //         zIndex: 1
  //       }}
  //     />
  //   );
  // };

  // const GeofenceLabel = memo(({ position, nombre }) => {
  //   return (
  //     <OverlayView
  //       position={position}
  //       mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
  //       getPixelPositionOffset={(width, height) => ({
  //         x: -(width / 2),
  //         y: -(height / 2)
  //       })}
  //     >
  //       <div 
  //         className="label-container"
  //         style={{
  //           position: 'relative',
  //           transform: 'translate3d(0,0,0)',
  //           willChange: 'transform',
  //           transition: 'transform 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
  //           padding: '4px 8px',
  //           background: '',
  //           borderRadius: '4px',
  //           boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
  //           whiteSpace: 'nowrap'
  //         }}
  //       >
  //         <span className="label-icon" style={{ marginRight: '4px' }}>📍</span>
  //         <span className="label-text">{nombre}</span>
  //       </div>
  //     </OverlayView>
  //   );
  // });

  // const OptimizedPolygon = memo(({ paths, options }) => {
  //   const polygonOptions = useMemo(() => ({
  //     ...options,
  //     // Optimizaciones visuales
  //     geodesic: true,
  //     clickable: true,
  //     strokeWeight: 2,
  //     fillOpacity: 0.2,
  //     // Optimización de renderizado
  //     zIndex: 1,
  //     // Mejorar el rendimiento
  //     optimized: true,
  //     // Suavizar bordes
  //     strokeOpacity: 0.8,
  //     // Habilitar aceleración por hardware
  //     clickable: false,
  //     draggable: false
  //   }), [options]);
  
  //   return (
  //     <Polygon
  //       paths={paths}
  //       options={polygonOptions}
  //     />
  //   );
  // });

  // const GeofencePolygon = memo(({ geocerca }) => {
  //   const paths = useMemo(() =>
  //     geocerca.puntos.puntos.map(coord => ({
  //       lat: Number(coord.latitud),
  //       lng: Number(coord.longitud),
  //     }))
  //   , [geocerca.puntos.puntos]);
  
  //   const center = useMemo(() => {
  //     if (!window.google || !window.google.maps) return null;
  
  //     // Crear un objeto LatLngBounds
  //     const bounds = new window.google.maps.LatLngBounds();
  
  //     // Extender los límites con cada punto del polígono
  //     paths.forEach((path) => {
  //       bounds.extend(path);
  //     });
  
  //     // Obtener el centro de los límites
  //     const centerPoint = bounds.getCenter();
  //     return centerPoint;
  //   }, [paths]);
  
  //   if (!center) return null;
  
  //   return (
  //     <>
  //       <OptimizedPolygon
  //         paths={paths}
  //         options={{
  //           fillColor: '#4285F4',
  //           strokeColor: '#4285F4',
  //           fillOpacity: 0.2,
  //           strokeOpacity: 0.8,
  //           strokeWeight: 2,
  //         }}
  //       />
  //       <GeofenceLabel
  //         position={{ lat: center.lat(), lng: center.lng() }}
  //         nombre={geocerca.nombre}
  //       />
  //     </>
  //   );
  // });
  
  const GeofenceLabel = memo(({ position, nombre }) => {
    return (
      <OverlayView
        position={position}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        getPixelPositionOffset={(width, height) => ({
          x: 0,
          y: 0,
        })}
      >
        <div
          className="label-container"
          style={{
            position: 'relative',
            transform: 'translate3d(0,0,0)',
            willChange: 'transform',
            transition: 'transform 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
            padding: '4px 8px',
            background: '',
            borderRadius: '4px',
            boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
            whiteSpace: 'nowrap',
          }}
        >
          <span className="label-icon" style={{ marginRight: '4px' }}>📍</span>
          <span className="label-text">{nombre}</span>
        </div>
      </OverlayView>
    );
  });
  
  const OptimizedPolygon = memo(({ paths, options }) => {
    const polygonOptions = useMemo(() => ({
      ...options,
      geodesic: true,
      clickable: true,
      strokeWeight: 2,
      fillOpacity: 0.2,
      zIndex: 1,
      optimized: true,
      strokeOpacity: 0.8,
      clickable: false,
      draggable: false,
    }), [options]);
  
    return <Polygon paths={paths} options={polygonOptions} />;
  });
  
  const GeofencePolygon = memo(({ geocerca }) => {
    const paths = useMemo(() =>
      geocerca.puntos.puntos.map(coord => ({
        lat: Number(coord.latitud),
        lng: Number(coord.longitud),
      }))
    , [geocerca.puntos.puntos]);
  
    const topLeft = useMemo(() => {
      if (!window.google || !window.google.maps) return null;
  
      const bounds = new window.google.maps.LatLngBounds();
      paths.forEach(path => bounds.extend(path));
  
      return bounds.getNorthEast();
    }, [paths]);
  
    if (!topLeft) return null;
  
    return (
      <>
        <OptimizedPolygon
          paths={paths}
          options={{
            fillColor: '#4285F4',
            strokeColor: '#4285F4',
            fillOpacity: 0.2,
            strokeOpacity: 0.8,
            strokeWeight: 2,
          }}
        />
        <GeofenceLabel
          position={{ lat: 0, lng: 0}}
          nombre={geocerca.nombre}
        />
      </>
    );
  });
  

  


  const GeofencePointMarker = memo(({ position, nombre, radius }) => {
    const mapRef = useGoogleMap();
    
    useEffect(() => {
      if (!mapRef || !window.google) return;
  
      const element = document.createElement('div');
      element.innerHTML = `
        <div class="label-container">
          <div class="label-content">
            <span class="label-icon">📍</span>
            <span class="label-text">${nombre}</span>
          </div>
        </div>
      `;
  
      const marker = new window.google.maps.marker.AdvancedMarkerElement({
        position,
        map: mapRef,
        content: element,
        zIndex: 2
      });
  
      return () => {
        marker.setMap(null);
      };
    }, [position, nombre, mapRef]);
  
    return (
      <Circle 
        center={position}
        radius={radius}
        options={{
          fillColor: '#4285F4',
          strokeColor: '#4285F4',
          fillOpacity: 0.1,
          strokeOpacity: 0.4,
          strokeWeight: 2,
          zIndex: 1
        }}
      />
    );
  });

  const GeofenceRenderer = memo(({ geocercas, selectedGeocercas }) => {
    return (
      <>
        {geocercas
          .filter((geocerca) => selectedGeocercas.includes(geocerca.id))
          .map((geocerca) => {
            if (geocerca.puntos.type === 'Polygon') {
              return (
                <GeofencePolygon 
                  key={geocerca.id} 
                  geocerca={geocerca} 
                />
              );
            }
            
            if (geocerca.puntos.type === 'Point') {
              const position = {
                lat: Number(geocerca.puntos.puntos[0].latitud),
                lng: Number(geocerca.puntos.puntos[0].longitud),
              };
              return (
                <GeofencePointMarker
                  key={geocerca.id}
                  position={position}
                  nombre={geocerca.nombre}
                  radius={geocerca.radio}
                />
              );
            }
            
            return null;
          })}
      </>
    );
  });


  const styles = `

   .label-container {
  pointer-events: none;
  user-select: none;
  will-change: transform;
  transform: translate3d(0,0,0);
  backface-visibility: hidden;
  perspective: 1000px;
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.custom-user-location {
      background: transparent;
    }

    .user-marker-container {
      position: relative;
      width: 40px;
      height: 40px;
    }

    .pulse {
      position: absolute;
      left: 50%;
      top: 50%;
      width: 30px;
      height: 30px;
      background: rgba(66, 133, 244, 0.2);
      border-radius: 50%;
      transform: translate(-50%, -50%);
      animation: pulse 2s infinite;
    }

    .marker {
      position: absolute;
      left: 50%;
      top: 50%;
      z-index: 1;
    }

    @keyframes pulse {
      0% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
      }
      100% {
        transform: translate(-50%, -50%) scale(3);
        opacity: 0;
      }
    }
      
  .custom-alert-icon {
    background: transparent;
  }
  
  .alert-icon {
     position: absolute;
     right: 20px;
     top: 8px;     
     padding: 0px;
     border-radius: 50%;
   }

  .alert-marker-container {
    position: relative;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .alert-pulse {
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    animation: alertPulse 2s infinite;
  }

  .exceso-de-velocidad .alert-pulse {
    background: rgba(255, 0, 0, 0.2);
  }

  .detenido .alert-pulse {
    background: rgba(255, 165, 0, 0.2);
  }

  .encendido-motor .alert-pulse {
    background: rgba(0, 255, 0, 0.2);
  }

  .apagado-de-motor .alert-pulse {
    background: rgba(128, 128, 128, 0.2);
  }

  @keyframes alertPulse {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(2);
      opacity: 0;
    }
  }

  .vehicle-marker-container {
    position: relative;
    width: 60px;
    height: 40px;
  }

  .vehicle-icon {
    position: absolute;
    left: 0;
    top: 0;
    font-size: 24px;
  }



  .exceso-de-velocidad {
    background: rgba(255, 0, 0, 0.2);
  }

  .detenido {
    background: rgba(255, 165, 0, 0.2);
  }

  .encendido-motor {
    background: rgba(0, 255, 0, 0.2);
  }

  .apagado-de-motor {
    background: rgba(128, 128, 128, 0.2);
  }

  .puerta-cerrada .alert-pulse {
    background: rgba(0, 0, 255, 0.2);
  }
  
  .puerta-abierta .alert-pulse {
    background: rgba(255, 0, 255, 0.2);
  }
  
  .acercamiento .alert-pulse {
    background: rgba(255, 255, 0, 0.2);
  }
  
  .boton-de-panico .alert-pulse {
    background: rgba(255, 0, 0, 0.4);
  }
  
  .entrada .alert-pulse {
    background: rgba(0, 255, 0, 0.2);
  }
  
  .salida .alert-pulse {
    background: rgba(255, 165, 0, 0.2);
  }

   .gm-style-iw-chr {
            height: 0px;
        }

        .gm-style .gm-style-iw-c {
            padding: 0 !important;
        }

        .gm-style-iw {
            padding: 0 !important;
        }

        .gm-style-iw-d {
            overflow: hidden !important;
            width: 100% !important;
        }

          .alert-container {
    transition: transform 0.1s ease-out;
    -webkit-font-smoothing: antialiased;
  }

 

  /* Optimizaciones para las geocercas */
  .gm-style-iw {
    transform: translate3d(0,0,0);
    backfaceVisibility: hidden;
  }

  /* Optimizaciones para los marcadores */
  .vehicle-marker-container {
    will-change: transform;
    transform: translate3d(0,0,0);
    backfaceVisibility: hidden;
  }

  /* Optimizar animaciones */
  @keyframes fadeIn {
    from { 
      opacity: 0; 
      transform: translate3d(-50%, -90%, 0);
    }
    to { 
      opacity: 1; 
      transform: translate3d(-50%, -100%, 0);
    }
  }

`;


  return (
    <>
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column',
      height: '100%',
      gap: 2,
      p: 2 
    }}>

{/*     
      <FormControl fullWidth>
          <InputLabel>Geocercas Visibles</InputLabel>
          <Select
            multiple
            value={selectedGeocercas}
            onChange={handleGeocercaSelection}
            renderValue={(selected) => {
              // Don't show "all" in the display text
              const displayValues = selected.filter(id => id !== 'all');
              const selectedNames = geocercas
                .filter(g => displayValues.includes(g.id))
                .map(g => g.nombre)
                .join(', ');
              return selectedNames.length > 50 
                ? selectedNames.substring(0, 50) + '...' 
                : selectedNames;
            }}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 300,
                  width: 250
                }
              },
              variant: "menu"
            }}
          >
          <MenuItem value="all">
            <Checkbox 
              checked={selectedGeocercas.length === geocercas.length}
              indeterminate={selectedGeocercas.length > 0 && selectedGeocercas.length < geocercas.length}
            />
            <ListItemText 
              primary="Seleccionar Todas" 
              primaryTypographyProps={{
                style: { fontWeight: 'bold' }
              }}
            />
          </MenuItem>
          {geocercas.map((geocerca) => (
            <MenuItem key={geocerca.id} value={geocerca.id}>
              <Checkbox checked={selectedGeocercas.includes(geocerca.id)} />
              <ListItemText primary={geocerca.nombre} />
            </MenuItem>
      ))}
          </Select>
         
        </FormControl> */}

         <Box p={2}>
              {viewMode === 'geocercas' ? (
                <Autocomplete
                sx={{ width: '100%' }}
                multiple
                id="geocercas-autocomplete"
                options={geocercas}
                noOptionsText="No hay coincidencias"
                disableCloseOnSelect
                disableListWrap
                getOptionLabel={(option) => option.nombre}
                value={geocercas.filter(g => selectedGeocercas.includes(g.id))}
                onChange={(event, newValue, reason, details) => {
                  switch (reason) {
                    case "clear":
                      setSelectAllGeocercas(false);
                      setSelectedGeocercas([]);
                      break;
                    case "removeOption":
                      const newSelected = newValue.map(g => g.id);
                      setSelectAllGeocercas(newSelected.length === geocercas.length);
                      setSelectedGeocercas(newSelected);
                      break;
                    case "selectOption":
                      if (details?.option) {
                        setLastSelectedGeocerca(details.option.id);
                      }
                      const selectedIds = newValue.map(g => g.id);
                      setSelectAllGeocercas(selectedIds.length === geocercas.length);
                      setSelectedGeocercas(selectedIds);
                      break;
                    default:
                      const ids = newValue.map(g => g.id);
                      setSelectAllGeocercas(ids.length === geocercas.length);
                      setSelectedGeocercas(ids);
                  }
                }}
                renderTags={(value) => 
                  value.length > 0 ? (
                    <Typography variant="body2">
                      {`${value.length} seleccionados`}
                    </Typography>
                  ) : null
                }
                renderOption={(props, option, { selected }) => {
                  const { key, ...optionProps } = props;
                  return (
                    <li key={option.id} {...optionProps}>
                      <Checkbox
                        size="small"
                        style={{ marginRight: 8 }}
                        checked={selectedGeocercas.includes(option.id)}
                      />
                      {option.nombre}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Geocercas"
                    placeholder={selectedGeocercas.length === 0 ? "Todas" : ""}
                  />
                )}
                groupBy={() => ""}
                renderGroup={(params) => (
                  <div key={params.key}>
                    <Box pl={3.5} py={0.5} onMouseDown={(e) => e.preventDefault()}>
                      <FormControlLabel
                        onClick={handleToggleSelectAllGeocercas}
                        label="Seleccionar todas"
                        control={
                          <Checkbox
                            size="small"
                            checked={selectAllGeocercas}
                            indeterminate={selectedGeocercas.length > 0 && selectedGeocercas.length < geocercas.length}
                          />
                        }
                      />
                    </Box>
                    <Divider />
                    {params.children}
                  </div>
                )}
              />
        
                 
                ) : (
                  <Autocomplete
          sx={{ width: '100%' }}
          multiple
          id="vehiculos-autocomplete"
          options={vehiculos}
          noOptionsText="No hay coincidencias"
          disableCloseOnSelect
          disableListWrap
          getOptionLabel={(option) => `${option.alias} ${option.no_Economico ? `- ${option.no_Economico}` : ''}`}
          value={vehiculos.filter(v => selectedVehiculos.includes(v.id))}
          onChange={(event, newValue, reason) => {
            switch (reason) {
              case "clear":
                setSelectAllVehiculos(false);
                setSelectedVehiculos([]);
                break;
              case "removeOption":
                const newSelected = newValue.map(v => v.id);
                setSelectAllVehiculos(newSelected.length === vehiculos.length);
                setSelectedVehiculos(newSelected);
                break;
              case "selectOption":
                const selectedIds = newValue.map(v => v.id);
                setSelectAllVehiculos(selectedIds.length === vehiculos.length);
                setSelectedVehiculos(selectedIds);
                break;
              default:
                const ids = newValue.map(v => v.id);
                setSelectAllVehiculos(ids.length === vehiculos.length);
                setSelectedVehiculos(ids);
            }
          }}
          renderTags={(value) => 
            value.length > 0 ? (
              <Typography variant="body2">
                {`${value.length} seleccionados`}
              </Typography>
            ) : null
          }
          renderOption={(props, option, { selected }) => {
            const { key, ...optionProps } = props;
            return (
              <li key={option.id} {...optionProps}>
                <Checkbox
                  size="small"
                  style={{ marginRight: 8 }}
                  checked={selectedVehiculos.includes(option.id)}
                />
                {`${option.alias} ${option.no_Economico ? `- ${option.no_Economico}` : ''}`}
              </li>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Vehículos"
              placeholder={selectedVehiculos.length === 0 ? "Todos" : ""}
            />
          )}
          groupBy={() => ""}
          renderGroup={(params) => (
            <div key={params.key}>
              <Box pl={3.5} py={0.5} onMouseDown={(e) => e.preventDefault()}>
                <FormControlLabel
                  onClick={handleToggleSelectAllVehiculos}
                  label="Seleccionar todos"
                  control={
                    <Checkbox
                      size="small"
                      checked={selectAllVehiculos}
                      indeterminate={selectedVehiculos.length > 0 && selectedVehiculos.length < vehiculos.length}
                    />
                  }
                />
              </Box>
              <Divider />
              {params.children}
            </div>
          )}
        />
        
                )}
              
              </Box>


      <Box sx={{ 
      flex: 1,
      minHeight: '430px',
      width: '100%'
    }}>

      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={userLocation}
        zoom={12}
        onLoad={onLoad}                 
          mapId={GOOGLE_MAP_ID}
          options={{
            mapId: GOOGLE_MAP_ID,
            disableDefaultUI: false,
            clickableIcons: false,
            scrollwheel: true,
            gestureHandling: 'greedy',
            zoomControl: true,
          }}
          onError={(error) => {
            console.error('Error loading Google Map:', error);
          }}

      >
        {userLocation && <UserLocationMarker position={userLocation} map={mapRef.current} />}
        {/* <VehicleMarkers alerts={alerts} map={mapRef.current} /> */}
        <VehicleMarkers 
          alerts={alerts}
          selectedVehiculos={selectedVehiculos}
          map={mapRef.current} 
        />
        {/* <GeocercasLayer
          geocercas={geocercas}
          selectedGeocercas={selectedGeocercas}
        /> */}
      <MapViewUpdater center={center} activeAlert={activeAlert} />

      {activeAlert && (
  <ActiveAlertOverlay
    alert={activeAlert}
    onClose={onClearActiveAlert}
  />
)}

{/* <GeofenceRenderer geocercas={geocercas} selectedGeocercas={selectedGeocercas} /> */}

{geocercas
        .filter(geocerca => selectedGeocercas.includes(geocerca.id))
        .map(geocerca => {
          if (geocerca.puntos.type === 'Polygon') {
            const paths = geocerca.puntos.puntos.map(coord => ({
              lat: Number(coord.latitud),
              lng: Number(coord.longitud),
            }));

            return (
              <RenderPolygon
                key={geocerca.id}
                paths={paths}
                name={geocerca.nombre}
              />
            );

          } else if (geocerca.puntos.type === 'Point') {
            const position = {
              lat: Number(geocerca.puntos.puntos[0].latitud),
              lng: Number(geocerca.puntos.puntos[0].longitud),
            };
            return (
              <RenderCircle
                key={geocerca.id}
                center={position}
                radius={geocerca.radio}
                name={geocerca.nombre}
              />
            );
          }
          return null;
        })}


        <MapResizer expandMap={expandMap} map={mapRef.current} />

        <CenterMapOnGeocerca
          geocercas={geocercas}
          selectedGeocercas={selectedGeocercas}
          map={mapRef.current}
        />

        <MapViewUpdater
          center={userLocation}
          activeAlert={activeAlert}
          map={mapRef.current}
        />

      </GoogleMap>

      </Box>
   </Box>

   <style>{styles}</style>
</>
  );
};

export default GoogleMapView;