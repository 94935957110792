import React, { useState, useEffect } from 'react';
import { 
  TextField, 
  Autocomplete, 
  Box, 
  Button, 
  Chip,
  Checkbox,
  Paper 
} from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { notificacionService,vehiculoService,eventoService } from '../../services/apiService';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { IconButton, Tooltip } from '@mui/material';
import SelectAllIcon from '@mui/icons-material/DoneAll';
import ClearAllIcon from '@mui/icons-material/RemoveCircle';
import { useLoading } from "../../contexts/LoadingContext";

 const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
 const checkedIcon = <CheckBoxIcon fontSize="small" />;


const FiltersDatabase = ({ fields, filters, setFilters, filtersWithTypes,
   onSearch, dataSource,configId }) => {
  const [vehiculos, setVehiculos] = useState([]);
  const [alertas, setAlertas] = useState([]);
  const [fechaInicial, setFechaInicial] = useState(new Date(new Date().setHours(0, 0, 0, 0)));
  const [fechaFinal, setFechaFinal] = useState(new Date(new Date().setHours(23, 59, 59, 999)));
  const [error, setError] = useState(null);
  //const [isLoading, setIsLoading] = useState(false);
  const [lastConfigId, setLastConfigId] = useState(null);
  const { setIsLoading, setLoadingMessage } = useLoading();

//  const icon = <Checkbox icon={icon} checkedIcon={checkedIcon} />;
// const checkedIcon = <Checkbox icon={icon} checkedIcon={checkedIcon} />;
const [initialized, setInitialized] = useState(false);

// Effect para manejar cambios de configId
useEffect(() => {
  if (configId !== lastConfigId) {
    console.log('Config ID changed:', configId);
    setLastConfigId(configId);
    setInitialized(false);
    // Limpiar filtros solo si ya estaba inicializado
    // if (initialized) {
    //   setFilters({
    //     vehiculo: [],
    //     alerta: [],
    //     Fecha_start: fechaInicial,
    //     Fecha_end: fechaFinal
    //   });
    // }
  }
}, [configId]);

useEffect(() => {
  const fetchData = async () => {
    if (initialized) return;

    setIsLoading(true);
    setLoadingMessage('Cargando vehículos y alertas...');
    try {
      let vehiculosgps;
      let alertasgps;

      if (dataSource === 'notificacionService') {
        [vehiculosgps, alertasgps] = await Promise.all([
          notificacionService.getVehiculo(),
          notificacionService.getAlertas()
        ]);
      } else {
        setLoadingMessage('Obteniendo datos del servicio...');
        const [vehiculosResponse, alertasResponse] = await Promise.all([
          vehiculoService.getVehiculos(),
          eventoService.getEventos()
        ]);

        console.log(`Loading data for configId: ${configId}`);
        
        vehiculosgps = vehiculosResponse.data.map(v => ({
          ...v,
          VehiculoUId: v.id,
          AliasConNumeroEconomico: v.alias || v.no_Economico || ''
        }));

        alertasgps = alertasResponse.data.map(a => ({
          ...a,
          TipoAlerta: a.eventos[0],
          NombreTipo: a.nombre
        }));
      }

      setVehiculos(vehiculosgps);
      setAlertas(alertasgps);
      
      setFilters(prev => ({
        ...prev,
        vehiculo: vehiculosgps, // Seleccionar todos los vehículos
        alerta: alertasgps.map(a => a.TipoAlerta), // Seleccionar todas las alertas
        Fecha_start: fechaInicial,
        Fecha_end: fechaFinal
      }));
      
      setInitialized(true);
      // if (!initialized) {
      //   setFilters(prev => ({
      //     ...prev,
      //     vehiculo: [],
      //     alerta: [],
      //     Fecha_start: fechaInicial,
      //     Fecha_end: fechaFinal
      //   }));
      //   setInitialized(true);
      // }

    } catch (error) {
      console.error('Error loading data:', error);
    } finally {
      setIsLoading(false);
      setLoadingMessage('');
    }
  };

  if (configId && !initialized) {
    fetchData();
  }
}, [configId, dataSource, initialized]);

const handleFilterChange = (type, value) => {
  setFilters(prev => {
    const newFilters = { ...prev, [type]: value };
    console.log(`Updating ${type} filters:`, newFilters);
    return newFilters;
  });
};

// if (isLoading) {
//   return <Box sx={{ p: 2 }}>Cargando datos...</Box>;
// }

if (error) {
  return (
    <Box sx={{ p: 2, color: 'error.main' }}>
      Error al cargar los datos: {error}
    </Box>
  );
}

  // useEffect(() => {
  //   const fetchData = async () => {
  //     let vehiculosgps;
  //     let alertasgps;
  //     if (dataSource === 'notificacionService') {
  //       try {
  //         vehiculosgps = await notificacionService.getVehiculo();
  //         alertasgps = await notificacionService.getAlertas();
  //         setVehiculos(vehiculosgps);
  //         setAlertas(alertasgps);
  //         setFilters({
  //           ...filters,
  //           vehiculo: vehiculosgps,
  //           alerta: alertasgps.map(a => a.TipoAlerta),
  //           Fecha_start: fechaInicial,
  //           Fecha_end: fechaFinal
  //         });
  //       } catch (error) {
  //         console.error('Error fetching data:', error);
  //       }
  //     } else {
  //       try {
  //         // vehiculosgps = await vehiculoService.getVehiculos();
  //         // console.log('Vehiculos:', vehiculosgps);
  //         // alertasgps = await eventoService.getEventos();
  //         // console.log('Alertas:', alertasgps);


  //         // setVehiculos(vehiculosgps.data);
  //         // setAlertas(alertasgps.data);

  //         let vehiculosgps = await vehiculoService.getVehiculos();
  //         let alertasgps = await eventoService.getEventos();
      
  //         console.log('Vehiculos 07122024:', vehiculosgps);
  //         console.log('Alertas 07122024:', alertasgps);

  //         // Extraer los arrays de datos
  //         const vehiculosData = vehiculosgps.data;
  //         const alertasData = alertasgps.data;

  //         vehiculosgps = vehiculosData.map(v => ({
  //           ...v,
  //           VehiculoUId: v.id,
  //           AliasConNumeroEconomico: v.alias || v.no_Economico || ''
  //         }));
          

  //         alertasgps = alertasData.map(a => ({
  //           ...a,
  //           TipoAlerta: a.eventos[0],
  //           NombreTipo: a.nombre
  //         }));
  
  //         // Asignar los arrays al estado
  //         setVehiculos(vehiculosgps);
  //         setAlertas(alertasgps);

  //         setFilters({
  //           ...filters,
  //           vehiculo: vehiculosgps,
  //           alerta: alertasgps.map(a => a.TipoAlerta),
  //           Fecha_start: fechaInicial,
  //           Fecha_end: fechaFinal
  //         });
  //       } catch (error) {
  //         console.error('Error fetching data:', error);
  //       }
  //     }
  //   };

  //   fetchData();
  // }, [configId, dataSource]);
  ////}, []);

  const handleVehiculoChange = (event, value) => {
    setFilters({ ...filters, vehiculo: value });
  };

  // const handleAlertChange = (event, value) => {
  //   setFilters({ ...filters, alerta: value.map(a => a.TipoAlerta) });
  // };

  const handleAlertChange = (event, newValue) => {
    if (newValue.includes('all')) {
      if (filters.alerta?.length === alertas.length) {
        // Si todas están seleccionadas, deselectar todas
        handleDeselectAllAlertas();
      } else {
        // Si no todas están seleccionadas, seleccionar todas
        handleSelectAllAlertas();
      }
    } else {
      // Filtrar 'all' y mapear a TipoAlerta
      const alertasToSet = newValue.filter(v => v !== 'all').map(a => a.TipoAlerta ?? a.nombre );


      setFilters(prev => ({ ...prev, alerta: alertasToSet }));
    }
  };

  

  const handleFechaInicialChange = (newValue) => {
    setFechaInicial(newValue);
    setFilters({ ...filters, Fecha_start: newValue });
  };

  const handleFechaFinalChange = (newValue) => {
    setFechaFinal(newValue);
    setFilters({ ...filters, Fecha_end: newValue });
  };

  const handleSelectAllVehiculos = () => {
    setFilters({ ...filters, vehiculo: vehiculos });
  };

  const handleDeselectAllVehiculos = () => {
    setFilters({ ...filters, vehiculo: [] });
  };

  const handleSelectAllAlertas = () => {
    setFilters({ ...filters, alerta: alertas.map(a => a.TipoAlerta) });
  };

  // const handleSelectAllAlertas = () => {
  //   const alertasMapped = alertas.map(a => a.TipoAlerta ?? a.nombre);
  //   console.log('Alertas seleccionadas:', alertasMapped);
  //   setFilters({ ...filters, alerta: alertasMapped });
  // };

  const handleDeselectAllAlertas = () => {
    setFilters({ ...filters, alerta: [] });
  };
  const formatFecha = (fecha) => {
    return fecha.toISOString().slice(0, 19).replace('T', ' ');
  };

  return (
    <Paper 
      elevation={2} 
      sx={{
        p: 2,
        mb: 2,
        backgroundColor: 'background.paper',
        borderRadius: '0 0 8px 8px', // Only bottom radius
          marginTop: '-24px',  // Add negative margin to close gap
          marginBottom: '16px'
      }}
    >
      <Box
        display="flex"
        flexDirection={{ xs: 'column',sm: 'row', sm: 'row' }}
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
        gap={1}
        sx={{
          '& > *': {
            flex: '1 1 auto',
            minWidth: { xs: '100%', md: '180px' }, // Responsive min-width
            maxWidth: { xs: '100%', md: '220px' }, // Max width for larger screens
          },
          '& .MuiAutocomplete-root': {
            '& .MuiOutlinedInput-root': {
              height: '40px', // Reduced height
            }
          },
          '& .MuiTextField-root': {
            height: '40px' // Reduced height
          }
        }}
        // sx={{
        //   '& > *': {
        //     flex: '0 0 auto', 
        //   }
        // }}
      >

<Autocomplete
  multiple
  options={['all', ...vehiculos]}  
   getOptionLabel={(option) => {
     if (option === 'all') return 'Seleccionar todas';
     return option.AliasConNumeroEconomico ||  '';
   }}
  value={filters.vehiculo || []}
  onChange={(event, newValue) => {
    if (newValue.includes('all')) {
      if (filters.vehiculo?.length === vehiculos.length) {
        handleDeselectAllVehiculos();
      } else {
        handleSelectAllVehiculos();
      }
    } else {
      setFilters({ ...filters, vehiculo: newValue });
    }
  }}
  disableCloseOnSelect
  sx={{
    '& .MuiAutocomplete-inputRoot': {
      height: 40,
      fontSize: '0.875rem'
    },
    '& .MuiAutocomplete-popper': {
      '& .MuiAutocomplete-paper': {
        '& .MuiAutocomplete-listbox': {
          maxHeight: '250px',
          '& .MuiAutocomplete-option': {
            minHeight: '35px',
            padding: '2px 8px',
            fontSize: '0.875rem',
            '& .MuiCheckbox-root': {
              padding: '4px',
              '& .MuiSvgIcon-root': {
                fontSize: '1.1rem'
              }
            }
          }
        }
      }
    }
  }}
  renderOption={(props, option, { selected }) => {
    const { key, ...rest } = props; // Extrae 'key' de 'props'

    if (option === 'all') {
      const allSelected = filters.vehiculo?.length === vehiculos.length;
      const someSelected = filters.vehiculo?.length > 0 && filters.vehiculo?.length < vehiculos.length;

      return (
        <li key="select-all" {...rest} style={{ minHeight: '35px', fontSize: '0.875rem' }}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8, padding: '4px' }}
            checked={allSelected}
            indeterminate={someSelected}
            size="small"
          />
          <b>Seleccionar todas</b>
        </li>
      );
    }

    // Asignar un key único basado en una propiedad única
    //const optionKey = option.id || option.AliasConNumeroEconomico;

    return (
      <li key={option.id || option.AliasConNumeroEconomico} {...rest} style={{ minHeight: '35px', fontSize: '0.875rem' }}>
        <Checkbox
          icon={icon}
          checkedIcon={checkedIcon}
          style={{ marginRight: 8, padding: '4px' }}
          checked={selected}
          size="small"
        />
        {option.AliasConNumeroEconomico}
      </li>
      // <li key={optionKey} {...rest}>
      //   <Checkbox
      //     icon={icon}
      //     checkedIcon={checkedIcon}
      //     style={{ marginRight: 8 }}
      //     checked={selected}
      //   />
      //   {option.AliasConNumeroEconomico}
      // </li>
    );
  }}
  renderTags={(value, getTagProps) => {
    const displayValues = value.filter(v => v !== 'all');
    const { key, ...tagProps } = getTagProps({ index: 0 }); // Extraer 'key'

    return (
      <Chip 
        key={key} // Pasar 'key' directamente
        label={`${displayValues.length} vehículo${displayValues.length !== 1 ? 's' : ''} seleccionado${displayValues.length !== 1 ? 's' : ''}`} 
        {...tagProps} // Expandir el resto de las props
        sx={{
          backgroundColor: 'primary.light',
          color: 'primary.contrastText',
          width: '100%', // Ajusta el ancho según tus necesidades
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          height: 25,
        }}
        // sx={{ 
        //   backgroundColor: 'primary.light', 
        //   color: 'primary.contrastText'
        // }}
      />
    );
  }}
  renderInput={(params) => (
    <TextField 
      {...params} 
      label="Vehículo" 
      variant="outlined"
      sx={{ bgcolor: 'background.paper' }}
    />
  )}
 
  // sx={{ 
  //   '& .MuiAutocomplete-inputRoot': {
  //     height: 40, // Reduced height
  //     fontSize: '0.875rem' // Smaller font
  //   }
  //   // width: 300,
  //   // '& .MuiAutocomplete-inputRoot': {
  //   //   height: 56
  //   // }
  // }}
/>
       
<Autocomplete
  multiple
  options={['all', ...alertas]}
  getOptionLabel={(option) => {
    if (option === 'all') return 'Seleccionar todas';
    return option.NombreTipo || option.nombre || '';
  }}
  value={alertas.filter(a => filters.alerta?.includes(a.TipoAlerta)) || alertas.filter(a => filters.alerta?.includes(a.nombre)) ||  []}
  onChange={handleAlertChange}
  disableCloseOnSelect
  sx={{
    '& .MuiAutocomplete-inputRoot': {
      height: 40,
      fontSize: '0.875rem'
    },
    '& .MuiAutocomplete-popper': {
      '& .MuiAutocomplete-paper': {
        '& .MuiAutocomplete-listbox': {
          maxHeight: '250px',
          '& .MuiAutocomplete-option': {
            minHeight: '35px',
            padding: '2px 8px',
            fontSize: '0.875rem',
            '& .MuiCheckbox-root': {
              padding: '4px',
              '& .MuiSvgIcon-root': {
                fontSize: '1.1rem'
              }
            }
          }
        }
      }
    }
  }}
  renderOption={(props, option, { selected }) => {
    const { key, ...rest } = props; // Extraer 'key' de 'props'

    if (option === 'all') {
      const allSelected = filters.alerta?.length === alertas.length;
      const someSelected = filters.alerta?.length > 0 && filters.alerta?.length < alertas.length;

      return (
        //<li key="select-all" {...rest}>
        <li key="select-all" {...rest} style={{ minHeight: '35px', fontSize: '0.875rem' }}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 , padding: '4px' }}
            checked={allSelected}
            indeterminate={someSelected}
          />
          <b>Seleccionar todas</b>
        </li>
      );
    }

    // Asignar un key único basado en una propiedad única
    const optionKey = option.TipoAlerta || option.id;

    return (
      <li key={optionKey} {...rest} style={{ minHeight: '35px', fontSize: '0.875rem' }}>
        <Checkbox
          icon={icon}
          checkedIcon={checkedIcon}
          style={{ marginRight: 8, padding: '4px' }}
          checked={selected}
        />
        {option.NombreTipo || option.nombre}
      </li>
    );
  }}
  renderTags={(value, getTagProps) => {
    const displayValues = value.filter(v => v !== 'all');
    const { key, ...tagProps } = getTagProps({ index: 0 }); // Extraer 'key'

    return (
      <Chip
        key={key} // Pasar 'key' directamente
        label={`${displayValues.length} alerta${
          displayValues.length !== 1 ? 's' : ''
        } seleccionada${displayValues.length !== 1 ? 's' : ''}`}
        {...tagProps} // Expandir el resto de las props
        sx={{
          backgroundColor: 'primary.light',
          color: 'primary.contrastText',
          width: '100%', // Ajusta el ancho según tus necesidades
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          height: 25,
        }}
      />
    );
  }}
  renderInput={(params) => (
    <TextField
      {...params}
      label="Alertas"
      variant="outlined"
      sx={{ bgcolor: 'background.paper' }}
    />
  )}
  
  // sx={{
  //   '& .MuiAutocomplete-inputRoot': {
  //     height: 40, // Reduced height
  //     fontSize: '0.875rem' // Smaller font
  //   }
  //   // width: 300,
  //   // '& .MuiAutocomplete-inputRoot': {
  //   //   height: 56,
  //   // },
  // }}
/>


        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            label="Fecha Inicial"
            value={fechaInicial}
            onChange={handleFechaInicialChange}
            slotProps={{
              textField: {
                variant: 'outlined',
                size: 'small',
                sx: { bgcolor: 'background.paper' },
              },
            }}
            ampm={false}
          />
          <DateTimePicker
            label="Fecha Final"
            value={fechaFinal}
            onChange={handleFechaFinalChange}
             slotProps={{
    textField: {
      variant: 'outlined',
      size: 'small',
      sx: { bgcolor: 'background.paper' },
    },
  }}
            ampm={false}
          />
        </LocalizationProvider>

        <Button 
          variant="contained" 
          color="primary" 
          onClick={() => onSearch({
            ...filters,
            Fecha_start: formatFecha(fechaInicial),
            Fecha_end: formatFecha(fechaFinal)
          })}
          sx={{ 
            height: 40,
            minWidth: { xs: '100%', md: '120px' },
            fontSize: '0.875rem',
            fontWeight: 'bold'
          }}
        >
          Buscar
        </Button>
      </Box>
    </Paper>
  );
};

export default FiltersDatabase;

// import React, { useState, useEffect } from 'react';
// import { TextField, Autocomplete, Box, Button, Chip } from '@mui/material';
// import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// import { notificacionService } from '../../services/apiService';

// const FiltersDatabase = ({ fields, filters, setFilters, filtersWithTypes, onSearch }) => {
//   const [vehiculos, setVehiculos] = useState([]);
//   const [alertas, setAlertas] = useState([]);
//   const [fechaInicial, setFechaInicial] = useState(new Date(new Date().setHours(0, 0, 0, 0)));
//   const [fechaFinal, setFechaFinal] = useState(new Date(new Date().setHours(23, 59, 59, 999)));

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const vehiculosgps = await notificacionService.getVehiculo();
//         const alertasgps = await notificacionService.getAlertas();

//          // Verificar la estructura de los datos obtenidos
//          console.log('Vehiculos:', vehiculosgps);
//          console.log('Alertas:', alertasgps);

//         setVehiculos(vehiculosgps);
//         setAlertas(alertasgps);
//         setFilters({
//           ...filters,
//           vehiculo: vehiculosgps,
//           alerta: alertasgps.map(a => a.TipoAlerta), // Convertir a enteros
//           Fecha_start: fechaInicial,
//           Fecha_end: fechaFinal
//         });
//       } catch (error) {
//         console.error('Error fetching data:', error);
//       }
//     };

//     fetchData();
//   }, []);

//   const handleVehiculoChange = (event, value) => {
//     setFilters({ ...filters, vehiculo: value });
//   };

//   const handleAlertaChange = (event, value) => {
//     setFilters({ ...filters, alerta: value.map(a => a.TipoAlerta) }); // Convertir a enteros
//   };

//   const handleFechaInicialChange = (newValue) => {
//     setFechaInicial(newValue);
//     setFilters({ ...filters, Fecha_start: newValue });
//   };

//   const handleFechaFinalChange = (newValue) => {
//     setFechaFinal(newValue);
//     setFilters({ ...filters, Fecha_end: newValue });
//   };

//   const formatFecha = (fecha) => {
//     return fecha.toISOString().slice(0, 19).replace('T', ' ');
//   };

//   return (
//     <Box
//       display="flex"
//       flexDirection={{ xs: 'column', sm: 'row' }}
//       alignItems="center"
//       justifyContent="space-between"
//       flexWrap="wrap"
//       p={2}
//       gap={2}
//     >
//       <Autocomplete
//         multiple
//         options={vehiculos}
//         getOptionLabel={(option) => option.AliasConNumeroEconomico}
//         value={filters.vehiculo || []}
//         onChange={handleVehiculoChange}
//         renderTags={(value, getTagProps) => (
//           <Chip label={`${value.length} seleccionados`} {...getTagProps({ index: 0 })} />
//         )}
//         renderInput={(params) => <TextField {...params} label="Vehículo" variant="outlined" />}
//         style={{ minWidth: 200 }}
//       />
//       <Autocomplete
//         multiple
//         options={alertas}
//         getOptionLabel={(option) => option.NombreTipo}
//         value={filters.alerta || []}
//         onChange={handleAlertaChange}
//         renderTags={(value, getTagProps) => (
//           <Chip label={`${value.length} seleccionados`} {...getTagProps({ index: 0 })} />
//         )}
//         renderInput={(params) => <TextField {...params} label="Alerta" variant="outlined" />}
//         style={{ minWidth: 200 }}
//       />
//       <LocalizationProvider dateAdapter={AdapterDateFns}>
//         <DateTimePicker
//           label="Fecha Inicial"
//           value={fechaInicial}
//           onChange={handleFechaInicialChange}
//           renderInput={(params) => <TextField {...params} variant="outlined" />}
//           ampm={false} // Usar formato de 24 horas
//           style={{ minWidth: 200 }}
//         />
//         <DateTimePicker
//           label="Fecha Final"
//           value={fechaFinal}
//           onChange={handleFechaFinalChange}
//           renderInput={(params) => <TextField {...params} variant="outlined" />}
//           ampm={false} // Usar formato de 24 horas
//           style={{ minWidth: 200 }}
//         />
//       </LocalizationProvider>
//       <Button variant="contained" color="primary" 
//        onClick={() => onSearch({
//         ...filters,
//         Fecha_start: formatFecha(fechaInicial),
//         Fecha_end: formatFecha(fechaFinal)
//       })}
//         style={{ minWidth: 200 }}>
//         Buscar
//       </Button>
//     </Box>
//   );
// };

// export default FiltersDatabase;