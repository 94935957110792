// src/components/Sidebar.js
import React, { useState } from 'react';
import {
  Drawer,
  List,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Divider,
  Box,
  Typography,
  Collapse,
  IconButton,
  Paper,
  Popover,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { alpha, styled } from '@mui/material/styles';
import FolderIcon from '@mui/icons-material/Folder';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { motion } from 'framer-motion';
import BusinessIcon from '@mui/icons-material/Business';
import PersonIcon from '@mui/icons-material/Person';
import MonitorIcon from '@mui/icons-material/Monitor';
import ContactsIcon from '@mui/icons-material/Contacts';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import MapIcon from '@mui/icons-material/Map';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

import CategoryIcon from '@mui/icons-material/Category';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ListAltIcon from '@mui/icons-material/ListAlt';
import EventIcon from '@mui/icons-material/Event';
import AssessmentIcon from '@mui/icons-material/Assessment';
import WarningIcon from '@mui/icons-material/Warning';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import ListIcon from '@mui/icons-material/List';


const drawerWidth = 250;
const minimizedDrawerWidth = 60;

const showSeccionMenu = process.env.REACT_APP_SUPERUSERACTIVATE === "true";

const SidebarContainer = styled(Box)(({ theme, isMinimized }) => ({
  width: isMinimized ? minimizedDrawerWidth : drawerWidth,
  background: theme.palette.mode === 'dark'
    ? alpha(theme.palette.background.paper, 0.95)
    : alpha(theme.palette.background.paper, 0.98),
  height: 'calc(100vh - 64px)', // Ajusta la altura para que no se monte sobre el Navbar
  display: 'flex',
  flexDirection: 'column',
  position: 'fixed',
  top: 64, // Altura del Navbar
  left: 0,
  backdropFilter: 'blur(10px)',
  borderRight: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
  boxShadow: theme.shadows[4],
  transition: 'width 0.3s ease, background 0.3s ease',
}));

const StyledListItem = styled(ListItemButton)(({ theme }) => ({
  margin: '4px 8px',
  borderRadius: theme.shape.borderRadius,
  transition: 'background-color 0.3s, box-shadow 0.3s',
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    boxShadow: `inset 6px 0 0 ${theme.palette.primary.main}`,
    '& .MuiListItemIcon-root': {
      color: theme.palette.primary.main,
    },
  },
}));

// const categoryIcons = {
//   Administrativo: <AdminPanelSettingsIcon />,
//   Empresa: <BusinessIcon />,
//   Usuario: <PersonIcon />,
//   'Monitor': <MonitorIcon />,
//   Contactos: <ContactsIcon />,
//   Vehìculos: <DirectionsCarIcon />,  
//   Geocercas: <MapIcon />,
//   Monitor: <MonitorIcon />,
// };

const categoryIcons = {
  Administrativo: <AdminPanelSettingsIcon />,
  Empresa: <BusinessIcon />,
  Usuario: <PersonIcon />,
  Monitor: <MonitorIcon />,
  Contactos: <ContactsIcon />,
  Vehìculos: <DirectionsCarIcon />,  
  Geocercas: <MapIcon />,
  Clasificaciones: <CategoryIcon />,
  Alertas: <NotificationsIcon />,
  'Listado Alertas': <ListAltIcon />,
  'Clasificación Evento': <EventIcon />,
  Reportes: <AssessmentIcon />,
  'Reporte Alertas': <ListIcon />,
  'Reporte Omisiones': <ListIcon />,
};


function Sidebar({ menuData = [], open, isMinimized }) {
  const navigate = useNavigate();
  const [expandedCategories, setExpandedCategories] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeCategory, setActiveCategory] = useState(null);

  // const handleNavigation = (path) => {
  //   navigate(path);
  //   handleClosePopover();
  // };

  const handleNavigation = (item) => {
    const { controller, action, configId } = item;
    let path = `/pages/${controller.toLowerCase()}/${action.toLowerCase()}`;
    console.log(`Navigating to path: ${path} with configId: ${configId}`);

    // Remove the following lines to prevent appending configId to the path
    if (item.nombre === 'Reporte Alertas') {
      path += `/a7458b28-61a5-4eaf-99f1-a1b4aaab7d38`; // Añade el configId a la URL
    }

    if (item.nombre === 'Reporte Omisiones') {
      path += `/be29f792-93a0-4f4e-8693-233200a8af71`; // Añade el configId a la URL
    }
  
  
    
    navigate(path);
    handleClosePopover();
  };

  // const handleNavigation = (item) => {
  //   const { controller, action, configId } = item;
  //   let path = `/pages/${controller.toLowerCase()}/${action.toLowerCase()}`;
  //   navigate(path);
  //   handleClosePopover();
  // };

  const toggleCategory = (event, category) => {
    if (isMinimized) {
      setAnchorEl(event.currentTarget);
      setActiveCategory(activeCategory === category ? null : category);
    } else {
      setExpandedCategories((prev) => ({
        ...prev,
        [category]: !prev[category],
      }));
    }
  };

  const handleClosePopover = () => {
    setActiveCategory(null);
    setAnchorEl(null);
  };

  const filteredMenuData = menuData.filter((item) => {
    if (!showSeccionMenu) {
      // Excluir secciones específicas cuando showSeccionMenu es false
      return !['clasificacion_evento', 'clasificaciones','vehiculo'].includes(item.controller.toLowerCase());
    }
    return true; // Mostrar todos los elementos si showSeccionMenu es true
  });

  const groupedMenu = filteredMenuData.reduce((acc, item) => {
    const category = item.categoria;
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(item);
    return acc;
  }, {});

  return (
    <Drawer
      sx={{
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: isMinimized ? minimizedDrawerWidth : drawerWidth,
          transition: 'width 0.3s ease',
          top: '64px', // Ajusta la posición para que no se monte sobre el Navbar
          height: 'calc(100vh - 64px)', // Ajusta la altura para que no se monte sobre el Navbar
        },
      }}
      variant="persistent"
      anchor="left"
      open={open}
    >
      <motion.div
        initial={{ x: -drawerWidth }}
        animate={{ x: 0 }}
        exit={{ x: -drawerWidth }}
        transition={{ type: 'spring', stiffness: 300, damping: 30 }}
      >
        <SidebarContainer isMinimized={isMinimized}>
          <List>
            {Object.keys(groupedMenu).length > 0 ? (
              Object.keys(groupedMenu).map((category) => (
                <React.Fragment key={category}>
                  <StyledListItem
                    onClick={(e) => toggleCategory(e, category)}
                    sx={{
                      justifyContent: isMinimized ? 'center' : 'flex-start',
                      px: isMinimized ? 2 : 3,
                      py: 1,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: isMinimized ? 0 : 40,
                        mr: isMinimized ? 0 : 2,
                        justifyContent: 'center',
                        transition: 'color 0.3s',
                      }}
                    >
                      {categoryIcons[category] || <FolderIcon />}
                    </ListItemIcon>

                    {!isMinimized && (
                      <>
                        <Typography variant="subtitle2" fontWeight={600}>
                          {category}
                        </Typography>
                        <ExpandMoreIcon
                          sx={{
                            transform: expandedCategories[category] ? 'rotate(180deg)' : 'rotate(0deg)',
                            transition: 'transform 0.2s',
                            marginLeft: 'auto',
                          }}
                        />
                      </>
                    )}
                  </StyledListItem>

                  {!isMinimized ? (
                    <Collapse in={expandedCategories[category]} timeout="auto" unmountOnExit>
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.3 }}
                      >
                        <List component="div" disablePadding>
                          {groupedMenu[category].map((item) => (
                            <StyledListItem
                              key={item.id}
                              onClick={() => handleNavigation(item)}
                              // onClick={() =>
                              //   handleNavigation(`/pages/${item.controller.toLowerCase()}/${item.action.toLowerCase()}`)
                              // }
                              sx={{ pl: 4, py: 0.5 }}
                            >
                              <ListItemIcon>
                              {categoryIcons[item.nombre] || <FolderIcon />}
                              </ListItemIcon>
                              <ListItemText
                                primary={
                                  <Typography variant="body2" fontWeight={500}>
                                    {item.nombre}
                                  </Typography>
                                }
                              />
                            </StyledListItem>
                          ))}
                        </List>
                      </motion.div>
                    </Collapse>
                  ) : (
                    <Popover
                      open={activeCategory === category}
                      anchorEl={anchorEl}
                      onClose={handleClosePopover}
                      anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'center',
                        horizontal: 'left',
                      }}
                      PaperProps={{
                        sx: {
                          pointerEvents: 'auto',
                          width: 220,
                          ml: 1,
                          boxShadow: (theme) => theme.shadows[4],
                          borderRadius: 1,
                        },
                      }}
                    >
                      <Paper elevation={4}>
                        <List>
                          {groupedMenu[category].map((item) => (
                            <StyledListItem
                              key={item.id}
                              onClick={() => handleNavigation(item)}
                              // onClick={() =>
                              //   handleNavigation(`/pages/${item.controller.toLowerCase()}/${item.action.toLowerCase()}`)
                              // }
                              sx={{
                                py: 0.5,
                              }}
                            >
                              <ListItemIcon>
                              {categoryIcons[item.nombre] || <FolderIcon />}
                              </ListItemIcon>
                              <ListItemText
                                primary={
                                  <Typography variant="body2" fontWeight={500}>
                                    {item.nombre}
                                  </Typography>
                                }
                              />
                            </StyledListItem>
                          ))}
                        </List>
                      </Paper>
                    </Popover>
                  )}
                  <Divider sx={{ my: 1 }} />
                </React.Fragment>
              ))
            ) : (
              <StyledListItem>
                <ListItemText
                  primary="No hay elementos en el menú"
                  sx={{ textAlign: 'center', opacity: 0.7 }}
                />
              </StyledListItem>
            )}
          </List>
        </SidebarContainer>
      </motion.div>
    </Drawer>
  );
}

export default Sidebar;