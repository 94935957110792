import React, { useState, useEffect } from "react";
import {
  Box,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  Typography,
  DialogContent, // Contenido principal del modal
  DialogContentText, // Texto dentro del modal
  Divider
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { alertaService } from "../../services/apiService";
import SearchForm from "./SearchForm";
import TableModule from "./TableModule";
import NuevaAlertaForm from "../alertas/components/NuevaAlertaForm";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
//import { Grid } from "@mui/material";
import Grid from "@mui/material/Grid2";
//Modulo listado de alertas
const Index = () => {
  const [alerts, setAlerts] = useState([]);
  const [alertaId, setAlertaId] = useState(""); // ID de la alerta
  const [editMode, setEditMode] = useState(false); // Nuevo estado para controlar el modo edición
  const [configuracion, setConfiguracion] = useState({
    nombre: "",
    tipo_alerta: {},
    geocerca: [],
    vehiculo: [],
    vehiculo_compare: [],
    contactos: [],
    riesgo: "",
    tiempo: 0,
    veces: 0,
    repetir: 1,
    velocidad_movimiento: 0,
    distancia_vehiculos: 0,
    tipo_marcado: 0,
    url_sonido: "",
    horario: [],
  });

  const [selectedRows, setSelectedRows] = useState([]); // Almacena las filas seleccionadas
  const [showConfirmDialog, setShowConfirmDialog] = useState(false); // Controla el mensaje de confirmación

  // Método para actualizar el JSON principal con los datos de los componentes
  const actualizarComponente = (clave, datos) => {
    setConfiguracion((prev) => ({
      ...prev,
      [clave]: datos,
    })); // Solo actualiza la clave correspondiente
  };

  // Método para guardar toda la alerta (creación o edición al api)
  const handleGuardarPrincipal = async () => {
    const jsonCompleto = {
      id: alertaId || "",
      ...configuracion, // Usamos todo el estado como base
    };
    try {
      if (!alertaId) {
        // Crear una nueva alerta
        const response = await alertaService.createAlerta(jsonCompleto);
        if (response && response.statusCode === 200) {
          setAlertaId(response.data.id);
          //resetForm(); //a gregar un nuevo boton para formatear todos los componentes
          toast.success("Alerta creada exitosamente");
        } else {
          toast.error(response?.message || "Error al crear alerta");
        }
      } else {
        // Actualizar la alerta existente
        const response = await alertaService.updateAlerta(jsonCompleto);
        if (response && response.statusCode === 200) {
          toast.success("Alerta Actualizada exitosamente");
          resetForm();
        } else {
          toast.error(response?.message || "Error al actualizar alerta");
        }
      }
    } catch (error) {
      console.error("Error al guardar alerta:", error);
      toast.error("Error al guardar la alerta");
    }
  };

  // Método para restablecer el formulario
  const resetForm = () => {
    setAlertaId(""); // Restablece el ID de la alerta
    setEditMode(false); // Salir del modo edición
    setConfiguracion({
      nombre: "",
      tipo_alerta: {},
      geocerca: [],
      vehiculo: [],
      vehiculo_compare: [],
      contactos: [],
      riesgo: "",
      tiempo: 0,
      veces: 0,
      repetir: 1,
      velocidad_movimiento: 0,
      distancia_vehiculos: 0,
      tipo_marcado: 0,
      url_sonido: "",
      horario: [],
    }); // Restaura el estado inicial
  };
  const handleEditarAlerta = (id) => {
    if (id) {
      loadAlertaById(id); // Llama solo si hay un ID válido
    } else {
      console.warn("Intento de edición sin seleccionar una alerta.");
    }
  };

  // Método para cargar los datos de una alerta seleccionada
  const loadAlertaById = async (id) => {
    if (!id) {
      console.warn("No se seleccionó ningún ID para cargar la alerta.");
      return; // Si no hay un ID, no hace nada
    }
    try {
      const filtros = { id: id };
      const response = await alertaService.getAlertList(filtros);
      if (response && response.statusCode === 200) {
        setConfiguracion(response.data[0]); // Cargar los datos en el estado de configuración
        setAlertaId(id); // Establecer el ID de la alerta
        setEditMode(true); // Activar el modo edición
      } else {
        toast.error("Error al cargar los datos de la alerta seleccionada");
      }
    } catch (error) {
      console.error("Error al cargar la alerta:", error);
      toast.error("Error al cargar la alerta seleccionada");
    }
  };

  const defaultFilters = {
    inicio: new Date(new Date().setHours(0, 0, 0, 0)).toISOString(), // Inicio del día (00:00:00)
    fin: new Date(new Date().setHours(23, 59, 59, 999)).toISOString(), // Fin del día (23:59:59)
    activo: true, // Solo alertas activas
  };

  //Metodo para Listar todos las alertas
  const loadAlerts = async (filtros = defaultFilters) => {
    try {
      const response = await alertaService.getAlertList(filtros);
      if (response && response.statusCode === 200) {
        setAlerts(response.data);
      }
    } catch (error) {
      console.error("Error al cargar alertas:", error);
    }
  };

  // Método para eliminar alertas seleccionadas
  const confirmDelete = () => {
    setShowConfirmDialog(false); // Cierra el diálogo
    const idsToDelete = selectedRows.map((row) => row.id);
    handleDelete(idsToDelete); // Llama a la función de eliminación
  };

  const handleDelete = async (ids) => {
    try {
      const response = await alertaService.deleteAlert({ ids }); // Ajusta el payload según tu API
      if (response.statusCode === 200) {
        toast.success("Alertas eliminadas exitosamente");
        setSelectedRows([]); // Limpia las filas seleccionadas
        // Recargar alertas con los filtros completos
        await loadAlerts(defaultFilters);
      } else {
        toast.error("Error al eliminar alertas");
      }
    } catch (error) {
      console.error("Error eliminando alertas:", error);
      toast.error("Error al eliminar alertas");
    }
  };

  //Init del modulo
  useEffect(() => {
    loadAlerts();
  }, []);

  

  //Retonrna la UI
  return (
    <Paper sx={{ p: 2}}>
      <Box sx={{ width: "100%", height: "100%", overflow: "auto" }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Listado de Alertas
        </Typography>
        <Divider sx={{ my: 2, borderColor: "red" }} />
        <form>
          <Grid container spacing={2}>
            <Grid size={{ xs: 10 }}>
              {editMode ? (
                <NuevaAlertaForm
                  idAlerta={alertaId}
                  configuracion={configuracion}
                  setConfiguracion={setConfiguracion}
                  handleGuardarPrincipal={handleGuardarPrincipal}
                  resetForm={resetForm}
                  viewNew={false}
                />
              ) : null}
            </Grid>
            <Grid size={{ xs: 10 }}>
              <TableModule
                alertList={alerts}
                onSelectionChange={(rows) => setSelectedRows(rows)}
                handleEditarAlerta={handleEditarAlerta}
                setShowConfirmDialog={setShowConfirmDialog}
                selectedRows={selectedRows}
              />
            </Grid>
          </Grid>
        </form>
      </Box>
      <ToastContainer />
      <Dialog
        open={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
      >
        <DialogTitle>Confirmar Eliminación</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro de que deseas guardar los cambios realizados?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowConfirmDialog(false)} color="primary">
            Cancelar
          </Button>
          <Button onClick={confirmDelete} color="secondary" autoFocus>
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};
export default Index;


  // return (
  //   <LocalizationProvider dateAdapter={AdapterDateFns}>
  //      <Paper sx={{ p: 2, width: "100%" }}>
   
  //       {/* <Box sx={{ padding: 3 ,  width: "100%"}}> */}
  //       <Box sx={{ width: '100%', height: '100%', overflow: 'auto' }}>
  //         <Typography variant="h4" component="h1" gutterBottom>
  //           Listado de Alertas
  //         </Typography>
  //         <Divider sx={{ my: 2, borderColor: "red" }} />


  //         <form>
  //           <Grid container spacing={2}>
  //             {/* Mostrar el formulario de búsqueda o el de edición */}
  //             <Grid item xs={12}>
  //             {editMode ? (
  //     <NuevaAlertaForm
  //       idAlerta={alertaId}
  //       configuracion={configuracion}
  //       setConfiguracion={setConfiguracion}
  //       handleGuardarPrincipal={handleGuardarPrincipal}
  //       resetForm={resetForm}
  //       viewNew={false}
  //     />
  //   ) : null}
                
  //             </Grid>  
  //             <Grid item xs={12}>
  //               <TableModule
  //                 alertList={alerts}
  //                 onSelectionChange={(rows) => setSelectedRows(rows)}
  //                 handleEditarAlerta={handleEditarAlerta}
  //                 setShowConfirmDialog={setShowConfirmDialog}
  //                 selectedRows={selectedRows}
  //               />
  //             </Grid>
  //           </Grid>
  //         </form>


  //               </Box>
  //               <ToastContainer />
  //           </Paper>
  //           <Dialog
  //               open={showConfirmDialog}
  //               onClose={() => setShowConfirmDialog(false)}
  //               >
  //               <DialogTitle>Confirmar Eliminación</DialogTitle>
  //               <DialogContent>
  //                 <DialogContentText>
  //                   ¿Estás seguro de que deseas guardar los cambios realizados?
  //                 </DialogContentText>
  //               </DialogContent>
  //               <DialogActions>
  //                   <Button onClick={() => setShowConfirmDialog(false)} color="primary">
  //                   Cancelar
  //                   </Button>
  //                   <Button onClick={confirmDelete}  color="secondary" autoFocus>
  //                   Eliminar
  //                   </Button>
  //               </DialogActions>
  //           </Dialog>
  //       </LocalizationProvider>
      
  //   );
