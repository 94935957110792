import {
  Autocomplete,
  Checkbox,
  TextField,
  Grid,
  FormControlLabel,
  Divider,
  Box,
  Button,
  IconButton,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CancelIcon from "@mui/icons-material/Cancel";

import {
  geocercaService,
  ClasificacionesGeocercasService,
  tiposGeocercasService,
} from "../../../services/apiService";
import { forwardRef, useEffect, useState } from "react";
import { isEmptyArray } from "formik";
import ImageContainer from "./ImageContainer";
import notFoundGeocerca from "../../../assets/not-found/empty-space-geocercas.png";
import { confirmDialog } from "../../../components/ConfirmDialog";

const ListaGeocercas = forwardRef(
  ({
    configuracion,
    actualizarComponente,
    handleGuardarPrincipal,
    idAlerta,
    nextComponent,
    isEditMode,
  }) => {
    /*Lista de Geocercas*/
    const [geocercas, setGeocercas] = useState([]);
    const loadGeocercas = async () => {
      try {
        const response = await geocercaService.getGeocercas("");
        if (response.statusCode === 200) {
          //setGeocercas(response.data);
          console.log(
            response.data.filter((geocerca) => geocerca.status === 1)
          );
          setGeocercas(
            response.data.filter((geocerca) => geocerca.status === 1)
          );
        } else {
          console.error("❌ Error al cargar geocercas:", response);
        }
      } catch (error) {
        console.error("❌ Error cargando geocercas:", error);
      }
    };
    const [selectedGeocercas, setSelectedGeocercas] = useState([]);

    /*Geocercas filtradas*/
    const [filteredGeocercas, setFilteredGeocercas] = useState(geocercas);
    const [selectAll, setSelectAll] = useState(false);

    /*Clasificaciones de Geocercas*/
    const [clasificacionesGeocercas, setClasificacionesGeocercas] = useState(
      []
    );
    const [selectedClasificacion, setSelectedClasificacion] = useState();
    const loadClasificacionesGeocercas = async () => {
      try {
        const response =
          await ClasificacionesGeocercasService.getClasificacionesGeocercas();
        if (response.statusCode === 200) {
          setClasificacionesGeocercas(response.data);
        } else {
          console.error(
            "❌ Error al cargar las clasificaciones de geocercas:",
            response
          );
        }
      } catch (error) {
        console.error(error);
      }
    };

    /*Tipos de Geocercas*/
    const [tiposGeocercas, setTiposGeocercas] = useState([]);
    const [selectedTipo, setSelectedTipo] = useState();
    const loadTiposGeocercas = async () => {
      try {
        const response = await tiposGeocercasService.getTiposGeocercas(
          "",
          "",
          1
        );
        if (response.statusCode === 200) {
          setTiposGeocercas(response.data);
        } else {
          console.error("❌ Error al cargar los tipos de geocercas:", response);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const handleSelect = (geocerca) => {
      setSelectedGeocercas(geocerca);
    };

    const handleToggleSelectAll = () => {
      setSelectAll((prev) => {
        if (!prev) setSelectedGeocercas([...geocercas]);
        else setSelectedGeocercas([]);
        return !prev;
      });
    };

    const handleAgregarGeocerca = async () => {
      const isConfirmed = await confirmDialog({
        title: "Crear geocerca",
        message:
          "Será redireccionado a Quro para crear la geocerca, ¿Está seguro de realizar esta acción?",
        confirmLabel: "Aceptar",
        cancelLabel: "Cancelar",
      });
      if (!isConfirmed) return;
    };

    const handleRemoveGeocerca = (id) => {
      setSelectedGeocercas((prevSelected) =>
        prevSelected.filter((geocerca) => geocerca.id !== id)
      );
      if (selectedGeocercas.length === 0) setSelectAll(false);
    };
    /*Lista de Geocercas*/
    function isEnableButton() {
      return selectedGeocercas.length > 0 && idAlerta !== "";
    }

    useEffect(() => {
      loadGeocercas();
      loadClasificacionesGeocercas();
      loadTiposGeocercas();
    }, []);

    useEffect(() => {
      console.log(selectedGeocercas);
      actualizarComponente(
        "geocerca",
        selectedGeocercas.map((item) => {
          return { _id: `${item.id}`, type: item.puntos.type };
        })
      );
    }, [selectedGeocercas]);

    useEffect(() => {
      let filtered = geocercas;
      if (selectedClasificacion) {
        filtered = filtered.filter(
          (geocerca) => geocerca.clasificacion === selectedClasificacion.id
        );
      }

      if (selectedTipo) {
        filtered = filtered.filter(
          (geocerca) => geocerca.puntos.type === selectedTipo.nombre
        );
      }

      setFilteredGeocercas(filtered);
    }, [selectedClasificacion, selectedTipo, geocercas]);

    useEffect(() => {
      //Validamos si el componente fue limpiado
      if (isEmptyArray(configuracion.geocerca)) {
        //refrescamos el state si es necesario
        if (!isEmptyArray(selectedGeocercas)) {
          setSelectedGeocercas([]);
          setSelectAll(false);
        }
      } else if (geocercas.length > 0) {
        // const selectedFromConfig = configuracion.geocerca.map((configGeocerca) => { //antiguo
        //   const matchingGeocerca = geocercas.find(
        //     (geocerca) => geocerca.id === configGeocerca._id
        //   );
        //   if (matchingGeocerca) {
        //     return {
        //       ...matchingGeocerca,
        //       type: configGeocerca.type,
        //     };
        //   }
        //   return null;
        // }).filter((item) => item !== null);

        // cuando se obtiene configuración
        // Comparamos si las geocercas seleccionadas actuales coinciden con las de la configuración
        const selectedFromConfig = configuracion.geocerca
          .map((configGeocerca) => {
            const matchingGeocerca = geocercas.find(
              (geocerca) => geocerca.id === configGeocerca._id
            );
            return matchingGeocerca
              ? { ...matchingGeocerca, type: configGeocerca.type }
              : null;
          })
          .filter((item) => item !== null);

        console.log("selecteds", selectedFromConfig);

        // Verificamos si el estado actual ya tiene las geocercas seleccionadas
        const areEqual =
          selectedGeocercas.length === selectedFromConfig.length &&
          selectedGeocercas.every(
            (sel, index) => sel.id === selectedFromConfig[index].id
          );

        if (!areEqual) {
          setSelectedGeocercas(selectedFromConfig); // Actualizamos solo si es necesario

          // Notificamos al componente padre si el estado cambia
          actualizarComponente(
            "geocerca",
            selectedFromConfig.map((item) => ({
              _id: item.id,
              type: item.type,
            }))
          );
        }
      }
    }, [configuracion.geocerca, geocercas]);

    return (
      <>
        <Box
          height={310}
          pr={1}
          sx={{
            display: "flex",
            alignItems: "stretch",
            alignContent: "space-between",
          }}
        >
          <Box
            display={"flex"}
            flexDirection={"column"}
            maxWidth={500}
            minWidth={465}
            pt={1}
            gap={2}
            flexGrow={1}
          >
            <Autocomplete
              id="clasificaciones-geocerca"
              options={clasificacionesGeocercas}
              noOptionsText={"No se cargaron las clasificaciones"}
              disableListWrap
              filterSelectedOptions={false}
              renderInput={(params) => (
                <TextField {...params} label="Clasificaciones" />
              )}
              getOptionLabel={(option) => option.nombre}
              renderOption={(props, option, { selected }) => {
                const { key, ...optionProps } = props;
                return (
                  <li key={key} {...optionProps}>
                    {option.nombre}
                  </li>
                );
              }}
              onChange={(_e, value, reason) => setSelectedClasificacion(value)}
            />
            <Autocomplete
              id="tipos-geocerca"
              options={tiposGeocercas}
              noOptionsText={"No se cargaron los tipos"}
              disableListWrap
              filterSelectedOptions={false}
              renderInput={(params) => <TextField {...params} label="Tipos" />}
              getOptionLabel={(option) => option.nombre}
              renderOption={(props, option, { selected }) => {
                const { key, ...optionProps } = props;
                return (
                  <li key={key} {...optionProps}>
                    {option.nombre}
                  </li>
                );
              }}
              onChange={(_e, value, reason) => setSelectedTipo(value)}
            />
            <Box display={"flex"} alignItems={"center"} gap={1}>
              <Box flexGrow={1}>
                <Autocomplete
                  id="checkboxes-geocerca"
                  multiple
                  options={filteredGeocercas}
                  noOptionsText={"No hay coincidencias"}
                  disableCloseOnSelect
                  disableListWrap
                  filterSelectedOptions={false}
                  // value={selectedGeocercas} //antiguo
                  value={selectedGeocercas.map(
                    (selected) =>
                      filteredGeocercas.find(
                        (geocerca) => geocerca.id === selected.id
                      ) || selected
                  )}
                  defaultValue={selectedGeocercas}
                  // onChange={(_e, value, reason) => { //antiguo
                  //   if (reason === "clear" || reason === "removeOption")
                  //     setSelectAll(false);
                  //   if (
                  //     reason === "selectOption" &&
                  //     value.length === geocercas.length
                  //   )
                  //     setSelectAll(true);
                  //   handleSelect(value);
                  // }}
                  onChange={(_e, value, reason) => {
                    if (
                      reason === "removeOption" &&
                      selectedGeocercas.length > 0
                    ) {
                      setSelectedGeocercas(selectedGeocercas);
                      return;
                    }
                    switch (reason) {
                      case "clear":
                        setSelectAll(false);
                        setSelectedGeocercas([]);
                        break;
                      case "removeOption":
                        setSelectAll(false);
                        setSelectedGeocercas(value);
                        break;
                      case "selectOption":
                        if (value.length === geocercas.length)
                          setSelectAll(true);
                        setSelectedGeocercas(value);
                        break;
                      default:
                        setSelectedGeocercas(value);
                    }
                  }}
                  getOptionLabel={(option) => option.nombre}
                  renderOption={(props, option, { selected }) => {
                    const { key, ...optionProps } = props;
                    return (
                      <li key={key} {...optionProps}>
                        <Checkbox
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.nombre}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Geocercas"
                      placeholder="Selecciona una geocerca"
                    />
                  )}
                  renderTags={(selectedOptions) => {
                    return <></>;
                  }}
                  groupBy={() => ""}
                  renderGroup={(params) => (
                    <div key={params.key}>
                      <>
                        <Box
                          onMouseDown={(e) => e.preventDefault()} // prevent blur
                          pl={3.5}
                          py={0.5}
                        >
                          <FormControlLabel
                            onClick={(e) => {
                              e.preventDefault(); // prevent blur
                              handleToggleSelectAll();
                            }}
                            label="Seleccionar todos"
                            control={
                              <Checkbox
                                id="select-all-checkbox"
                                checked={selectAll}
                              />
                            }
                          />
                        </Box>
                        <Divider />
                      </>
                      {params.children}
                    </div>
                  )}
                />
              </Box>
              <Button
                variant="contained"
                sx={{
                  marginLeft: 2,
                  width: 55,
                  height: 55,
                  bgcolor: "#ea0000",
                  color: "#ffffff",
                  padding: "12px",
                  fontSize: "1.1rem",
                  fontWeight: 600,
                  "&:hover": {
                    bgcolor: "#000000",
                  },
                  "&.Mui-disabled": {
                    bgcolor: "grey.600",
                    color: "#ffffff",
                  },
                }}
                onClick={handleAgregarGeocerca}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 1,
                  }}
                >
                  <AddCircleIcon />
                </Box>
              </Button>
            </Box>
          </Box>
          {/* <Box
            display={"flex"}
            flexDirection={"column"}
            maxWidth={500}
            minWidth={250}
            pt={1}
            flexGrow={1}
            justifyContent={"space-around"}
            // gap={2}
          >
            <Box 
            display={"flex"} 
            maxWidth={500} 
            minWidth={250} 
            flexGrow={1}>
              <Grid container>
                <Grid item xs={12} md={12}>
                  <Box flexGrow={1}>
                    <Autocomplete
                      id="clasificaciones-geocerca"
                      options={clasificacionesGeocercas}
                      noOptionsText={"No se cargaron las clasificaciones"}
                      disableListWrap
                      filterSelectedOptions={false}
                      renderInput={(params) => (
                        <TextField {...params} label="Clasificaciones" />
                      )}
                      getOptionLabel={(option) => option.nombre}
                      renderOption={(props, option, { selected }) => {
                        const { key, ...optionProps } = props;
                        return (
                          <li key={key} {...optionProps}>
                            {option.nombre}
                          </li>
                        );
                      }}
                      onChange={(_e, value, reason) =>
                        setSelectedClasificacion(value)
                      }
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} md={12}>
                  <Box flexGrow={1}>
                    <Autocomplete
                      id="tipos-geocerca"
                      options={tiposGeocercas}
                      noOptionsText={"No se cargaron los tipos"}
                      disableListWrap
                      filterSelectedOptions={false}
                      renderInput={(params) => (
                        <TextField {...params} label="Tipos" />
                      )}
                      getOptionLabel={(option) => option.nombre}
                      renderOption={(props, option, { selected }) => {
                        const { key, ...optionProps } = props;
                        return (
                          <li key={key} {...optionProps}>
                            {option.nombre}
                          </li>
                        );
                      }}
                      onChange={(_e, value, reason) => setSelectedTipo(value)}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} md={12}>
                  <Box display={"flex"} alignItems={"center"} gap={1}>
                    <Box flexGrow={1}>
                      <Autocomplete
                        id="checkboxes-geocerca"
                        multiple
                        options={filteredGeocercas}
                        noOptionsText={"No hay coincidencias"}
                        disableCloseOnSelect
                        disableListWrap
                        filterSelectedOptions={false}
                        // value={selectedGeocercas} //antiguo
                        value={selectedGeocercas.map(
                          (selected) =>
                            filteredGeocercas.find(
                              (geocerca) => geocerca.id === selected.id
                            ) || selected
                        )}
                        defaultValue={selectedGeocercas}
                        // onChange={(_e, value, reason) => { //antiguo
                        //   if (reason === "clear" || reason === "removeOption")
                        //     setSelectAll(false);
                        //   if (
                        //     reason === "selectOption" &&
                        //     value.length === geocercas.length
                        //   )
                        //     setSelectAll(true);
                        //   handleSelect(value);
                        // }}
                        onChange={(_e, value, reason) => {
                          if (
                            reason === "removeOption" &&
                            selectedGeocercas.length > 0
                          ) {
                            setSelectedGeocercas(selectedGeocercas);
                            return;
                          }
                          switch (reason) {
                            case "clear":
                              setSelectAll(false);
                              setSelectedGeocercas([]);
                              break;
                            case "removeOption":
                              setSelectAll(false);
                              setSelectedGeocercas(value);
                              break;
                            case "selectOption":
                              if (value.length === geocercas.length)
                                setSelectAll(true);
                              setSelectedGeocercas(value);
                              break;
                            default:
                              setSelectedGeocercas(value);
                          }
                        }}
                        getOptionLabel={(option) => option.nombre}
                        renderOption={(props, option, { selected }) => {
                          const { key, ...optionProps } = props;
                          return (
                            <li key={key} {...optionProps}>
                              <Checkbox
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option.nombre}
                            </li>
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Geocercas"
                            placeholder="Selecciona una geocerca"
                          />
                        )}
                        renderTags={(selectedOptions) => {
                          return <></>;
                        }}
                        groupBy={() => ""}
                        renderGroup={(params) => (
                          <div key={params.key}>
                            <>
                              <Box
                                onMouseDown={(e) => e.preventDefault()} // prevent blur
                                pl={3.5}
                                py={0.5}
                              >
                                <FormControlLabel
                                  onClick={(e) => {
                                    e.preventDefault(); // prevent blur
                                    handleToggleSelectAll();
                                  }}
                                  label="Seleccionar todos"
                                  control={
                                    <Checkbox
                                      id="select-all-checkbox"
                                      checked={selectAll}
                                    />
                                  }
                                />
                              </Box>
                              <Divider />
                            </>
                            {params.children}
                          </div>
                        )}
                      />
                    </Box>
                    <Button
                      variant="contained"
                      sx={{
                        marginLeft: 2,
                        width: 55,
                        height: 55,
                        bgcolor: "#ea0000",
                        color: "#ffffff",
                        padding: "12px",
                        fontSize: "1.1rem",
                        fontWeight: 600,
                        "&:hover": {
                          bgcolor: "#000000",
                        },
                        "&.Mui-disabled": {
                          bgcolor: "grey.600",
                          color: "#ffffff",
                        },
                      }}
                      onClick={handleAgregarGeocerca}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: 1,
                        }}
                      >
                        <AddCircleIcon />
                      </Box>
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box> */}

          <Box marginLeft={2} display="flex">
            <Divider
              orientation="vertical"
              flexItem
              sx={{
                bgcolor: "red",
                width: "2px",
              }}
            />
          </Box>

          <Box marginLeft={2} flexGrow={1} pt={1}>
            {selectedGeocercas.length > 0 ? (
              <Grid
                container
                alignContent="flex-start"
                display={"flex"}
                direction={"column"}
                wrap="wrap"
                height={300}
                sx={{
                  overflowX: geocercas.length >= 5 ? "auto" : "visible",
                }}
              >
                {selectedGeocercas.map((geocerca) => (
                  <Grid
                    item
                    key={geocerca.id}
                    p={2}
                    width={"50%"}
                    height={"20%"}
                    border={1}
                    borderColor={"grey.100"}
                    sx={{ position: "relative" }}
                  >
                    <IconButton
                      size="small"
                      aria-label="close"
                      onClick={() => handleRemoveGeocerca(geocerca.id)}
                      sx={{
                        position: "absolute",
                        top: 0,
                        right: 0, // Posiciona el botón en la esquina superior derecha
                      }}
                    >
                      <CancelIcon fontSize="small" />
                    </IconButton>
                    {geocerca.nombre}
                  </Grid>
                ))}
              </Grid>
            ) : (
              <ImageContainer src={notFoundGeocerca} />
            )}
          </Box>
        </Box>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            variant="contained"
            startIcon={<SaveIcon />}
            color="primary"
            type="button"
            sx={{ width: "150px", padding: 2 }}
            onClick={() => {
              handleGuardarPrincipal();
              if (!isEditMode) nextComponent(1);
            }}
            disabled={!isEnableButton()}
          >
            {!isEditMode ? "Siguiente" : "Guardar"}
          </Button>
        </Box>
      </>
    );
  }
);
export default ListaGeocercas;
