import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { TextField, Button, Container, Typography, Box, Paper, Avatar, IconButton, InputAdornment, useTheme } from '@mui/material';
import { motion } from 'framer-motion';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { AuthContext } from '../contexts/AuthContext';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { styled } from '@mui/material/styles';
import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded';
import SecurityRoundedIcon from '@mui/icons-material/SecurityRounded';
import Footer from '../components/Footer';
import Logo from '../assets/logo_login.png';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import BusinessIcon from '@mui/icons-material/Business';
import SecurityIcon from '@mui/icons-material/Security';
import CircularProgress from '@mui/material/CircularProgress';
import { authService } from '../services/apiService';
import { useLoading } from '../contexts/LoadingContext';
import { decrypt } from '../utils/encryption';


const LoginForm = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [auth, setAuth] = useState(() => localStorage.getItem('auth') || false);
  const navigate = useNavigate();
  const location = useLocation();
  const { setAutenticado } = useContext(AuthContext);
  const theme = useTheme();
  const { login } = useContext(AuthContext);
  const { setIsLoading, setLoadingMessage } = useLoading();

  const DecorativeIcon = styled(Box)(({ theme, top, left, rotate }) => ({
    position: 'absolute',
    top,
    left,
    transform: `rotate(${rotate}deg)`,
    opacity: 0.03,
    zIndex: 0,
    fontSize: '4rem',
  }));

  useEffect(() => {
    const auth = localStorage.getItem('auth');
    if (auth === 'true') {
      setAutenticado(true);
      navigate('/');
    }
  }, [setAutenticado, navigate]);

  const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: theme.shape.borderRadius * 3,
    background: theme.palette.mode === 'dark'
      ? 'linear-gradient(135deg, rgba(30, 30, 30, 0.95) 0%, rgba(30, 30, 30, 0.98) 100%)'
      : 'linear-gradient(135deg, rgba(255, 255, 255, 0.95) 0%, rgba(255, 255, 255, 0.98) 100%)',
    backdropFilter: 'blur(20px)',
    boxShadow: theme.palette.mode === 'dark'
      ? '0 8px 32px rgba(0, 0, 0, 0.3)'
      : '0 8px 32px rgba(234, 0, 0, 0.1)',
    position: 'relative',
    overflow: 'hidden',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      height: '5px',
      background: 'linear-gradient(90deg, #ea0000, #000000, #ea0000)',
      backgroundSize: '200% 100%',
      animation: 'gradient 15s ease infinite',
    }
  }));

  const initialValues = {
    username: '',
    password: '',
  };

  const validationSchema = Yup.object({
    username: Yup.string().required('El usuario es obligatorio'),
    password: Yup.string().required('La contraseña es obligatoria'),
  }); 

  const handleSubmit = async (values, { setSubmitting, setStatus }) => {
    setStatus(null);
    setSubmitting(true);
    setLoadingMessage('Iniciando sesión...');
    setIsLoading(true);
  
    try {
      const credentials = {
        usuario: values.username,
        password: values.password,
      };
  
      const response = await authService.login(credentials);
  
      if (response.statusCode === 200) {
        const { token, nombre, uuid_empresa, uuid_usuario,nuevo_usuario } = response.data;
        //console.log('Login response:', response);
        // Guarda el token y otros datos si es necesario
        login(token, nombre, uuid_empresa, uuid_usuario,nuevo_usuario);
        setAutenticado(true);
        setStatus({ success: 'Inicio de sesión exitoso' });
        navigate('/');
      } else {
        setStatus({ error: response.message || 'Usuario o contraseña incorrectos' });
      }
    } catch (error) {
      console.error('Login error:', error);
      setStatus({ error: error.response?.data?.message || 'Error al intentar iniciar sesión' });
    } finally {
      setSubmitting(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const username = query.get('username');
    //const password = query.get('password');
    const encryptedPassword = query.get('password');
    console.log('Encrypted password:', encryptedPassword);
    const password = encryptedPassword ? decrypt(encryptedPassword) : '';
    console.log("Decrypted password", password);

    if (username && password) {
      handleSubmit({ username, password }, { setSubmitting: () => { }, setStatus: () => { } });
    }
  }, [location.search]);

  const StatusMessage = ({ status }) => {
    if (!status) return null;

    return (
      <motion.div
        initial={{ opacity: 0, y: -10 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 10 }}
      >
        <Typography
          variant="body2"
          align="center"
          sx={{
            mt: 2,
            p: 1,
            borderRadius: 1,
            color: status.error ? '#ea0000' : '#00c853',
            bgcolor: status.error
              ? 'rgba(234, 0, 0, 0.1)'
              : 'rgba(0, 200, 83, 0.1)',
          }}
        >
          {status.error || status.success}
        </Typography>
      </motion.div>
    );
  };

  const BackgroundPattern = styled(Box)(({ theme }) => ({
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: -1,
    opacity: 0.02,
    background: `
      linear-gradient(45deg, ${theme.palette.mode === 'dark' ? '#ffffff' : '#000000'} 25%, transparent 25%),
      linear-gradient(-45deg, ${theme.palette.mode === 'dark' ? '#ffffff' : '#000000'} 25%, transparent 25%),
      linear-gradient(45deg, transparent 75%, ${theme.palette.mode === 'dark' ? '#ffffff' : '#000000'} 75%),
      linear-gradient(-45deg, transparent 75%, ${theme.palette.mode === 'dark' ? '#ffffff' : '#000000'} 75%)
    `,
    backgroundSize: '20px 20px',
    backgroundPosition: '0 0, 0 10px, 10px -10px, -10px 0px',
  }));

  const FloatingIcon = styled(motion.div)(({ theme }) => ({
    position: 'absolute',
    color: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.03)' : 'rgba(0,0,0,0.03)',
    fontSize: '4rem',
  }));

  return (
    <Box sx={{
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      bgcolor: theme.palette.background.default,
      position: 'relative',
    }}>
      <BackgroundPattern />
      <Container maxWidth="sm">
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, ease: "easeOut" }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 8 }}>
            <StyledPaper>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ isSubmitting, status }) => (

                  <Form style={{ width: '100%' }}>
                    {/* Logo and Title */}
                    <motion.div
                      initial={{ scale: 0.8 }}
                      animate={{ scale: 1 }}
                      transition={{ duration: 0.5 }}
                    >
                      <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        mb: 4,
                      }}>
                        <Box
                          component="img"
                          src={Logo}
                          alt="Logo de Omisiones"
                          sx={{
                            height: { xs: 60, sm: 80 },
                            mr: 1,
                            filter: 'drop-shadow(2px 4px 6px rgba(0,0,0,0.1))',
                          }}
                        />
                      </Box>
                    </motion.div>

                    {/* Welcome Messages */}
                    <motion.div
                      initial={{ y: 20, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      transition={{ delay: 0.3 }}
                    >
                      <Typography
                        variant="h5"
                        align="center"
                        sx={{
                          color: theme.palette.text.secondary,
                          fontWeight: 500,
                          mb: 1
                        }}
                      >
                        Bienvenido de Nuevo
                      </Typography>
                      <Typography
                        variant="body1"
                        align="center"
                        sx={{
                          color: theme.palette.text.secondary,
                          mb: 4
                        }}
                      >
                        Por favor, inicia sesión para continuar
                      </Typography>
                    </motion.div>

                    {/* Form Fields */}
                    <Box sx={{ position: 'relative', width: '100%' }}>
                      <FloatingIcon
                        initial={{ opacity: 0, x: -50 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ delay: 0.5 }}
                        style={{ top: -20, left: -30 }}
                      >
                        <BusinessIcon sx={{ fontSize: 'inherit' }} />
                      </FloatingIcon>

                      <FloatingIcon
                        initial={{ opacity: 0, x: 50 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ delay: 0.7 }}
                        style={{ top: '50%', right: -30 }}
                      >
                        <SecurityIcon sx={{ fontSize: 'inherit' }} />
                      </FloatingIcon>

                      <Field
                        as={TextField}
                        name="username"
                        label="Usuario"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        error={Boolean(ErrorMessage)}
                        helperText={<ErrorMessage name="username" />}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <AccountCircleIcon sx={{ color: theme.palette.mode === 'dark' ? '#ea0000' : '#000000' }} />
                            </InputAdornment>
                          ),
                        }}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.23)' : 'rgba(0, 0, 0, 0.23)',
                              borderWidth: 2,
                            },
                            '&:hover fieldset': {
                              borderColor: '#ea0000',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: '#ea0000',
                            },
                          },
                          '& .MuiInputBase-input': {
                            padding: '16px 14px 16px 0',
                          },
                        }}
                      />

                      <Field
                        as={TextField}
                        name="password"
                        type={showPassword ? 'text' : 'password'}
                        label="Contraseña"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        error={Boolean(ErrorMessage)}
                        helperText={<ErrorMessage name="password" />}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <VpnKeyIcon sx={{ color: theme.palette.mode === 'dark' ? '#ea0000' : '#000000' }} />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              {/* <button
                                type="button"
                                onClick={(e) => {
                                  e.preventDefault(); // Prevenir el envío del formulario
                                  setShowPassword(!showPassword);
                                }}
                                style={{ background: 'none', border: 'none', cursor: 'pointer' }}
                              >
                                <IconButton
                                  edge="end"
                                >
                                  {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </button> */}

                              {/* <IconButton
                              type="button"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setShowPassword(!showPassword);
                                }}
                                edge="end"
                                
                              >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton> */}
                              {/* <IconButton onClick={() => setShowPassword(!showPassword)} edge="end"  type="button">
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton> */}
                            </InputAdornment>
                          ),
                        }}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.23)' : 'rgba(0, 0, 0, 0.23)',
                              borderWidth: 2,
                            },
                            '&:hover fieldset': {
                              borderColor: '#ea0000',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: '#ea0000',
                            },
                          },
                          '& .MuiInputBase-input': {
                            padding: '16px 14px 16px 0',
                          },
                        }}
                      />
                    </Box>

                    {/* Submit Button */}
                    <motion.div
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                    >
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        disabled={isSubmitting}
                        sx={{
                          mt: 3,
                          mb: 2,
                          bgcolor: isSubmitting ? 'grey.600' : '#ea0000',
                          color: '#ffffff',
                          padding: '12px',
                          fontSize: '1.1rem',
                          fontWeight: 600,
                          '&:hover': {
                            bgcolor: '#000000',
                          },
                          '&.Mui-disabled': {
                            bgcolor: 'grey.600',
                            color: '#ffffff',
                          },
                        }}
                      >
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1 }}>
                          {isSubmitting && <CircularProgress size={20} color="inherit" />}
                          {isSubmitting ? 'Iniciando sesión...' : 'Iniciar Sesión'}
                        </Box>
                      </Button>
                    </motion.div>
                    <StatusMessage status={status} />

                  </Form>
                )}
              </Formik>
            </StyledPaper>
          </Box>
        </motion.div>
      </Container>

     {/*
     <Box sx={{ mt: 'auto' }}>
        <Footer />
      </Box>
      */} 
    </Box>
  );
};

export default LoginForm;