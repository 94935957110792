import React from 'react';
import { Box, Typography } from '@mui/material';

const EmptyState = ({ message = "No hay datos para mostrar" }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 4,
        minHeight: '400px'
      }}
    >
      <svg
        width="200"
        height="200"
        viewBox="0 0 400 400"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {/* Fondo circular */}
        <circle cx="200" cy="200" r="180" fill="#f5f5f5" />
        
        {/* Documento base */}
        <path
          d="M140 100h120v200H140z"
          fill="#e0e0e0"
          stroke="#9e9e9e"
          strokeWidth="4"
        />
        
        {/* Líneas de texto */}
        <path
          d="M160 140h80M160 170h80M160 200h40"
          stroke="#bdbdbd"
          strokeWidth="8"
          strokeLinecap="round"
        />
        
        {/* Lupa */}
        <circle
          cx="240"
          cy="220"
          r="40"
          fill="none"
          stroke="#2196f3"
          strokeWidth="8"
        />
        <path
          d="M270 250l30 30"
          stroke="#2196f3"
          strokeWidth="8"
          strokeLinecap="round"
        />
      </svg>

      <Typography
        variant="h6"
        color="text.secondary"
        sx={{ mt: 2, textAlign: 'center' }}
      >
        {message}
      </Typography>
    </Box>
  );
};

export default EmptyState;