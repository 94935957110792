import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Paper,
  Grid,
  Typography,
  MenuItem,
  Select,
  FormControl,
  Checkbox,
  ListItemText,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { motion } from 'framer-motion';
import { eventoService, vehiculoService } from '../../services/apiService';

const SearchForm = ({ onFilterSubmit }) => {
   // Establecer valores iniciales para las fechas
   const now = new Date();
   const startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0); // Inicio del día (00:00:00)
   const endOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59); // Fin del día (23:59:59)

   // Inicializar fechas con el día actual
   const [horaInicio, setHoraInicio] = useState(startOfDay); 
   const [fechaFinal, setFechaFinal] = useState(endOfDay); 
  const [eventos, setEventos] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [selectedAlertas, setSelectedAlertas] = useState([]);
  const [selectedVehicles, setSelectedVehicles] = useState([]);

  useEffect(() => {
    const loadEventos = async () => {
      try {
        const response = await eventoService.getEventos();
        if (response && response.statusCode === 200) {
          setEventos(response.data);
        }
      } catch (error) {
        console.error('Error cargando eventos:', error);
      }
    };

    const loadVehiculos = async () => {
      try {
        const response = await vehiculoService.getVehiculos();
        if (response && response.statusCode === 200) {
          setVehicles(response.data);
        }
      } catch (error) {
        console.error('Error cargando vehículos:', error);
      }
    };

    loadEventos();
    loadVehiculos();
  }, []);

  // Al cargar los filtros iniciales, enviarlos al componente padre
  useEffect(() => {
    const filtrosIniciales = {
      inicio: horaInicio.toISOString(),
      fin: fechaFinal.toISOString(),
      activo: true,
      vehiculos: selectedVehicles,
      eventos: selectedAlertas,
    };
    onFilterSubmit(filtrosIniciales);
  }, []); // Ejecuta solo al montar el componente

  const handleSubmit = () => {
    const filtros = {
      inicio: horaInicio.toISOString(),
      fin: fechaFinal.toISOString(),
      activo: true,
      vehiculos: selectedVehicles,
      eventos: selectedAlertas,
    };

    // Enviar filtros al componente padre
    onFilterSubmit(filtros);
  };

  const handleSelectAllAlertas = (event) => {
    setSelectedAlertas(event.target.checked ? eventos.map((e) => e.id) : []);
  };

  const handleSelectAllVehicles = (event) => {
    setSelectedVehicles(event.target.checked ? vehicles.map((v) => v.id) : []);
  };

  const handleAlertasChange = (event) => {
    const value = event.target.value;
    setSelectedAlertas(value.includes('all') ? eventos.map((e) => e.id) : value);
  };

  const handleVehiclesChange = (event) => {
    const value = event.target.value;
    setSelectedVehicles(value.includes('all') ? vehicles.map((v) => v.id) : value);
  };

  return (
    <Paper
      elevation={3}
      sx={{
        p: 3,
        mb: 4,
        borderRadius: '8px',
        backgroundColor: '#FFFFFF',
        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
        position: 'relative',
         width: "100%"
      }}
      component={motion.div}
      initial={{ y: -20, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
    >
      {/* Título del formulario */}
      <Typography
        variant="h6"
        sx={{
          fontWeight: 700,
          fontSize: '18px',
          mb: 2,
        }}
      >
        Filtro
      </Typography>

      {/* Línea roja */}
      <div
        style={{
          width: '100%',
          height: '2px',
          backgroundColor: '#FF0000',
          marginBottom: '16px',
        }}
      ></div>

      {/* Formulario */}
      <Grid container spacing={3} alignItems="center">
        {/* Hora de inicio */}
        {/* <Grid item xs={12} sm={6} md={3}>
          <Typography variant="body2" sx={{ fontWeight: 600, mb: 1 }}>
            Hora de inicio
          </Typography>
          <DateTimePicker
            value={horaInicio}
            onChange={(newValue) => setHoraInicio(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                placeholder="DD/MM/AAAA HH:mm:ss"
                variant="outlined"
                sx={{
                  '& .MuiInputBase-root': {
                    width: '100%',
                  },
                  '& .MuiOutlinedInput-input': {
                    fontSize: '14px',
                    padding: '10px',
                  },
                }}
              />
            )}
            inputFormat="dd/MM/yyyy HH:mm:ss"
            ampm={false} // Usar formato de 24 horas
            sx={{ width: '100%' }} // Asegura que el DateTimePicker ocupe el 100% del ancho
          />
        </Grid> */}

        {/* Fecha final */}
        {/* <Grid item xs={12} sm={6} md={3}>
          <Typography variant="body2" sx={{ fontWeight: 600, mb: 1 }}>
            Fecha final
          </Typography>
          <DateTimePicker
            value={fechaFinal}
            onChange={(newValue) => setFechaFinal(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                placeholder="DD/MM/AAAA HH:mm:ss"
                variant="outlined"
                sx={{
                  '& .MuiInputBase-root': {
                    width: '100%',
                  },
                  '& .MuiOutlinedInput-input': {
                    fontSize: '14px',
                    padding: '10px',
                  },
                }}
              />
            )}
            inputFormat="dd/MM/yyyy HH:mm:ss"
            ampm={false} // Usar formato de 24 horas
            sx={{ width: '100%' }} // Asegura que el DateTimePicker ocupe el 100% del ancho
          />
        </Grid> */}

        {/* Tipo de alertas */}
        <Grid item xs={12} sm={6} md={3}>
          <Typography variant="body2" sx={{ fontWeight: 600, mb: 1 }}>
            Tipo de alertas
          </Typography>
          <FormControl fullWidth>
            <Select
              multiple
              value={selectedAlertas}
              onChange={handleAlertasChange}
              placeholder="Selecciona el tipo de alerta"
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return <em>Selecciona el tipo de alerta</em>;
                }
                return selected.length === eventos.length
                  ? 'Todos los tipos'
                  : selected.map((id) => eventos.find((e) => e.id === id)?.nombre).join(', ');
              }}
            >
              <MenuItem value="all">
                <Checkbox
                  checked={selectedAlertas.length === eventos.length}
                  onChange={handleSelectAllAlertas}
                />
                <ListItemText primary="Seleccionar todos" />
              </MenuItem>
              {eventos.map((evento) => (
                <MenuItem key={evento.id} value={evento.id}>
                  <Checkbox checked={selectedAlertas.includes(evento.id)} />
                  <ListItemText primary={evento.nombre} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Vehículo */}
        <Grid item xs={12} sm={6} md={3}>
          <Typography variant="body2" sx={{ fontWeight: 600, mb: 1 }}>
            Vehículo
          </Typography>
          <FormControl fullWidth>
            <Select
              multiple
              value={selectedVehicles}
              onChange={handleVehiclesChange}
              renderValue={(selected) =>
                selected.length === vehicles.length
                  ? 'Todos los vehículos'
                  : selected.map((id) => vehicles.find((v) => v.id === id)?.alias).join(', ')
              }
            >
              <MenuItem value="all">
                <Checkbox
                  checked={selectedVehicles.length === vehicles.length}
                  onChange={handleSelectAllVehicles}
                />
                <ListItemText primary="Seleccionar todos" />
              </MenuItem>
              {vehicles.map((vehicle) => (
                <MenuItem key={vehicle.id} value={vehicle.id}>
                  <Checkbox checked={selectedVehicles.includes(vehicle.id)} />
                  <ListItemText primary={`${vehicle.alias} - ${vehicle.no_Economico}`} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      {/* Botón buscar */}
      <Grid container mt={2} justifyContent="flex-end">
        <Grid item>
          <Button
            variant="contained"
            onClick={handleSubmit}
            sx={{
              backgroundColor: '#FF0000',
              color: '#FFFFFF',
              borderRadius: '8px',
              height: '55px',
              width: '140px',
              '&:hover': { backgroundColor: '#D00000' },
            }}
          >
            Buscar
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default SearchForm;
