import React, { useState, useEffect,useRef,useCallback,useMemo } from 'react';
import { Box, Typography, List, ListItem, ListItemIcon, 
  ListItemText, Paper, IconButton,ListItemButton,Collapse,
  Avatar,useTheme,Tooltip } from '@mui/material';
  import {
    ExpandLess,
    ExpandMore,
    Map as MapIcon,
    Circle as CircleIcon,
  } from '@mui/icons-material';
  import LocationOnIcon from '@mui/icons-material/LocationOn';

  import { motion, AnimatePresence } from 'framer-motion';  
import { wsService } from '../../services/webSocketService';
import { getAlertIcon,getTextEmoji } from '../../utils/alertUtils';
import WarningIcon from '../../assets/warning.svg';
import LocationIcon from '../../assets/location.svg';
import carmap from '../../assets/svg_images/car_.svg'; 

import { VariableSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
 
const COLLAPSED_HEIGHT = 140; // Adjust this value
const EXPANDED_HEIGHT = 270; // Adjust this value based on the expanded item content
const TRANSITION_DURATION = 500; // en ms
const EASING = 'cubic-bezier(0.4, 0, 0.2, 1)';

const transformWebSocketData = async (wsData) => {
  try {    

    const eventDate = new Date(wsData.FechaHora_Evento);    
       
        const lat1 = wsData?.latitud || 0;
        const lng1 = wsData?.longitud || 0;  
        
        const lat = parseFloat(lat1);
        const lng = parseFloat(lng1);
    
        if (Number.isNaN(lat) || Number.isNaN(lng)) {
          throw new Error('Coordenadas inválidas');
        }
        
        const direccion = wsData?.Direccion || 'No disponible';
        //  (lat && lng) ? 
        //   await getAddressFromCoordinates(lat, lng) : 
        //   'Dirección no disponible';
          // fecha: eventDate.toLocaleString('es-MX', {
          //   timeZone: 'America/Mexico_City',
          //   year: 'numeric',
          //   month: '2-digit',
          //   day: '2-digit',
          //   hour: '2-digit',
          //   minute: '2-digit',
          //   second: '2-digit',
          //   hour12: true
          // }),
    return {
      id: wsData.IdUnico,
      FechaHora_Evento: wsData.FechaHora_Evento,
      fecha: eventDate.toLocaleString('es-MX', {
        timeZone: 'America/Mexico_City',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
      }),
      UID_Vehiculo: wsData.UID_Vehiculo,
      unidad: wsData.Alias || wsData.NoEconomico,
      chofer: wsData.chofer,
      telefono: wsData.Telefono,
      alerta: wsData.Nombre_TipoAlerta,
      estado: determinarEstado(wsData.Riesgo),
      geocerca: wsData.Nombre_Geocerca,
      ubicacion: {
        lat,
        lng,
        direccion
      },
      adicional: {
        placas: wsData.Placas,
        velocidad: wsData.velocidadOriginal,
        odometro: wsData.odometroOriginal,
        empresa: wsData.Nombre_Empresa,
         mensaje: wsData.message || 'Sin mensaje'
      },
      cog: wsData.cog,
      UID_TipoAlerta: wsData.UID_TipoAlerta,
      alert_type : wsData.Tipo_Alerta
    };

    console.log('Datos transformados:', wsData);
  } catch (error) {
    console.error('Error transformando datos:', error);
    return null;
  }
};

const determinarEstado = (riesgo) => {
  switch (riesgo) {
    case 'Alto':
      return 'rojo';
    case 'Medio':
      return 'amarillo';
    case 'Bajo':
      return 'verde';
    default:
      return 'verde';
  }
};
const getStatusColor = (estado) => {
  switch (estado.toLowerCase()) {
    case 'rojo':
      return '#ff4444';
    case 'amarillo':
      return '#ff9800';
    default:
      return '#4CAF50';
  }
};

const formatPhoneNumber = (phoneNumber) => {
  // Remover cualquier caracter que no sea número
  const cleaned = ('' + phoneNumber).replace(/\D/g, '');
  
  // Verificar si tiene 10 dígitos
  if (cleaned.length === 10) {
    return `(${cleaned.slice(0,3)})${cleaned.slice(3,6)}-${cleaned.slice(6)}`;
  }
  
  // Si no tiene 10 dígitos, retornar el original o 'No disponible'
  return phoneNumber || 'Sin número';
};


const removeEmojis = (text) => {
  return text.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|[\uD83C-\uDBFF\uDC00-\uDFFF]|[\u0023-\u0039]\uFE0F?\u20E3|[\u3297\u3299]|[\u303D\u3030]|[\u24C2]|[\u25AA-\u25AB]|[\u25B6]|[\u25C0]|[\u25FB-\u25FE]|[\u00A9\u00AE]|[\u2122\u2139]|[\u0020-\u0039]\uFE0F|[\u2194-\u21AA]|[\u2B05-\u2B07]|[\u2934\u2935]|[\u2B1B\u2B1C]|[\u2B50]|[\u231A\u231B]|[\u23E9-\u23F3]|[\u23F8-\u23FA]|[\u25AA-\u25AB]|[\u25B6]|[\u25C0]|[\u25FB-\u25FE]|[\u00A9\u00AE]|[\u2122\u2139]|[\u0020-\u0039]\uFE0F|[\u2194-\u21AA]|[\u2B05-\u2B07]|[\u2934\u2935]|[\u2B1B\u2B1C]|[\u2B50]|[\u231A\u231B]|[\u23E9-\u23F3]|[\u23F8-\u23FA]|⚡)/g, '');
};

const sortByTime = (alerts) => {
  return [...alerts].sort((a, b) => {
    const dateA = new Date(a.FechaHora_Evento);
    const dateB = new Date(b.FechaHora_Evento);
    return dateB - dateA;
  });
};

const AlertList = ({ onMapIconClick , onNewAlert,onLoadComplete,tourAlertId,filters}) => {
  const [wsStatus, setWsStatus] = useState('disconnected')
  const [alerts, setAlerts] = useState([]);
  const [filteredAlerts, setFilteredAlerts] = useState([]);

  const [openAlertIds, setOpenAlertIds] = useState([]);
  const theme = useTheme();
  const listRef = useRef();
  const [itemHeights, setItemHeights] = useState({});
  const itemHeightsRef = useRef({});
  const prevAlertsLengthRef = useRef(filteredAlerts.length);


  
  const handleNewAlert = useCallback((rawData) => {
    const { type, data } = rawData;
    console.log('Procesando mensaje tipo:', type);

    if (type === 'history') {
      const processHistoricalAlerts = async () => {
        console.log('Procesando histórico inicial...');
        const transformedAlerts = await Promise.all(
          data.map(alert => transformWebSocketData(alert))
        );

        const validAlerts = transformedAlerts.filter(alert => alert !== null);
        const sortedAlerts = sortByTime(validAlerts);

        console.log('Alertas históricas procesadas:', sortedAlerts.length);

        setAlerts(sortedAlerts);
        setFilteredAlerts(sortedAlerts);

        if (listRef.current) {
          setTimeout(() => {
            listRef.current.resetAfterIndex(0);
          }, 0);
        }

        //sortedAlerts.forEach(alert => onNewAlert(alert));
        sortedAlerts.slice().reverse().forEach(alert => onNewAlert(alert));
      };

      processHistoricalAlerts();
      return;
    }

    const processAlert = async (alert) => {
      const transformedAlert = await transformWebSocketData(alert);
      if (transformedAlert) {
        setAlerts(prev => {
          const newAlerts = [transformedAlert, ...prev];
          return newAlerts;
        });

        setFilteredAlerts(prevFiltered => {
          const newFiltered = [transformedAlert, ...prevFiltered];
          return sortByTime(newFiltered);
        });

        if (listRef.current) {
          setTimeout(() => {
            listRef.current.resetAfterIndex(0, false);
          }, 0);
        }

        onNewAlert(transformedAlert);
      }
    };

    if (type === 'update') {
      if (Array.isArray(data)) {
        data.forEach(processAlert);
      } else if (data) {
        processAlert(data);
      }
    }
  }, [onNewAlert]);
  

  useEffect(() => {
    let isSubscribed = true;
  
    const handleStatus = (status) => {
      if (!isSubscribed) return;
      
      if (status === 'connected') {
        onLoadComplete?.();
      }
      console.log('WebSocket status:', status);
      setWsStatus(status);
    };
  
    wsService.onStatusChange = handleStatus;
    wsService.connect(handleNewAlert);
  
    return () => {
      isSubscribed = false;
      wsService.disconnect();
    };
  }, []);

  useEffect(() => {
    const applyFilters = () => {
      const { horaInicial, horaFinal, vehiculos, tiposAlerta } = filters;
  
      const toSeconds = (time) => {
        if (!time) return null;
        return time.getHours() * 3600 + time.getMinutes() * 60 + time.getSeconds();
      };
  
      const inicialSeconds = horaInicial ? toSeconds(horaInicial) : null;
      const finalSeconds = horaFinal ? toSeconds(horaFinal) : null;
  
      const filtered = sortByTime(alerts.filter((alert) => {//alerts.filter((alert) => {
        const [alertH, alertM, alertS] = alert.fecha.split(':').map(Number);
        const alertTotalSeconds = alertH * 3600 + alertM * 60 + alertS;
  
        const vehiculoMatch = vehiculos?.length > 0 ? 
          vehiculos.includes(alert.UID_Vehiculo) : true;
        
        const alertaMatch = tiposAlerta?.length > 0 ? 
          tiposAlerta.includes(alert.UID_TipoAlerta) : true;
        
        let horaMatch = true;
        if (inicialSeconds !== null && finalSeconds !== null) {
          horaMatch = alertTotalSeconds >= inicialSeconds && 
                     alertTotalSeconds <= finalSeconds;
        }
  
        return vehiculoMatch && alertaMatch && horaMatch;
      }));
  
      setFilteredAlerts(filtered);
    };
  
    applyFilters();
  }, [alerts, filters]);

  const filteredAlertsMemo = useMemo(() => filteredAlerts, [filteredAlerts]);

  // const handleCollapseClick = useCallback((id) => {
  //   setOpenAlertIds(prevOpenAlertIds => {
  //     const isOpen = prevOpenAlertIds.includes(id);
  //     const newOpenAlertIds = isOpen
  //       ? prevOpenAlertIds.filter(openId => openId !== id)
  //       : [...prevOpenAlertIds, id];
  
  //     const index = filteredAlerts.findIndex(alert => alert.id === id);
      
  //     if (listRef.current) {
  //       const currentOffset = listRef.current.state.scrollOffset;
        
  //       // Usar setTimeout para dar tiempo a la animación
  //       setTimeout(() => {
  //         listRef.current.resetAfterIndex(index, false);
  //         listRef.current.scrollTo(currentOffset);
  //       }, 50);
  //     }
  
  //     return newOpenAlertIds;
  //   });
  // }, [filteredAlerts]); 

  const handleCollapseClick = useCallback((id) => {
    setOpenAlertIds(prevOpenAlertIds => {
      const newOpenAlertIds = prevOpenAlertIds.includes(id)
        ? prevOpenAlertIds.filter(openId => openId !== id)
        : [...prevOpenAlertIds, id];
  
      const index = filteredAlerts.findIndex(alert => alert.id === id);
      if (listRef.current) {
        listRef.current.resetAfterIndex(index);
      }
  
      return newOpenAlertIds;
    });
  }, [filteredAlerts]);

  const getItemSize = useCallback((index) => {
    const alert = filteredAlerts[index];
    const isOpen = openAlertIds.includes(alert.id);
    return isOpen ? EXPANDED_HEIGHT : COLLAPSED_HEIGHT;
  }, [filteredAlerts, openAlertIds]);

  useEffect(() => {
    if (listRef.current && prevAlertsLengthRef.current !== filteredAlerts.length) {
      const scrollOffset = listRef.current.state.scrollOffset;
      listRef.current.resetAfterIndex(0, false);
      listRef.current.scrollTo(scrollOffset);
      prevAlertsLengthRef.current = filteredAlerts.length;
    }
  }, [filteredAlerts]);

  const RenderRow = React.memo(({ index, style, data }) => {
    const alert = data[index];
    const isOpen = openAlertIds.includes(alert.id);
    const borderColor = getStatusColor(alert.estado);
    const isInTour = alert.id === tourAlertId;

    return (
      // <div style={{ ...style, height: isOpen ? EXPANDED_HEIGHT : COLLAPSED_HEIGHT }}>
      <div 
      style={{ 
        ...style,
        height: isOpen ? EXPANDED_HEIGHT : COLLAPSED_HEIGHT,
        transition: `height ${TRANSITION_DURATION}ms ${EASING}`
      }}
    >
        <motion.div
          layout
          key={alert.id}
          //initial={{ opacity: 0 }}
          initial={false}
          animate={{ opacity: 1}} //, x: 0 , scale: isInTour ? 1.02 : 1}}
          exit={{ opacity: 0 }}
          transition={{ 
            duration: 0.2,
            layout: { duration: 0.2 }
          }}
          // transition={{ 
          //   duration: 0.5,
          //   delay: index * 0.1,
          //   type: "spring",
          //   stiffness: 100
          // }}
           style={{ 
             width: '95%', 
          //   paddingRight: '4px' 
           }}
        >
          <ListItemButton
            // button
            //component={motion.div}
            //disablePadding
            onClick={() => handleCollapseClick(alert.id)}
            elevation={isInTour ? 6 : 3}
            sx={{ 
             mb: 1,
             borderLeft: `4px solid ${borderColor}`,
             borderTop: `4px solid ${borderColor}`,
             borderRadius: '8px',
             transition: 'all 0.3s ease',
             px: 0,
             ...(isInTour && {
               boxShadow: `0 0 0 2px ${theme.palette.primary.main}`,
               transform: 'scale(1.02)'
             })
           }}
           component={motion.div}
           whileHover={{ scale: isInTour ? 1.02 : 1.01 }}   
          >

             {/* Icono de alerta */}
  <Box 
  sx={{ 
    // width: 80,
    display: 'flex',
    //justifyContent: 'center',
    //alignItems: 'center',
    padding: 0, // Quitar padding interno
    margin: 0,  // Quitar margin
    marginRight: '2px',
    marginLeft: '3px',
    // alignItems: 'center', // Añadir alineación vertical
    // alignSelf: 'center', // Alinear el box mismo al centro del contenedor padre
    // height: '100%' // Asegurar que tome toda la altura disponible
  }}
  >
    <img 
      src={getAlertIcon(alert.alert_type)} 
      alt={alert.alerta}
      style={{ width: 36, height: 36 }}
    />
  </Box>

  {/* Contenedor principal de información */}
  <Box 
  sx={{
     flex: 1, display: 'flex', flexDirection: 'column', gap: 0.5,
     position: 'relative',
     px: 2,
    '&::before': {
      content: '""',
      position: 'absolute',     
      left: '3px',
      top: 0,
      height: '100%',
      width: '2px',
      backgroundColor: (theme) => theme.palette.divider
    }
  }} 
  >
      {/* Primera fila - 3 columnas con espacio en medio */}
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between',
        width: '100%',
        mb: 1,
        pb: 1,
        borderBottom: '3px solid',
        borderColor: 'divider',   
      }}>
        <Box sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'flex-start',
          width: '50%'
        }}>
          <Typography variant="overline" color="textSecondary" style={{ fontSize: '8px' }}>
            Tipo de alerta
          </Typography>
          <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
            {alert.alerta}
          </Typography>
        </Box>

        <Box sx={{ width: '10%' }} /> {/* Espacio en blanco */}

        <Box sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'flex-start',
          width: '30%'
        }}>
          <Typography variant="overline" color="textSecondary" style={{ fontSize: '8px' }}>
            Unidad
          </Typography>
          <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
            {alert.unidad}
          </Typography>
        </Box>
      </Box>

      {/* Segunda fila - 3 columnas distribuidas */}
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between',
        width: '100%'
      }}>
        {[
          { label: 'Hora', value: alert.fecha },
          { label: 'Chofer', value: alert.chofer || 'Sin chofer' },
          { label: 'Teléfono', value: formatPhoneNumber(alert.telefono) } 
        ].map(item => (
          <Box key={item.label} sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'flex-start',
            width: '30%'
          }}>
            <Typography variant="overline" color="textSecondary" style={{ fontSize: '8px' }}>
              {item.label}
            </Typography>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              {item.value}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
      {/* Botones de acción */}
      <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column', // Cambio principal para alinear verticalmente
      alignItems: 'flex-start',
      gap: 1, // Espacio entre botones
      ml: 1 // Margen izquierdo opcional
    }}>

      <Tooltip title={isOpen ? "Ocultar detalles" : "Mostrar detalles"} arrow>
      <IconButton>
        {isOpen ? <ExpandLess /> : <ExpandMore />}
      </IconButton>
      </Tooltip>

    
    <IconButton 
    onClick={(e) => {
    e.stopPropagation();
    onMapIconClick(alert.ubicacion, alert);
  }}
  sx={{ 
    '& img': {
      width: 36,
      height: 36
    }
  }}
>
  <Tooltip title="Ver ubicación en el mapa" arrow placement="top">
    <LocationOnIcon 
      sx={{ 
        color: '#E41E31', 
        fontSize: 24,
        cursor: 'pointer'
      }} 
    />
  </Tooltip>
</IconButton>
    
    </Box>

          
          </ListItemButton>
          <Collapse 
          in={isOpen} 
          timeout={TRANSITION_DURATION}
          unmountOnExit
          sx={{
            transition: `all ${TRANSITION_DURATION}ms ${EASING}`,
          }}
        >
              <Box p={2}   
              sx={{
                position: 'relative',
                '&::before': {
                  content: '""',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  height: '10px',
                  background: 'linear-gradient(180deg, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 100%)'
                }
              }}           
              >
              {/* Primer bloque - mensaje */}
              <Box 
                display="flex" 
                alignItems="center"
                mb={1}
              >
                <Box 
                  display="flex" 
                  alignItems="center" 
                  alignSelf="stretch"
                  mr={1}
                >
                  <img
                    src={WarningIcon}
                    alt="Warning"
                    style={{ 
                      width: '24px', 
                      height: '24px',
                      flexShrink: 0,
                    }}
                  />
                </Box>
                <Typography variant="caption">
                  {removeEmojis(alert?.adicional?.mensaje || 'Sin mensaje')}
                </Typography>
              </Box>

              {/* Segundo bloque - dirección */}
              <Box 


                display="flex" 
                alignItems="center"
              >
                <Box 
                  display="flex" 
                  alignItems="center" 
                  alignSelf="stretch"
                  mr={1}
                >
                  <img
                    src={LocationIcon}
                    alt="Location"
                    style={{ 
                      width: '24px', 
                      height: '24px',
                      flexShrink: 0,
                    }}
                  />
                </Box>
                <Typography variant="caption">
                  {alert?.ubicacion.direccion || 'No disponible'}
                </Typography>
              </Box>
            </Box>
                
              </Collapse>
        </motion.div>
      </div>
    );
  });


  return (
    <Box 
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        pl: 2, // left padding
        pr: 2, // right padding
        pb: 2, // bottom padding
        bgcolor: 'background.paper'
      }}
    >
      <Typography variant="h6" gutterBottom>
        Lista de Alertas
        <Typography 
          component="span" 
          color={wsStatus === 'connected' ? 'success.main' : 'error.main'}
          ml={2}
        >
          ({wsStatus})
        </Typography>
      </Typography>

      {/* <Box 
  sx={{
    width: '100%',
    height: '100%',
    maxWidth: '100vw',
    overflow: 'hidden'
  }}
>
  <VariableSizeList
    ref={listRef}
    height={600}
    width="100%"
    itemCount={filteredAlertsMemo.length}
    itemSize={getItemSize}
    itemData={filteredAlertsMemo}
    overscanCount={3}
    itemKey={(index) => filteredAlertsMemo[index].id}
    style={{
      overflowX: 'hidden',
      overflowY: 'auto',
      willChange: 'transform',
      maxWidth: '100%'
    }}
  >     
    {RenderRow}
  </VariableSizeList>
</Box> */}

      <VariableSizeList
        ref={listRef}
        height={600}
        width="100%"
        itemCount={filteredAlertsMemo.length}
        itemSize={getItemSize}
        itemData={filteredAlertsMemo}
        overscanCount={3}
        itemKey={(index) => filteredAlertsMemo[index].id}
        style={{
          overflowX: 'hidden',
          willChange: 'transform'
        }}
      >     
        {RenderRow}
      </VariableSizeList>

      
    </Box>
  );
};
export default AlertList;