import {
  Autocomplete,
  Checkbox,
  TextField,
  Grid,
  FormControlLabel,
  Divider,
  Box,
  Button,
  IconButton,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CancelIcon from "@mui/icons-material/Cancel";

import { useEffect, useState } from "react";
import { isEmptyArray } from "formik";
import SaveIcon from "@mui/icons-material/Save";

import { vehiculoService } from "../../../services/apiService";
import ImageContainer from "./ImageContainer";
import notFoundVehicle from "../../../assets/not-found/empty-space-vehiculos.png";
import { confirmDialog } from "../../../components/ConfirmDialog";

export default function ListaVehiculoCompare({
  configuracion,
  actualizarComponente,
  handleGuardarPrincipal,
  idAlerta,
  nextComponent,
  isEditMode,
}) {
  /*Lista de Vehiculos*/
  const [vehicles, setVehicles] = useState([]);
  const loadVehiculos = async () => {
    try {
      const response = await vehiculoService.getVehiculos("", 1);
      if (response.statusCode === 200) {
        setVehicles(response.data);
      }
    } catch (error) {
      console.error("Error cargando vehículos:", error);
    }
  };
  const [selectedVehicles, setSelectedVehicles] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const handleSelect = (vehicle) => {
    setSelectedVehicles(vehicle);
  };

  const handleToggleSelectAll = () => {
    setSelectAll((prev) => {
      if (!prev) setSelectedVehicles([...vehicles]);
      else setSelectedVehicles([]);
      return !prev;
    });
  };

  const handleAgregarVehiculo = async () => {
    const isConfirmed = await confirmDialog({
      title: "Crear vehículo",
      message:
        "Será redireccionado a Quro para crear el vehículo, ¿Está seguro de realizar esta acción?",
      confirmLabel: "Aceptar",
      cancelLabel: "Cancelar",
    });
    if (!isConfirmed) return;
  };

  const handleRemoveVehiculo = (id) => {
    setSelectedVehicles((prevSelected) =>
      prevSelected.filter((vehicle) => vehicle.id !== id)
    );
    if (selectedVehicles.length === 0) setSelectAll(false);
  };

  /*Lista de Vehiculos*/
  function isEnableButton() {
    return idAlerta !== "";
  }

  useEffect(() => {
    loadVehiculos();
  }, []);

  useEffect(() => {
    actualizarComponente(
      "vehiculo_compare",
      selectedVehicles.map((item) => {
        return item.id;
      })
    );
  }, [selectedVehicles]);

  useEffect(() => {
    //Validamos si el componente fue limpiado
    if (isEmptyArray(configuracion.vehiculo_compare)) {
      //refrescamos el state si es necesario
      if (!isEmptyArray(selectedVehicles)) {
        setSelectedVehicles([]);
      }
    } else {
      // cuando se obtiene configuración
      // Comparamos si las seleccionadas actuales coinciden con las de la bd
      const selectedFromConfig = configuracion.vehiculo_compare
        .map((vehicle_id) => {
          const vehicle_id_str = vehicle_id.replaceAll("'", "");

          //Buscamos los vehiculos seleccionados
          const matchingVehicle = vehicles.find(
            (vehicle) => vehicle.id === vehicle_id_str
          );

          //Valida si encontro vehiculos selecciono
          if (matchingVehicle) {
            return {
              ...matchingVehicle,
            };
          }
          return null;
        })
        .filter((item) => item !== null);

      // Verificamos si el estado actual ya tiene las geocercas seleccionadas
      const areEqual =
        selectedVehicles.length === selectedFromConfig.length &&
        selectedVehicles.every(
          (sel, index) => sel.id === selectedFromConfig[index].id
        );

      if (!areEqual) {
        setSelectedVehicles(selectedFromConfig); // Actualizamos solo si es necesario

        // Notificamos al componente padre si el estado cambia
        actualizarComponente(
          "vehiculo_compare",
          selectedFromConfig.map((item) => {
            return `${item.id}`;
          })
        );
      }
    }
  }, [configuracion.vehiculo_compare]);

  return (
    <>
      <Box
        height={310}
        pr={2}
        sx={{
          display: "flex",
          alignItems: "stretch",
          alignContent: "space-between",
        }}
      >
        <Box
          pt={1}
          maxWidth={500}
          minWidth={250}
          flexGrow={1}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"space-around"}
        >
          <Box display={"flex"} maxWidth={500} minWidth={250} flexGrow={1}>
            <Box flexGrow={1}>
              <Autocomplete
                id="checkboxes-vehiculo-compare"
                multiple
                noOptionsText={"No hay coincidencias"}
                disableCloseOnSelect
                disableListWrap
                options={vehicles}
                getOptionLabel={(option) =>
                  `${option.alias} - ${option.no_Economico}`
                }
                // value={selectedVehicles}
                value={selectedVehicles.map((selected) =>
                  vehicles.find((vehiculo) => vehiculo.id === selected.id)
                )}
                onChange={(_e, value, reason) => {
                  if (
                    reason === "removeOption" &&
                    selectedVehicles.length > 0
                  ) {
                    setSelectedVehicles(selectedVehicles);
                    return;
                  }
                  switch (reason) {
                    case "clear":
                      setSelectAll(false);
                      setSelectedVehicles([]);
                      break;
                    case "removeOption":
                      setSelectAll(false);
                      setSelectedVehicles(value);
                      break;
                    case "selectOption":
                      if (value.length === vehicles.length) setSelectAll(true);
                      setSelectedVehicles(value);
                      break;
                    default:
                      setSelectedVehicles(value);
                  }
                }}
                defaultChecked
                renderOption={(props, option, { selected }) => {
                  const { key, ...optionProps } = props;
                  return (
                    <li key={option.id} {...optionProps}>
                      <Checkbox
                        style={{ marginRight: 8 }}
                        checked={
                          selectedVehicles.find(
                            (value) => value.id === option.id
                          )
                            ? true
                            : false
                        }
                      />
                      {`${option.alias} - ${option.no_Economico}`}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Vehículos"
                    placeholder="Selecciona un vehículo"
                  />
                )}
                renderTags={(selectedOptions) => {
                  return <></>;
                }}
                groupBy={() => ""}
                renderGroup={(params) => (
                  <div key={params.key}>
                    <>
                      <Box
                        onMouseDown={(e) => e.preventDefault()} // prevent blur
                        pl={3.5}
                        py={0.5}
                      >
                        <FormControlLabel
                          onClick={(e) => {
                            e.preventDefault(); // prevent blur
                            handleToggleSelectAll();
                          }}
                          label="Seleccionar todos"
                          control={
                            <Checkbox
                              id="select-all-checkbox"
                              checked={selectAll}
                            />
                          }
                        />
                      </Box>
                      <Divider />
                    </>
                    {params.children}
                  </div>
                )}
              />
            </Box>
            <Button
              variant="contained"
              sx={{
                marginLeft: 2,
                width: 55,
                height: 55,
                bgcolor: "#ea0000",
                color: "#ffffff",
                padding: "12px",
                fontSize: "1.1rem",
                fontWeight: 600,
                "&:hover": {
                  bgcolor: "#000000",
                },
                "&.Mui-disabled": {
                  bgcolor: "grey.600",
                  color: "#ffffff",
                },
              }}
              onClick={handleAgregarVehiculo}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 1,
                }}
              >
                <AddCircleIcon />
              </Box>
            </Button>
          </Box>
        </Box>

        <Box marginLeft={2} display="flex">
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              bgcolor: "red",
              width: "2px",
            }}
          />
        </Box>

        <Box marginLeft={2} flexGrow={1} pt={1}>
          {selectedVehicles.length > 0 ? (
            <Grid
              container
              alignContent="flex-start"
              display={"flex"}
              direction={"column"}
              wrap="wrap"
              maxHeight={300}
              sx={{
                overflowX: vehicles.length >= 5 ? "auto" : "visible",
              }}
            >
              {selectedVehicles.map((vehiculo) => (
                <Grid
                  item
                  key={vehiculo.uuid + vehiculo.no_Economico}
                  p={2}
                  width={"50%"}
                  height={"20%"}
                  border={1}
                  borderColor={"grey.100"}
                  sx={{ position: "relative" }}
                >
                  <IconButton
                    size="small"
                    aria-label="close"
                    onClick={() => handleRemoveVehiculo(vehiculo.id)}
                    sx={{
                      position: "absolute",
                      top: 0,
                      right: 0, // Posiciona el botón en la esquina superior derecha
                    }}
                  >
                    <CancelIcon />
                  </IconButton>
                  {vehiculo.alias}
                </Grid>
              ))}
            </Grid>
          ) : (
            <ImageContainer src={notFoundVehicle} />
          )}
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          type="button"
          sx={{ width: "150px", padding: 2 }}
          startIcon={<SaveIcon />}
          onClick={() => {
            handleGuardarPrincipal();
            if (!isEditMode) nextComponent(3);
          }}
          disabled={!isEnableButton()}
        >
          {!isEditMode ? "Siguiente" : "Guardar"}
        </Button>
      </Box>
    </>
  );
}
