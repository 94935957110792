import React, { useEffect, useRef, useState } from "react";
import IconButton from "@mui/material/IconButton";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
export default function CustomAudioPlayer({ src }) {
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const handleMouseDown = (e) => e.stopPropagation();
  const handleAudioEnded = () => setIsPlaying(false);

  const handlePlay = (e) => {
    e.stopPropagation();
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
        setIsPlaying(false);
      } else {
        audioRef.current.play();
        setIsPlaying(true);
      }
    }
  };

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.load();
      setIsPlaying(false);
    }
  }, [src]);

  return (
    <>
      <IconButton
        children={
          isPlaying ? (
            <PauseCircleIcon fontSize="small" />
          ) : (
            <PlayCircleFilledIcon fontSize="small" />
          )
        }
        onClick={handlePlay}
        onMouseDown={handleMouseDown}
        sx={{
          width: "10px",
          height: "10px",
        }}
      />

      <audio ref={audioRef} onEnded={handleAudioEnded}>
        <source src={src} type={`audio/${src.split(".").pop()}`} />
        Tu navegador no soporta el elemento de audio.
      </audio>
    </>
  );
}
