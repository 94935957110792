import React from 'react';
import { TextField, MenuItem, InputAdornment, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

const ComboBoxWithIcon = ({ options, placeholder, tooltipTitle }) => {
  return (
    <TextField
      select
      variant="outlined"
      placeholder={placeholder}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Tooltip title={tooltipTitle}>
              <InfoIcon />
            </Tooltip>
          </InputAdornment>
        )
      }}
      sx={{
        '.MuiInputBase-input': {
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap'
        }
      }}
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

// Ejemplo de uso del componente ComboBoxWithIcon
const SoundList = () => {
  const options = [
    { value: 'option1', label: 'Esta es una opción muy larga que se trunca' },
    { value: 'option2', label: 'Opción 2' },
    { value: 'option3', label: 'Opción 3' },
  ];

  return (
    <div style={{ padding: '20px' }}>
      <ComboBoxWithIcon
        options={options}
        placeholder="Seleccionar opción"
        tooltipTitle="Información del ícono"
      />
    </div>
  );
};

export default SoundList;


// import React from 'react';
// import { List, ListItem, ListItemText, ListItemIcon, Card, CardContent, Typography } from '@mui/material';
// import { motion } from 'framer-motion';
// import MusicNoteIcon from '@mui/icons-material/MusicNote';

// function SoundList() {
//   // Use require.context to get all .mp3 and .wav files in the public/alertamp3 directory
//   const soundsContext = require.context('../../../public/alertas_audio', false, /\.(mp3|wav)$/);
//   const soundFiles = soundsContext.keys().map((key) => {
//     // Extract the file name from the key
//     const fileName = key.replace('./', '');
//     // Get the full path to the file
//     const filePath = `/alertas_audio/${fileName}`;
//     return { fileName, filePath };
//   });

//   return (
//     <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
//       {soundFiles.map((sound, index) => (
//         <motion.div
//           key={index}
//           initial={{ opacity: 0, y: 20 }}
//           animate={{ opacity: 1, y: 0 }}
//           transition={{ duration: 0.5, delay: index * 0.1 }}
//         >
//           <ListItem alignItems="flex-start">
//             <ListItemIcon>
//               <MusicNoteIcon />
//             </ListItemIcon>
//             <ListItemText
//               primary={sound.fileName}
//               secondary={
//                 <React.Fragment>
//                   <Typography
//                     sx={{ display: 'inline' }}
//                     component="span"
//                     variant="body2"
//                     color="text.primary"
//                   >
//                     Ruta: {sound.filePath}
//                   </Typography>
//                   <audio controls style={{ width: '100%' }}>
//                     <source src={sound.filePath} type={`audio/${sound.fileName.split('.').pop()}`} />
//                     Tu navegador no soporta el elemento de audio.
//                   </audio>
//                 </React.Fragment>
//               }
//             />
//           </ListItem>
//         </motion.div>
//       ))}
//     </List>
//   );
// }

// export default SoundList;

// import React from 'react';

// function SoundList() {
//   // Use require.context to get all .mp3 and .wav files in the public/alertamp3 directory
//   const soundsContext = require.context('../../../public/alertamp3', false, /\.(mp3|wav)$/);
//   const soundFiles = soundsContext.keys().map((key) => {
//     // Extract the file name from the key
//     const fileName = key.replace('./', '');
//     // Get the full path to the file
//     const filePath = `/alertamp3/${fileName}`;
//     return { fileName, filePath };
//   });

//   return (
//     <ul>
//       {soundFiles.map((sound, index) => (
//         <li key={index}>
//           <p>Nombre: {sound.fileName}</p>
//           <p>Ruta: {sound.filePath}</p>
//           <audio controls>
//             <source src={sound.filePath} type={`audio/${sound.fileName.split('.').pop()}`} />
//             Tu navegador no soporta el elemento de audio.
//           </audio>
//         </li>
//       ))}
//     </ul>
//   );
// }

// export default SoundList;

// import React from 'react';

// function SoundList() {
//   // Use require.context to get all .mp3 files in the public/sounds directory
//   const soundsContext = require.context('../../../public/alertamp3', false, /\.mp3$/);
//   const soundFiles = soundsContext.keys().map(soundsContext);

//   return (
//     <ul>
//       {soundFiles.map((sound, index) => (
//         <li key={index}>
//           <audio controls src={sound} />
//         </li>
//       ))}
//     </ul>
//   );
// }

// export default SoundList;

// //  const soundsContext = require.context('../../../public/alertamp3', false, /\.(mp3|wav)$/);
// import React from 'react';

// function SoundList() {
//   // Use require.context to get all .mp3 and .wav files in the public/sounds directory
//   const soundsContext = require.context('../../../public/alertamp3', false, /\.(mp3|wav)$/);
//   const soundFiles = soundsContext.keys().map((key) => {
//     // Extract the file name from the key
//     const fileName = key.replace('./', '');
//     // Get the full path to the file
//     const filePath = `/sounds/${fileName}`;
//     return { fileName, filePath };
//   });

//   return (
//     <ul>
//       {soundFiles.map((sound, index) => (
//         <li key={index}>
//           <p>Nombre: {sound.fileName}</p>
//           <p>Ruta: {sound.filePath}</p>
//           <audio controls>
//             <source src={sound.filePath} type={`audio/${sound.fileName.split('.').pop()}`} />
//             Tu navegador no soporta el elemento de audio.
//           </audio>
//         </li>
//       ))}
//     </ul>
//   );
// }

// export default SoundList;
