// contexts/AuthContext.js
import React, { createContext, useState, useEffect } from 'react';
import { empresaService } from '../services/apiService';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [autenticado, setAutenticado] = useState(() => {
    return localStorage.getItem('auth') === 'true';
  });
  
  // Añadir estado para el nombre de usuario
  const [userName, setUserName] = useState(() => {
    return localStorage.getItem('userName') || '';
  });

  const [uidExterno, setUidExterno] = useState(() => {
    return localStorage.getItem('uid_externo') || '';
  });

  useEffect(() => {
    if (autenticado) {
      localStorage.setItem('auth', 'true');
    } else {
      localStorage.removeItem('auth');
      localStorage.removeItem('userName');
      localStorage.removeItem('token');
      localStorage.removeItem('uuid_empresa');
      localStorage.removeItem('uuid_usuario');
      localStorage.removeItem('uid_externo');

    }
  }, [autenticado]);

  const login = async (token, nombre, uuid_empresa, uuid_usuario,nuevo_usuario) => {
    setAutenticado(true);
    setUserName(nombre);
    localStorage.setItem('auth', 'true');
    localStorage.setItem('token', token);
    localStorage.setItem('userName', nombre);
    localStorage.setItem('uuid_empresa', uuid_empresa);
    localStorage.setItem('uuid_usuario', uuid_usuario);
    localStorage.setItem('nuevo_usuario', nuevo_usuario); 

    console.log('nuevo_usuario:', nuevo_usuario);

    try {
      const empresaData = await empresaService.getEmpresas(uuid_empresa);
      console.log('empresaData:', empresaData);
      if (empresaData && empresaData.data[0].uiD_Externo) {
        console.log('empresaData.UID_Externo:', empresaData.data[0].uiD_Externo);
        localStorage.setItem('uid_externo', empresaData.data[0].uiD_Externo);
        setUidExterno(empresaData.data[0].uiD_Externo);
      }
    } catch (error) {
      console.error('Error fetching empresa UID_Externo:', error);
    }
  

  };

  const logout = () => {
    setAutenticado(false);
    setUserName('');
    setUserName('');
    
    localStorage.removeItem('auth');
    localStorage.removeItem('token');
    localStorage.removeItem('userName');
    localStorage.removeItem('uuid_empresa');
    localStorage.removeItem('uuid_usuario');
    localStorage.removeItem('uid_externo');
  };

  return (
    <AuthContext.Provider value={{ 
      autenticado, 
      setAutenticado, 
      userName,
      setUserName,
      uidExterno,
      login,
      logout 
    }}>
      {children}
    </AuthContext.Provider>
  );
};