//Componentes de la UI
import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
// import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import {
  DataGrid,
  GridActionsCellItem,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";

import { esES } from "@mui/x-data-grid/locales";
import {
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  Divider,
  FormControl,
  Select,
  MenuItem,
  Tooltip,
  Checkbox,
  ListItemText,
} from "@mui/material";
import Brightness1SharpIcon from "@mui/icons-material/Brightness1Sharp";
import AutoAwesomeMotionIcon from "@mui/icons-material/AutoAwesomeMotion";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import Modal from "@mui/material/Modal";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close"; // Necesario para botones de cierre
import CancelIcon from "@mui/icons-material/Cancel";
import PeopleIcon from "@mui/icons-material/People"; // Necesario para contactos
import DeleteIcon from "@mui/icons-material/Delete";
//Componentes para alertas
import HorarioConfigForm from "../alertas/components/HorarioConfigForm";
import ListaVehiculo from "../alertas/components/VehiculoForm";

//Servicios API
import { alertaService } from "../../services/apiService";
import ListaGeocercas from "../alertas/components/GeocercaForm";
import ListaAudios from "../alertas/components/AlertaSonidoForm";
import ListaVehiculoCompare from "../alertas/components/VehiculoCompareForm";
import ModalListaContactos from "../contactos/contactos-alertas/components/ListaContactosAlertas";
import { eventoService, vehiculoService } from "../../services/apiService";

//Estilo personalizado para la ventana modal
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

//Modulo Principal del componente -------------------------------------------------------------
const TableModule = ({
  alertList,
  onSelectionChange,
  handleEditarAlerta,
  setShowConfirmDialog,
  //selectedRows
}) => {
  //estados del componente alerta
  const [searchText, setSearchText] = useState(""); //Buscador de la tabla
  const [selectedRows, setSelectedRows] = useState([]); //Elementos seleccionados tabla
  const [alertaId, setAlertaId] = useState(""); // ID de la alerta seleccionada
  const [daysSel, setDaysSel] = React.useState(
    []
  ); /*Para el selector individual de dias*/
  const [openModalHours, setOpenHour] = React.useState(false); //Para la ventana modal (abrir horarios)
  const [openModalVehicle, setOpenVh] = React.useState(false); //Para la ventana modal (abrir vehiculos)
  const [openModalVehicleCompare, setOpenVhCo] = React.useState(false); //Para la ventana modal (abrir vehiculos)
  const [configuracion, setConfiguracion] = useState({
    //Configuracion de la vista
    nombre: "",
    tipo_alerta: {},
    geocerca: [],
    vehiculo: [],
    vehiculo_compare: [],
    contactos: [],
    riesgo: "",
    tiempo: 0,
    veces: 0,
    repetir: 1,
    velocidad_movimiento: 0,
    distancia_vehiculos: 0,
    tipo_marcado: 0,
    url_sonido: "",
    horario: [],
  });
  //geocercas
  const [openGeocercas, setOpenGeocercas] = React.useState(false);

  //audio
  const [openAudios, setOpenAudios] = React.useState(false);

  //contactos
  const [openContactos, setOpenContactos] = React.useState(false);

  /*
Nuevas importaciones
 */

  const [eventos, setEventos] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [selectedAlertas, setSelectedAlertas] = useState([]);
  const [selectedVehicles, setSelectedVehicles] = useState([]);

  const containerRef = React.useRef(null);
  const [gridWidth, setGridWidth] = React.useState(600);

  React.useEffect(() => {
    if (!containerRef.current) return;
    const observer = new ResizeObserver(([entry]) => {
      console.log("🔍 Observed width:", entry.contentRect.width);
      setGridWidth(600); //(entry.contentRect.width);
    });
    observer.observe(containerRef.current);
    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    const loadEventos = async () => {
      try {
        const response = await eventoService.getEventos();
        if (response && response.statusCode === 200) {
          setEventos(response.data);
        }
      } catch (error) {
        console.error("Error cargando eventos:", error);
      }
    };

    const loadVehiculos = async () => {
      try {
        const response = await vehiculoService.getVehiculos();
        if (response && response.statusCode === 200) {
          setVehicles(response.data);
        }
      } catch (error) {
        console.error("Error cargando vehículos:", error);
      }
    };

    loadEventos();
    loadVehiculos();
  }, []);

  //Se definenen las columnas de la tabla
  const columns = [
    //{ field: "fecha_alta", headerName: "Fecha", width: 200 },
    // { field: "nombre_usuario", headerName: "Usuario", width: 200 },
    { field: "TipoAlerta", headerName: "Tipo de alerta", width: 200 },
    { field: "nombre", headerName: "Detalle", width: 200 },
    {
      field: "geocerca",
      type: "actions",
      headerName: "Geocerca",
      width: 150,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<AutoAwesomeMotionIcon />}
          label="Modificar Geocercas"
          onClick={() => handleGeocercaProcess(params.row)}
        />,
      ],
    },
    {
      field: "vehiculo",
      type: "actions",
      headerName: "Vehiculo",
      width: 150,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<LocalShippingIcon />}
          label="Modificar Vehiculos"
          onClick={() => {
            handleClickVehicle(params.row);
          }}
        />,
      ],
    },
    {
      field: "vehiculo_compare",
      type: "actions",
      headerName: "Vehiculos a comparar",
      width: 150,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<LocalShippingIcon />}
          label="Modificar Vehiculos"
          onClick={() => {
            handleClickVehicleCompare(params.row);
          }}
          disabled={
            params.row.tipo_alerta.nombre === "Acercamiento" ? false : true
          }
        />,
      ],
    },
    {
      field: "horario",
      type: "actions",
      headerName: "Horario",
      width: 150,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EventAvailableIcon />}
          label="Modificar Horario"
          onClick={() => handleClickHours(params.row)}
        />,
      ],
    },
    {
      field: "url_sonido",
      type: "actions",
      headerName: "Audio",
      width: 150,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<VolumeUpIcon />}
          label="Modificar Audio"
          onClick={() => handleAudioProcess(params.row)}
        />,
      ],
    },
    {
      field: "contactos",
      type: "actions",
      headerName: "Contactos",
      width: 150,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<PeopleIcon />}
          label="Modificar Contactos"
          onClick={() => handleContactosProcess(params.row)}
        />,
      ],
    },
    {
      field: "nombre_riesgo",
      type: "actions",
      headerName: "Importancia",
      width: 150,
      getActions: (params) => {
        let colorStr = "";

        switch (params.row.nombre_riesgo) {
          case "Alto":
            colorStr = "#f54242";
            break;

          case "Bajo":
            colorStr = "#78db53";
            break;

          case "Medio":
            colorStr = "#dbd653";
            break;

          default:
            colorStr = "#a8a8a8";
            break;
        }

        return [
          <GridActionsCellItem
            icon={<Brightness1SharpIcon />}
            label="Modificar Audio"
            sx={{ color: `${colorStr}` }}
          />,
        ];
      },
    },
  ];

  /***** Eventos ******************************
   *********************************************/

  const resetForm = () => {
    setConfiguracion({
      //Configuracion de la vista
      nombre: "",
      tipo_alerta: {},
      geocerca: [],
      vehiculo: [],
      vehiculo_compare: [],
      contactos: [],
      riesgo: "",
      tiempo: 0,
      veces: 0,
      repetir: 1,
      velocidad_movimiento: 0,
      distancia_vehiculos: 0,
      tipo_marcado: 0,
      url_sonido: "",
      horario: [],
    });

    setAlertaId(null);
  };

  // Evento para cargar los datos de la ventana modal (Para los procesos de la tabla)
  const loadAlertaById = async (id) => {
    if (!id) {
      console.warn("No se seleccionó ningún ID para cargar la alerta.");
      return; // Si no hay un ID, no hace nada
    }
    try {
      const filtros = { id: id };
      const response = await alertaService.getAlertList(filtros);
      if (response && response.statusCode === 200) {
        setConfiguracion(response.data[0]); // Cargar los datos en el estado de configuración
        setAlertaId(id); // Establecer el ID de la alerta
      } else {
        toast.error("Error al cargar los datos del horario seleccionado");
      }
    } catch (error) {
      toast.error("Error al cargar la alerta seleccionada");
    }
  };

  // Método para guardar toda la alerta (creación o edición al api)
  const handleGuardarPrincipal = async () => {
    const jsonCompleto = {
      id: alertaId || "",
      ...configuracion, // Usamos todo el estado como base
    };

    try {
      if (alertaId) {
        // Actualizar la alerta existente
        const response = await alertaService.updateAlerta(jsonCompleto);
        if (response && response.statusCode === 200) {
          toast.success("Alerta Actualizada exitosamente");
        } else {
          toast.error(response?.message || "Error al actualizar alerta");
        }
      }
    } catch (error) {
      console.error("Error al guardar alerta:", error);
      toast.error("Error al guardar la alerta");
    }
  };

  //Evento para actualizar el componente principal
  const actualizarComponente = (clave, datos) => {
    setConfiguracion((prev) => ({
      ...prev,
      [clave]: datos,
    })); // Solo actualiza la clave correspondiente
  };

  //Evento para cerrar el modal
  const handleClose = () => {
    setOpenHour(false);
    setOpenVh(false);
    resetForm();
  };

  //Evento para cerrar el modal
  const handleCloseCompare = () => {
    setOpenHour(false);
    setOpenVhCo(false);
    resetForm();
  };

  //Evento para abir el modal
  const showHours = () => {
    //limpia el formulario
    resetForm();

    //Muestra el modal
    setOpenHour(true);
  };

  //Evento para cerrar el modal
  const showVehicles = () => {
    resetForm();
    setOpenVh(true);
  };

  //Evento para cerrar el modal
  const showVehiclesCompare = () => {
    resetForm();
    setOpenVhCo(true);
  };

  //Evento para cerrar el modal
  const showAudio = () => {
    resetForm();
    setOpenAudios(true);
  };

  //Evento para cerrar el modal
  const showContactos = () => {
    resetForm();
    setOpenContactos(true);
  };

  //Evento modificar alerta (vehiculo)
  const handleClickVehicle = (selected) => {
    //Aqui va actualizar la UI con el elemento seleccionado
    loadAlertaById(`${selected.id}`);

    //abrir modal horas
    showVehicles();
  };

  //Evento modificar alerta (comparar vehiculo)
  const handleClickVehicleCompare = (selected) => {
    //Aqui va actualizar la UI con el elemento seleccionado
    loadAlertaById(`${selected.id}`);

    //abrir modal
    showVehiclesCompare();
  };

  //Evento modificar alerta (horario)
  const handleClickHours = (selected) => {
    //Aqui va actualizar la UI con el elemento seleccionado
    loadAlertaById(`${selected.id}`);
    //abrir modal horas
    showHours();
  };

  //Actualizacion de la UI
  React.useEffect(() => {
    //Validar si el arreglo tiene un elemnto
    if (selectedRows.length === 1) {
      //obtiene el identificador para el componente
      setAlertaId(`${selectedRows[0].id}`);
    }
  }, [selectedRows]);

  // Mapea la data a modelo de vista
  const listViewModel = alertList.map((alert, Index) => {
    alert.TipoAlerta = alert.tipo_alerta.nombre;
    alert.id = alert.id || Index;
    return alert;
  });

  // // Filtrar las filas según el texto de búsqueda
  // const filteredAlerts = listViewModel.filter((alert) => {
  //   // Comprobamos que cada valor de la fila se pueda convertir a string y que contenga la búsqueda
  //   return Object.values(alert).some((value) => {
  //     // Convertir el valor a string y comprobar si incluye el texto de búsqueda
  //     const strValue = value ? value.toString().toLowerCase() : "";
  //     return strValue.includes(searchText.toLowerCase());
  //   });
  // });

  const filteredAlerts = listViewModel.filter((alert) => {
    // Filtro por texto de búsqueda
    const matchesText = Object.values(alert).some((value) => {
      const strValue = value ? value.toString().toLowerCase() : "";
      return strValue.includes(searchText.toLowerCase());
    });

    // Filtro por tipo de alerta
    const matchesAlertType =
      selectedAlertas.length === 0 ||
      selectedAlertas.includes(alert.tipo_alerta._id);

    // Filtro por vehículos
    const matchesVehicle =
      selectedVehicles.length === 0 ||
      alert.vehiculo.some((vehicleId) => selectedVehicles.includes(vehicleId));

    // Retorna true solo si cumple con todos los filtros
    return matchesText && matchesAlertType && matchesVehicle;
  });

  //geocercas
  const handleGeocercaProcess = (selected) => {
    // Carga los datos específicos de la alerta
    loadAlertaById(`${selected.id}`);
    // Abre el modal de geocercas
    setOpenGeocercas(true);
  };

  //audio
  const handleAudioProcess = (selected) => {
    // Carga los datos específicos de la alerta
    loadAlertaById(`${selected.id}`);
    // Abre el modal de audios
    showAudio();
    //setOpenAudios(true);
  };

  //Contactos
  const handleContactosProcess = (selected) => {
    loadAlertaById(`${selected.id}`);
    showContactos();
  };

  const handleSelectAllAlertas = (event) => {
    if (selectedAlertas.length === eventos.length) {
      setSelectedAlertas([]); // Deselect all
    } else {
      setSelectedAlertas(eventos.map((e) => e.id)); // Select all
    }
  };

  const handleSelectAllVehicles = (event) => {
    if (selectedVehicles.length === vehicles.length) {
      setSelectedVehicles([]); // Deselect all
    } else {
      setSelectedVehicles(vehicles.map((v) => v.id)); // Select all
    }
  };

  const handleAlertasChange = (event) => {
    const value = event.target.value;
    if (value.includes("all")) {
      if (selectedAlertas.length === eventos.length) {
        setSelectedAlertas([]); // Deselect all
      } else {
        setSelectedAlertas(eventos.map((e) => e.id)); // Select all
      }
    } else {
      setSelectedAlertas(value);
    }
  };

  const handleVehiclesChange = (event) => {
    const value = event.target.value;
    if (value.includes("all")) {
      if (selectedVehicles.length === vehicles.length) {
        setSelectedVehicles([]); // Deselect all
      } else {
        setSelectedVehicles(vehicles.map((v) => v.id)); // Select all
      }
    } else {
      setSelectedVehicles(value);
    }
  };

  //Retorna la UI del componente -----------------------------------
  return (
    <Box sx={{ width: "100%", mt: 2 }}>
      <Box
        sx={{
          display: "flex",
          gap: 2,
          mb: 2,
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
          <Button
            variant="contained"
            color="error"
            disabled={selectedRows.length !== 1}
            startIcon={<EditIcon />}
            onClick={() => handleEditarAlerta(selectedRows[0].id)}
          >
            Editar
          </Button>
          <Button
            variant="contained"
            color="error"
            disabled={selectedRows.length === 0}
            onClick={() => setShowConfirmDialog(true)}
            startIcon={<DeleteIcon />}
          >
            Eliminar
          </Button>
        </Box>

        <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
          {/* Tipo de alertas */}
          <FormControl sx={{ width: 200 }}>
            <Select
              multiple
              value={selectedAlertas}
              onChange={handleAlertasChange}
              size="small"
              displayEmpty
              sx={{
                "& .MuiSelect-select": {
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                },
              }}
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return "Tipo de alertas";
                }
                const selectedNames =
                  selected.length === eventos.length
                    ? "Todos los tipos"
                    : selected
                        .map((id) => eventos.find((e) => e.id === id)?.nombre)
                        .join(", ");
                return (
                  <Tooltip title={selectedNames}>
                    <span>{selectedNames}</span>
                  </Tooltip>
                );
              }}
            >
              <MenuItem value="all">
                <Checkbox
                  checked={selectedAlertas.length === eventos.length}
                  indeterminate={
                    selectedAlertas.length > 0 &&
                    selectedAlertas.length < eventos.length
                  }
                />
                <ListItemText primary="Seleccionar todos" />
              </MenuItem>
              {eventos.map((evento) => (
                <MenuItem key={evento.id} value={evento.id}>
                  <Checkbox checked={selectedAlertas.includes(evento.id)} />
                  <ListItemText primary={evento.nombre} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Vehículo */}
          <FormControl sx={{ width: 200 }}>
            <Select
              multiple
              value={selectedVehicles}
              onChange={handleVehiclesChange}
              size="small"
              displayEmpty
              sx={{
                "& .MuiSelect-select": {
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                },
              }}
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return "Vehículo";
                }
                const selectedNames =
                  selected.length === vehicles.length
                    ? "Todos los vehículos"
                    : selected
                        .map((id) => vehicles.find((v) => v.id === id)?.alias)
                        .join(", ");
                return (
                  <Tooltip title={selectedNames}>
                    <span>{selectedNames}</span>
                  </Tooltip>
                );
              }}
            >
              <MenuItem value="all">
                <Checkbox
                  checked={selectedVehicles.length === vehicles.length}
                  indeterminate={
                    selectedVehicles.length > 0 &&
                    selectedVehicles.length < vehicles.length
                  }
                />
                <ListItemText primary="Seleccionar todos" />
              </MenuItem>
              {vehicles.map((vehicle) => (
                <MenuItem key={vehicle.id} value={vehicle.id}>
                  <Checkbox checked={selectedVehicles.includes(vehicle.id)} />
                  <ListItemText
                    primary={`${vehicle.alias} - ${vehicle.no_Economico}`}
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Search TextField */}
          <TextField
            label="Buscar"
            variant="outlined"
            size="small"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            //sx={{ flexGrow: 1 }}
          />
        </Box>
      </Box>

      {/* <Box sx={{ width: "600px", height: "500px", overflow: "auto" }}> */}
      <Box
        ref={containerRef}
        sx={{
          width: "100%",
          maxWidth: {
            xs: "100%", // 100% width on extra-small screens
            sm: "100%", // 80% width on small screens
            md: "100%", // 600px width on medium and larger screens
          },
          height: "100%",
          display: "flex",
          flexDirection: "column",
          overflow: "auto",
          //mx: "auto",      // Center the Box horizontally
        }}
      >
        <DataGrid
          rows={filteredAlerts}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10, 25, 50]}
          checkboxSelection
          disableSelectionOnClick
          onRowSelectionModelChange={(ids) => {
            const selectedIDs = new Set(ids);
            const selectedRows = filteredAlerts.filter((row) =>
              selectedIDs.has(row.id)
            );

            setSelectedRows(selectedRows);
            onSelectionChange(selectedRows); // Notifica al padre
          }}
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          sx={{
            flexGrow: 1,
            width: "100%",
            minWidth: 300, // Ancho mínimo para legibilidad
            maxWidth: "100%", // Evita el desbordamiento
            "& .MuiDataGrid-cell:focus": {
              outline: "none", // Remove focus outline
            },
            "& .MuiDataGrid-row.Mui-selected .MuiDataGrid-cell": {
              border: "none", // Remove red border
            },
          }}
        />
      </Box>

      {/* Modal Horarios */}
      <Modal
        open={openModalHours}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style, width: 1200 }}>
          {/* Botón de cierre */}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
            }}
          >
            <CancelIcon />
          </IconButton>
          <h2 id="child-modal-title">Configuracion de horarios</h2>

          <Divider
            sx={{
              borderBottomWidth: 2,
              borderColor: "red",
              marginBottom: 2,
            }}
          />

          <Box sx={{ padding: 2 }}>
            <HorarioConfigForm
              configuracion={configuracion}
              actualizarComponente={actualizarComponente} // metodo que actualiza json principal
              handleGuardarPrincipal={handleGuardarPrincipal} // Pasamos el método para guardar todo al api
              idAlerta={alertaId} // Pasamos idAlerta
              daysSel={daysSel} //Lista de dias seleccionados
              setDaysSel={setDaysSel} //Evento para actualizar la UI
              isEditMode={true}
            />
          </Box>
        </Box>
      </Modal>

      {/* Modal Vehiculos */}
      <Modal
        open={openModalVehicle}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style, width: 1000 }}>
          {/* Botón de cierre */}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
            }}
          >
            <CancelIcon />
          </IconButton>
          <h2 id="child-modal-title">Configuracion de vehiculos</h2>

          <Divider
            sx={{
              borderBottomWidth: 2,
              borderColor: "red",
              marginBottom: 2,
            }}
          />

          <Box sx={{ padding: 2 }}>
            <ListaVehiculo
              configuracion={configuracion}
              actualizarComponente={actualizarComponente}
              handleGuardarPrincipal={handleGuardarPrincipal}
              idAlerta={alertaId}
              isEditMode={true}
            />
          </Box>
        </Box>
      </Modal>

      {/* Modal Vehiculos Comparar */}
      <Modal
        open={openModalVehicleCompare}
        onClose={handleCloseCompare}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style, width: 1000 }}>
          {/* Botón de cierre */}
          <IconButton
            aria-label="close"
            onClick={handleCloseCompare}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
            }}
          >
            <CancelIcon />
          </IconButton>
          <h2 id="child-modal-title">Configuracion de vehiculos a comparar</h2>

          <Divider
            sx={{
              borderBottomWidth: 2,
              borderColor: "red",
              marginBottom: 2,
            }}
          />

          <Box sx={{ padding: 2 }}>
            <ListaVehiculoCompare
              configuracion={configuracion}
              actualizarComponente={actualizarComponente}
              handleGuardarPrincipal={handleGuardarPrincipal}
              idAlerta={alertaId}
              isEditMode={true}
            />
          </Box>
        </Box>
      </Modal>

      {/* Modal Geocercas*/}
      <Modal
        open={openGeocercas}
        onClose={() => setOpenGeocercas(false)}
        aria-labelledby="geocerca-modal-title"
        aria-describedby="geocerca-modal-description"
      >
        <Box sx={{ ...style, width: 1000 }}>
          {/* Botón de cierre */}
          <IconButton
            aria-label="close"
            onClick={() => setOpenGeocercas(false)}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
            }}
          >
            <CancelIcon />
          </IconButton>
          <h2 id="geocerca-modal-title">Configuración de Geocercas</h2>

          <Divider
            sx={{
              borderBottomWidth: 2,
              borderColor: "red",
              marginBottom: 2,
            }}
          />

          <p id="geocerca-modal-description">
            <ListaGeocercas
              configuracion={configuracion}
              actualizarComponente={actualizarComponente}
              handleGuardarPrincipal={handleGuardarPrincipal}
              idAlerta={alertaId}
              isEditMode={true}
            />
          </p>
        </Box>
      </Modal>

      {/* Modal Audios*/}
      <Modal
        open={openAudios}
        onClose={() => setOpenAudios(false)}
        aria-labelledby="audio-modal-title"
        aria-describedby="audio-modal-description"
      >
        <Box
          sx={{
            ...style,
            width: 1000,
            height: "auto", // La altura se ajusta al contenido
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          {/* Botón de cierre */}
          <IconButton
            aria-label="close"
            onClick={() => setOpenAudios(false)}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
            }}
          >
            <CancelIcon />
          </IconButton>
          <h2 id="audio-modal-title">Configuración de Audios</h2>

          <Divider
            sx={{
              borderBottomWidth: 2,
              borderColor: "red",
              marginBottom: 2,
            }}
          />

          <p id="audio-modal-description">
            <ListaAudios
              configuracion={configuracion}
              actualizarComponente={actualizarComponente}
              handleGuardarPrincipal={handleGuardarPrincipal}
              idAlerta={alertaId}
              editMode={true}
            />
          </p>
        </Box>
      </Modal>

      {/* Modal Contactos */}
      <Modal
        key={alertaId}
        open={openContactos}
        onClose={() => setOpenContactos(false)}
        aria-labelledby="contactos-modal-title"
        aria-describedby="contactos-modal-description"
      >
        <Box
          sx={{
            ...style,
            width: 1000,
            height: "auto", // La altura se ajusta al contenido
            maxHeight: "90vh", // Limita la altura máxima al 90% de la ventana
            overflowY: "auto", // Habilita desplazamiento si el contenido es muy grande
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: 0,
          }}
        >
          {/* Botón de cierre */}
          <IconButton
            aria-label="close"
            onClick={() => setOpenContactos(false)}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
            }}
          >
            <CancelIcon />
          </IconButton>
          <h2 id="contactos-modal-title" style={{ marginBottom: "4px" }}>
            Configuracion de Contactos
          </h2>
          <p id="contactos-modal-description">
            <ModalListaContactos
              configuracion={configuracion}
              actualizarComponente={actualizarComponente}
              handleGuardarPrincipal={handleGuardarPrincipal}
              idAlerta={alertaId}
            />
          </p>
        </Box>
      </Modal>
    </Box>
  );
};

export default TableModule;
