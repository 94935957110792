// src/components/Visualization.js
import React, { useState, useEffect, useMemo } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import Table from './Table';
import Chart from './Chart';
import GroupedPanel from './GroupedPanel';
import { notificacionService } from '../../services/apiService';
import ViewSelector from './ViewSelector';
import { configurationService } from './configurationService';
import { parseISO, format } from 'date-fns';

// Function to get nested values from objects
const getNestedValue = (obj, path) => {
  return path.split('.').reduce((acc, key) => (acc ? acc[key] : undefined), obj);
};

// Function to group data
const groupData = (data, groupBy) => {
  const groupedData = {};
  data.forEach(item => {
    const groupKey = getNestedValue(item, groupBy) || 'N/A';
    if (!groupedData[groupKey]) {
      groupedData[groupKey] = { groupKey, count: 0, items: [] };
    }
    groupedData[groupKey].count += 1;
    groupedData[groupKey].items.push(item);
  });

  const total = data.length;
  const groupedDataArray = Object.values(groupedData).map(group => ({
    ...group,
    percentage: (group.count / total) * 100,
  }));

  return groupedDataArray;
};

const Visualization = ({ 
  type, 
  dataSource, 
  fields, 
  filters, 
  grouping,
  onConvertView,
  setType, 
  panelSettings,
  setPanelSettings,
  updatePanelSettings,setGroupedData, savedColors, data, 
}) => {
  //const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentConfigId, setCurrentConfigId] = useState(null);
  const [configName, setConfigName] = useState('');
  const [partialData, setPartialData] = useState(false);

  //console.log('📊 Visualization props:',)

  const availableViews = [
    { type: 'Table', label: 'Tabla' },
    { type: 'Panel', label: 'Panel' },
    { type: 'BarChart', label: 'Gráfico de Barras' },
    { type: 'PieChart', label: 'Gráfico de Pastel' },
    { type: 'DoughnutChart', label: 'Gráfico de Dona' },
  ];

  // useEffect(() => {
  //   const fetchData = async () => {
  //     setLoading(true);
  //     setError(null);
  //     setPartialData(false);
  //     try {
  //       let accumulatedData = [];
  //       let skip = 0;
  //       const limit = 500;
  //       const fieldsToRetrieve = fields; // ['Message', 'Status', 'Fecha'];
  //       const sortBy = 'Fecha';

  //       // Generate startDate and endDate for the current day
  //       const now = new Date();
  //       const startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0)
  //         .toISOString()
  //         .slice(0, 19)
  //         .replace('T', ' ');
  //       const endDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59)
  //         .toISOString()
  //         .slice(0, 19)
  //         .replace('T', ' ');

  //       let hasMore = true;

  //       while (hasMore) {
  //         try {
  //           const response = await notificacionService.getNotificacionesproductivo(
  //             startDate,
  //             endDate,
  //             limit,
  //             skip,
  //             fieldsToRetrieve,
  //             sortBy
  //           );

  //           if (response && response.data) {
  //             accumulatedData = [...accumulatedData, ...response.data];
  //             skip += limit;

  //             if (accumulatedData.length >= response.pagination.total) {
  //               hasMore = false;
  //             }
  //           } else {
  //             hasMore = false;
  //           }
  //         } catch (pageError) {
  //           console.error('Error fetching page:', pageError);
  //           setPartialData(true);
  //           hasMore = false;
  //         }
  //       }

  //       setData(accumulatedData);
  //     } catch (err) {
  //       console.error('Error fetching data:', err);
  //       setError('Failed to load data. Please try again.');
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   if (dataSource) {
  //     fetchData();
  //   }
  // }, [dataSource, fields]);

  const filteredData = useMemo(() => {

    console.log('🔍 Applying filters:', filters);
    return data;
    if (!filters || Object.keys(filters).length === 0) {
      console.log('⚠️ No filters applied, returning original data.');
      return data;
    }

    const result = data.filter(item => {
      //console.log('🔄 Filtering item:', item);
      const isMatch = Object.entries(filters).every(([field, value]) => {
        //console.log(`🔍 Checking filter for field: ${field}, value: ${value}`);
        if (!value) {
          console.log('⚠️ Filter value is empty, including item.');
          return true;
        }

        // Handle date range filters
        if (field.endsWith('_start') || field.endsWith('_end')) {
          const baseField = field.replace('_start', '').replace('_end', '');
          const itemDateString = getNestedValue(item, baseField);
          if (!itemDateString) {
            //console.log('❌ Item date string is empty, excluding item.');
            return false;
          }

          const itemDate = parseISO(itemDateString);
          const filterDate = value instanceof Date ? value : parseISO(value);
          //console.log(`📅 Comparing dates: itemDate = ${itemDate}, filterDate = ${filterDate}`);

          if (field.endsWith('_start')) {
            const result = itemDate >= filterDate;
            //console.log(`📅 Start date comparison result: ${result}`);
            return result;
          }
          if (field.endsWith('_end')) {
            const result = itemDate <= filterDate;
            //console.log(`📅 End date comparison result: ${result}`);
            return result;
          }
        }

        // Handle string and other types of filters
        const itemValue = getNestedValue(item, field);
        if (itemValue === undefined || itemValue === null) {
          //console.log('❌ Item value is undefined or null, excluding item.');
          return false;
        }
        const result = itemValue.toString().toLowerCase().includes(value.toString().toLowerCase());
        //console.log(`🔤 String comparison result: ${result}`);
        return result;
      });

      //console.log(`🔄 Item match result: ${isMatch}`);
      return isMatch;
    });

    //console.log('✅ Filtered data:', result);
    return result;
  }, [data, filters]);
 

  const groupedData = useMemo(() => {
    if (!grouping.groupBy) return [];
    //console.log('Grouping data by:', grouping.groupBy);
    let grouped = groupData(filteredData, grouping.groupBy);
    //console.log('Grouped data:', grouped);

    // Apply savedColors if available
    if (savedColors && Object.keys(savedColors).length > 0) {
      //console.log('Applying saved colors:', savedColors);
      grouped = grouped.map(group => ({
        ...group,
        color: savedColors[group.groupKey] || group.color
      }));
    }
    //console.log('Final grouped data with colors:', grouped);
    return grouped;
  }, [filteredData, grouping.groupBy, savedColors]);


  const viewsToShow = grouping.groupBy
  ? availableViews
  : [{ type: 'Table', label: 'Tabla' }];


  const handleSaveConfig = async (chartSettings) => {
    try {
      const configData = {
        name: chartSettings.name,
        dataSource,
        selectedFields: fields,
        filters,
        grouping: {
          ...grouping,
          chartSettings: {
            showChart: chartSettings.chartSettings.showChart,
            chartType: chartSettings.chartSettings.chartType
          }
        },
        visualization: {
          type
        }
      };

      if (currentConfigId) {
        await configurationService.updateConfig(currentConfigId, configData);
      } else {
        const newConfig = await configurationService.saveConfig(configData);
        setCurrentConfigId(newConfig.id);
        setConfigName(newConfig.name);
      }
    } catch (error) {
      console.error('Error saving configuration:', error);
    }
  };


  // Determinar el contenido a mostrar
  let content;
  if (type === 'Table') {
    content = (
      <Table
        data={filteredData}
        fields={fields}
        grouping={grouping}
        onConvertView={onConvertView}

      />
    );
  } else if (type === 'Panel') {
    content = (
      <GroupedPanel
        groupedData={groupedData}
        groupBy={grouping.groupBy}
        onSaveConfig={handleSaveConfig}
        currentConfigId={currentConfigId}
        configName={configName}
        savedSettings={grouping.chartSettings} 

        panelSettings={panelSettings}
          setPanelSettings={setPanelSettings}
          onConvertView={onConvertView}
          updatePanelSettings={updatePanelSettings}
          setGroupedData={setGroupedData}
          fields = {fields}
          dataSource = {dataSource}
      />
    );
  } else if (['BarChart', 'PieChart', 'DoughnutChart'].includes(type)) {
    content = (
      <Chart
        type={type}
        data={groupedData}
        fields={['groupKey', 'count']}
        fromPanel={panelSettings.fromPanel}
      />
    );
  } else {
    content = (
      <Typography>
        Selecciona una vista válida.
      </Typography>
    );
  }


  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      style={{ marginTop: '-16px' }} 
    >
      <Box 
        sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center', 
          mb: 1
        }}
      >

        {process.env.REACT_APP_SUPERUSERACTIVATE === 'true' && (
        <>
          <Typography variant="h6">{type}</Typography> 
          {viewsToShow.length > 1 && (
            <ViewSelector
              currentView={type}
              onChangeView={setType}
              availableViews={viewsToShow}
            />
          )}  
        </>
        )}
      </Box>
    

      {content}
    </motion.div>
  );

};

export default Visualization;