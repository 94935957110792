// src/components/Navbar.js
import React, { useContext } from 'react';
import { AppBar, Toolbar, Typography, IconButton, Box, useTheme, Avatar,Tooltip } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import { motion } from 'framer-motion';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { useTheme as useCustomTheme } from '../contexts/ThemeContext';
import Logo from '../assets/logo_login.png';

function Navbar({ onMenuClick, onMinimizeClick, isSidebarMinimized }) {
  const navigate = useNavigate();
  const { darkMode, toggleDarkMode } = useCustomTheme();
  const { autenticado, logout, userName } = useContext(AuthContext);
  const theme = useTheme();

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  if (!autenticado) return null;
  return (
    <motion.div
      initial={{ y: -100 }}
      animate={{ y: 0 }}
      transition={{ type: "spring", stiffness: 120 }}
    >
      <AppBar 
        position="fixed" 
        sx={{ 
          background: darkMode
            ? 'linear-gradient(45deg, #b71c1c 30%, #ea0000 90%)'
            : 'linear-gradient(45deg, #ea0000 70%, #b71c1c 90%)',
          boxShadow: darkMode
            ? '0 3px 15px rgba(183, 28, 28, 0.3)'
            : '0 3px 15px rgba(234, 0, 0, 0.3)',
        }}
      >
        <Toolbar>

          {/* <IconButton
            color="inherit"
            edge="start"
            onClick={onMinimizeClick}
            sx={{ 
              mr: 2,
              '&:hover': {
                bgcolor: 'rgba(255, 255, 255, 0.1)'
              }
            }}
          >
            <MenuIcon />
          </IconButton>        */}
          <Tooltip title="Minimizar menú">
            <IconButton
              color="inherit"
              edge="start"
              onClick={onMinimizeClick}
              sx={{ 
                mr: 2,
                '&:hover': {
                  bgcolor: 'rgba(255, 255, 255, 0.1)'
                }
              }}
            >
              <MenuIcon />
            </IconButton>
          </Tooltip>

          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Box
              component="img"
              src={Logo}
              alt="Logo de Omisiones"
              sx={{
                height: 40,
                mr: 1,
                filter: 'brightness(0) invert(1)',
              }}
            />
            <Typography 
              variant="h6" 
              sx={{ 
                fontWeight: 600,
                background: 'linear-gradient(45deg, #ffffff 30%, #f5f5f5 90%)',
                backgroundClip: 'text',
                textFillColor: 'transparent',
                flexGrow: 1,
              }}
            >
              OMISIONES
            </Typography>
          </motion.div>

          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, ml: 'auto' }}>
            <motion.div
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.3 }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Avatar sx={{ 
                  bgcolor: darkMode ? '#ea0000' : '#b71c1c',
                  width: 35, 
                  height: 35,
                }}>
                  <PersonIcon />
                </Avatar>
                <Typography 
                  variant="body1" 
                  sx={{ 
                    color: 'white',
                    textShadow: '0 1px 2px rgba(0,0,0,0.3)',
                    fontWeight: 500
                  }}
                >
                  {userName || 'Usuario'}
                </Typography>
              </Box>
            </motion.div>

            <motion.div
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
            >
              {/* <IconButton 
                color="inherit" 
                onClick={toggleDarkMode}
                sx={{
                  '&:hover': {
                    bgcolor: 'rgba(255, 255, 255, 0.1)'
                  }
                }}
              >
                {darkMode ? <Brightness7Icon /> : <Brightness4Icon />}
              </IconButton> */}
              <Tooltip title={darkMode ? "Modo claro" : "Modo oscuro"}>
                <IconButton 
                  color="inherit" 
                  onClick={toggleDarkMode}
                  sx={{
                    '&:hover': {
                      bgcolor: 'rgba(255, 255, 255, 0.1)'
                    }
                  }}
                >
                  {darkMode ? <Brightness7Icon /> : <Brightness4Icon />}
                </IconButton>
              </Tooltip>
            </motion.div>

            <motion.div
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
            >
              <Tooltip title="Cerrar sesión">
                <IconButton 
                  color="inherit" 
                  onClick={handleLogout}
                  sx={{
                    '&:hover': {
                      bgcolor: 'rgba(255, 255, 255, 0.1)'
                    }
                  }}
                >
                  <LogoutIcon />
                </IconButton>
              </Tooltip>
            </motion.div>
          </Box>
        </Toolbar>
      </AppBar>
    </motion.div>
  );
}

export default Navbar;

// // src/components/Navbar.js
// import React, { useContext } from 'react';
// import { AppBar, Toolbar, Typography, IconButton, Box, useTheme, Avatar } from '@mui/material';
// import MenuIcon from '@mui/icons-material/Menu';
// import LogoutIcon from '@mui/icons-material/Logout';
// import PersonIcon from '@mui/icons-material/Person';
// import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
// import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// import { useNavigate } from 'react-router-dom';
// import { AuthContext } from '../contexts/AuthContext';
// import { motion } from 'framer-motion';
// import Brightness4Icon from '@mui/icons-material/Brightness4';
// import Brightness7Icon from '@mui/icons-material/Brightness7';
// import { useTheme as useCustomTheme } from '../contexts/ThemeContext';
// import Logo from '../assets/logo_login.png';

// function Navbar({ onMenuClick, onMinimizeClick, isSidebarMinimized }) {
//   const navigate = useNavigate();
//   const { darkMode, toggleDarkMode } = useCustomTheme();
//   const { autenticado, logout, userName } = useContext(AuthContext);
//   const theme = useTheme();

//   console.log('isSidebarMinimized:', isSidebarMinimized);

//   const handleLogout = () => {
//     logout();
//     navigate('/login');
//   };

//   if (!autenticado) return null;
//   return (
//     <motion.div
//       initial={{ y: -100 }}
//       animate={{ y: 0 }}
//       transition={{ type: "spring", stiffness: 120 }}
//     >
//       <AppBar 
//         position="sticky" 
//         sx={{ 
//           background: darkMode
//             ? 'linear-gradient(45deg, #b71c1c 30%, #ea0000 90%)'
//             : 'linear-gradient(45deg, #ea0000 70%, #b71c1c 90%)',
//           boxShadow: darkMode
//             ? '0 3px 15px rgba(183, 28, 28, 0.3)'
//             : '0 3px 15px rgba(234, 0, 0, 0.3)',
//         }}
//       >
//         <Toolbar>

//           <IconButton
//             color="inherit"
//             edge="start"
//             onClick={onMinimizeClick}
//             sx={{ 
//               mr: 2,
//               '&:hover': {
//                 bgcolor: 'rgba(255, 255, 255, 0.1)'
//               }
//             }}
//           >
//             <MenuIcon />
//           </IconButton>       

//           <motion.div
//             initial={{ opacity: 0, x: -20 }}
//             animate={{ opacity: 1, x: 0 }}
//             transition={{ duration: 0.5 }}
//             style={{ display: 'flex', alignItems: 'center' }}
//           >
//             <Box
//               component="img"
//               src={Logo}
//               alt="Logo de Omisiones"
//               sx={{
//                 height: 40,
//                 mr: 1,
//                 filter: 'brightness(0) invert(1)',
//               }}
//             />
//             <Typography 
//               variant="h6" 
//               sx={{ 
//                 fontWeight: 600,
//                 background: 'linear-gradient(45deg, #ffffff 30%, #f5f5f5 90%)',
//                 backgroundClip: 'text',
//                 textFillColor: 'transparent',
//                 flexGrow: 1,
//               }}
//             >
//               OMISIONES
//             </Typography>
//           </motion.div>

//           <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, ml: 'auto' }}>
//             <motion.div
//               initial={{ opacity: 0, scale: 0.5 }}
//               animate={{ opacity: 1, scale: 1 }}
//               transition={{ duration: 0.3 }}
//             >
//               <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
//                 <Avatar sx={{ 
//                   bgcolor: darkMode ? '#ea0000' : '#b71c1c',
//                   width: 35, 
//                   height: 35,
//                 }}>
//                   <PersonIcon />
//                 </Avatar>
//                 <Typography 
//                   variant="body1" 
//                   sx={{ 
//                     color: 'white',
//                     textShadow: '0 1px 2px rgba(0,0,0,0.3)',
//                     fontWeight: 500
//                   }}
//                 >
//                   {userName || 'Usuario'}
//                 </Typography>
//               </Box>
//             </motion.div>

//             <motion.div
//               whileHover={{ scale: 1.1 }}
//               whileTap={{ scale: 0.95 }}
//             >
//               <IconButton 
//                 color="inherit" 
//                 onClick={toggleDarkMode}
//                 sx={{
//                   '&:hover': {
//                     bgcolor: 'rgba(255, 255, 255, 0.1)'
//                   }
//                 }}
//               >
//                 {darkMode ? <Brightness7Icon /> : <Brightness4Icon />}
//               </IconButton>
//             </motion.div>

//             <motion.div
//               whileHover={{ scale: 1.1 }}
//               whileTap={{ scale: 0.95 }}
//             >
//               <IconButton 
//                 color="inherit" 
//                 onClick={handleLogout}
//                 sx={{
//                   '&:hover': {
//                     bgcolor: 'rgba(255, 255, 255, 0.1)'
//                   }
//                 }}
//               >
//                 <LogoutIcon />
//               </IconButton>
//             </motion.div>
//           </Box>
//         </Toolbar>
//       </AppBar>
//     </motion.div>
//   );
// }

// export default Navbar;