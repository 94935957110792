import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  Grid,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Tooltip,
  Divider,
  Dialog, // Para los modales
  DialogTitle, // Título del modal
  DialogContent, // Contenido principal del modal
  DialogContentText, // Texto dentro del modal
  DialogActions, // Botones de acción del modal
  InputAdornment,
} from "@mui/material";
import {
  eventoService,
  RiesgoService,
  ClasificacionEventoService,
} from "../../../services/apiService";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
import ErrorIcon from "@mui/icons-material/Error";
import ListaAudios from "./AlertaSonidoForm";

const EVENTOS_TIEMPO = [
  "6091ba29662e2b2e74500a40",
  "6091ba1d662e2b2e74500a3d",
  "6091ba3e662e2b2e74500a43",
  "6189b3ea2a2e016f281d2a05",
];
const EVENTOS_TIPO_MARCADO = [
  "6091ba3e662e2b2e74500a43",
  "6189b3ea2a2e016f281d2a05",
  "6091ba52662e2b2e74500a46",
];
const EVENTOS_VECES_VELOCIDAD = ["6091ba52662e2b2e74500a46"];
const EVENTOS_DISTANCIA = ["6189b3ea2a2e016f281d2a05"];

const NuevaAlertaForm = ({
  idAlerta,
  configuracion,
  setConfiguracion,
  handleGuardarPrincipal,
  resetForm,
  actualizarComponente,
  viewNew, // condicion para mostrar el boton nuevo o no
  nextComponent,
}) => {
  const [eventos, setEventos] = useState([]);
  const [riesgos, setRiesgos] = useState([]);
  const [errorNombre, setErrorNombre] = useState(""); // Estado para manejar errores en "Nombre"
  const [errorTiempo, setErrorTiempo] = useState(""); // Estado para manejar errores en "Tiempo"
  const [errorVeces, setErrorVeces] = useState(""); // Estado para manejar errores en "Veces"
  const [errorVelocidad, setErrorVelocidad] = useState(""); //Estado para manejar errores en "velocidad_movimiento"
  const [errorDistancia, setErrorDistancia] = useState(""); // Estado para manejar errores en "distancia_vehiculos"

  // Estado para el modal de cancelar
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);

  // Abrir y cerrar modal de cancelar
  const handleOpenCancelDialog = () => setCancelDialogOpen(true);
  const handleCloseCancelDialog = () => setCancelDialogOpen(false);
  // Estado para controlar la apertura del modal de guardar
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);

  const handleOpenSaveDialog = () => setSaveDialogOpen(true);
  const handleCloseSaveDialog = () => setSaveDialogOpen(false);
  const [clasificaciones, setClasificaciones] = useState([]);
  const [asignaciones, setAsignaciones] = useState({});
  const [clasificacionSeleccionada, setClasificacionSeleccionada] =
    useState(null); // Clasificación seleccionada
  const [eventosFiltrados, setEventosFiltrados] = useState([]); // Eventos filtrados según la clasificación

  const loadEventos = async () => {
    try {
      const response = await eventoService.getEventos();
      if (response && response.statusCode === 200) {
        setEventos(response.data);
        console.log(response.data);
      }
    } catch (error) {
      console.error("Error cargando eventos:", error);
    }
  };

  const loadRiesgos = async () => {
    try {
      const response = await RiesgoService.getRiesgos();
      if (response && response.statusCode === 200) {
        setRiesgos(response.data);
      }
    } catch (error) {
      console.error("Error cargando riesgos:", error);
    }
  };

  const loadClasificaciones = async () => {
    // Trae todos las clasifiaciones activas, si le pasas uid_clasificacion trae la asignacion de eventos de una clasificacion
    try {
      const response =
        await ClasificacionEventoService.getClasificacionesActivas();
      if (response && response.statusCode === 200) {
        setClasificaciones(response.data);
      }
    } catch (error) {
      console.error("Error cargando eventos:", error);
    }
  };

  const loadAsignaciones = async () => {
    //Se obtienen todas las asignaciones
    try {
      const response = await ClasificacionEventoService.FiltrarAsignaciones();
      if (response && response.statusCode === 200) {
        setAsignaciones(response.data);
      }
    } catch (error) {
      console.error("Error cargando asignaciones:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Validación específica para el campo "Nombre"
    if (name === "nombre") {
      if (value.length > 50) {
        setErrorNombre("El nombre no puede tener más de 50 caracteres.");
      } else {
        setErrorNombre(""); // Resetea el error si la longitud es válida
      }
    }

    if (name === "tiempo") {
      const numericValue = parseInt(value, 10);
      if (value.length > 3) return;
      if (isNaN(numericValue) || numericValue < 0 || numericValue > 999) {
        setErrorTiempo("El tiempo tiene que tener un valor entre 0 y 999");
      } else {
        setErrorTiempo("");
      }
    }

    if (name === "veces") {
      const numericValue = parseInt(value, 10);
      if (value.length > 3) return;

      if (isNaN(numericValue) || numericValue < 0 || numericValue > 999) {
        setErrorVeces(
          "La cantidad de veces tiene que tener un valor entre 0 y 999"
        );
      } else {
        setErrorVeces("");
      }
    }

    if (name === "velocidad_movimiento") {
      const numericValue = parseInt(value, 10);
      if (value.length > 3) return;

      if (isNaN(numericValue) || numericValue < 0 || numericValue > 999) {
        setErrorVelocidad(
          "La velocidad tiene que tener un valor entre 0 y 999"
        );
      } else {
        setErrorVelocidad("");
      }
    }

    if (name === "distancia_vehiculos") {
      if (value.length > 3) return;

      const numericValue = parseInt(value, 10);
      if (isNaN(numericValue) || numericValue < 0 || numericValue > 999) {
        setErrorDistancia(
          "La distancia tiene que tener un valor entre 0 y 999"
        );
      } else {
        setErrorDistancia("");
      }
    }

    setConfiguracion((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Manejar selección de tipo de alerta
  const handleSelectTipoAlerta = (eventoId) => {
    const eventoSeleccionado = eventos.find((evento) => evento.id === eventoId);

    let tipoMarcado = 0; // Valor por defecto para tipo_marcado

    // Asignar tipo_marcado basado en el tipo elegido
    if (eventoId === "6091ba1d662e2b2e74500a3d") {
      //Entrada
      tipoMarcado = 1; // Dentro
    } else if (eventoId === "6091ba29662e2b2e74500a40") {
      //Salida
      tipoMarcado = 2; // Fuera
    }

    setConfiguracion((prev) => ({
      ...prev,
      tipo_alerta: {
        _id: eventoSeleccionado.id,
        nombre: eventoSeleccionado.nombre,
        evento: eventoSeleccionado.eventos || [],
      },
      tipo_marcado: tipoMarcado, // Establecer tipo_marcado automáticamente
    }));
  };

  useEffect(() => {
    loadClasificaciones();
    loadEventos();
    loadRiesgos();
    loadAsignaciones();
  }, []);

  // useEffect(() => {
  //   const cargarAsignacionesEdicion = async () => {
  //     if (configuracion.tipo_alerta._id) { // Si hay un tipo de alerta configurado
  //       console.log("evento guardado en base dd",configuracion.tipo_alerta._id);
  //       try {
  //         // Cargar todas las asignaciones
  //         const response = await ClasificacionEventoService.FiltrarAsignaciones({});
  //         if (response && response.statusCode === 200) {
  //           // Buscar la asignación que contiene el tipo de alerta actual
  //           const asignacion = response.data.find((a) =>
  //             a.eventos.includes(configuracion.tipo_alerta._id)
  //           );
  //           if (asignacion) {

  //             // Buscar la clasificación asociada a la asignación encontrada
  //             const clasificacion = clasificaciones.find(
  //               (c) => c.id === asignacion.uid_clasificacion
  //             );
  //             console.log("clasificacion",clasificacion);
  //             console.log("eventos",asignacion.eventos);

  //             // Actualizar clasificación seleccionada y eventos filtrados
  //             setClasificacionSeleccionada(clasificacion.id || null); // Seleccionar clasificación asociada
  //             setEventosFiltrados(asignacion.eventos || []); // Filtrar los eventos correspondientes
  //           } else {
  //             console.warn("No se encontró una asignación para el tipo de alerta actual.");
  //             setClasificacionSeleccionada(null); // No hay clasificación asociada
  //             setEventosFiltrados([]); // Limpiar eventos filtrados
  //           }
  //         }
  //       } catch (error) {
  //         console.error("Error al cargar asignaciones en edición:", error);
  //         setClasificacionSeleccionada(null); // Asegurar que se limpie en caso de error
  //         setEventosFiltrados([]); // Limpiar eventos filtrados
  //       }
  //     }
  //   };

  //   cargarAsignacionesEdicion();
  // }, [configuracion.tipo_alerta._id, clasificaciones]); // Dependencias
  const isFormEmpty =
    errorNombre ||
    errorTiempo ||
    errorVeces ||
    errorVelocidad ||
    errorDistancia;
  return (
    <Box
      sx={{
        borderRadius: 2,
        boxShadow: 1,
        // backgroundColor: "white",
        my: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          p: 2,
        }}
      >
        {/**Titulo */}
        <Typography
          sx={{
            flexGrow: 1,
          }}
        >
          Nueva Alerta
        </Typography>

        {/** Botón NUEVO */}
        {viewNew && ( // ✅ Ajuste: Mostrar el botón si viewNew es true
          <Button
            variant="contained"
            color="primary"
            onClick={resetForm}
            startIcon={<AddIcon />}
            sx={{ width: "150px", padding: 2 }}
          >
            Nuevo
          </Button>
        )}
      </Box>

      <Box display="flex" alignItems="flex-start">
        <Box width="50%">
          <Grid container spacing={2} p={2}>
            <Grid item xs={12} md={6}>
              <FormControl variant="outlined" size="small" fullWidth>
                <TextField
                  label="Nombre"
                  name="nombre"
                  placeholder="Escribir nombre"
                  variant="outlined"
                  size="small"
                  fullWidth
                  defaultValue={"Nombre"}
                  value={configuracion.nombre}
                  onChange={handleChange}
                  error={!!errorNombre} // Muestra un error si hay texto en errorNombre
                  helperText={errorNombre} // Texto de ayuda bajo el campo
                  slotProps={{
                    input: {
                      startAdornment: (
                        <InputAdornment position="start">
                          <Tooltip
                            title="Ingresa el nombre de la alerta"
                            placement="top"
                          >
                            <ErrorIcon fontSize="small" />
                          </Tooltip>
                        </InputAdornment>
                      ),
                    },
                  }}
                  // InputProps={{
                  //   displayEmpty: true,
                  //   renderValue: (e) => {
                  //     if (!e.target.value) {
                  //       return "Ingresar nombre";
                  //     }
                  //   },
                  // }}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl variant="outlined" size="small" fullWidth>
                <TextField
                  select
                  label="Riesgo"
                  name="riesgo" // Clave en el estado
                  variant="outlined"
                  fullWidth
                  size="small"
                  value={configuracion.riesgo}
                  onChange={handleChange}
                  renderValue={(selected) => {
                    const riesgo = riesgos.find((ries) => ries.id === selected);
                    return (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        {/* Circulito con color dinámico */}
                        <Box
                          sx={{
                            width: 12,
                            height: 12,
                            borderRadius: "50%",
                            backgroundColor:
                              riesgo?.nombre === "Alto"
                                ? "#f54242"
                                : riesgo?.nombre === "Bajo"
                                ? "#78db53"
                                : riesgo?.nombre === "Medio"
                                ? "#dbd653"
                                : "#a8a8a8", // Color predeterminado
                          }}
                        />
                        {riesgo?.nombre || "Selecciona un riesgo"}
                      </Box>
                    );
                  }}
                  slotProps={{
                    input: {
                      startAdornment: (
                        <InputAdornment position="start">
                          <Tooltip
                            title="Selecciona el nivel de riesgo de la alerta"
                            placement="top"
                          >
                            <ErrorIcon fontSize="small" />
                          </Tooltip>
                        </InputAdornment>
                      ),
                    },
                  }}
                  SelectProps={{
                    displayEmpty: true,
                    renderValue: (selected) => {
                      if (!selected) {
                        return "Seleccionar un riesgo";
                      }
                      return riesgos.find((riesgo) => riesgo.id === selected)
                        ?.nombre;
                    },
                  }}
                >
                  {riesgos
                    .sort((a, b) => {
                      const prioridad = { Alto: 1, Medio: 2, Bajo: 3 };
                      return prioridad[a.nombre] - prioridad[b.nombre];
                    })
                    .map((riesgo) => (
                      <MenuItem key={riesgo.id} value={riesgo.id}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                          }}
                        >
                          <Box
                            sx={{
                              width: 12,
                              height: 12,
                              borderRadius: "50%",
                              backgroundColor:
                                riesgo.nombre === "Alto"
                                  ? "#f54242"
                                  : riesgo.nombre === "Bajo"
                                  ? "#78db53"
                                  : riesgo.nombre === "Medio"
                                  ? "#dbd653"
                                  : "#a8a8a8", // Color predeterminado
                            }}
                          />
                          {riesgo.nombre}
                        </Box>
                      </MenuItem>
                    ))}
                </TextField>
              </FormControl>
            </Grid>

            {viewNew && (
              <Grid item xs={12} md={3}>
                <ListaAudios
                  configuracion={configuracion}
                  actualizarComponente={actualizarComponente}
                  handleGuardarPrincipal={handleGuardarPrincipal}
                  idAlerta={idAlerta}
                  editMode={false}
                />
              </Grid>
            )}
          </Grid>
        </Box>
        <Divider
          orientation="vertical"
          flexItem
          sx={{
            bgcolor: "red",
            width: "2px",
          }}
        />
        <Box width="50%">
          <Grid container spacing={2} p={2}>
            <Grid item xs={12} md={3}>
              <FormControl variant="outlined" size="small" fullWidth>
                <TextField
                  select
                  label="Clasificaciones"
                  value={clasificacionSeleccionada?.id || ""}
                  onChange={async (event) => {
                    const selectedId = event.target.value;
                    const selectedClasificacion = clasificaciones.find(
                      (c) => c.id === selectedId
                    );

                    setClasificacionSeleccionada(selectedClasificacion);

                    if (selectedId) {
                      try {
                        const response =
                          await ClasificacionEventoService.FiltrarAsignaciones(
                            selectedId
                          );
                        if (response && response.statusCode === 200) {
                          const asignacionFiltrada = response.data.find(
                            (asignacion) =>
                              asignacion.uid_clasificacion === selectedId
                          );

                          const eventosFiltrados =
                            asignacionFiltrada?.eventos || [];
                          setEventosFiltrados(eventosFiltrados);
                        }
                      } catch (error) {
                        console.error("Error al cargar asignaciones:", error);
                        setEventosFiltrados([]);
                      }
                    } else {
                      setEventosFiltrados([]);
                    }
                  }}
                  slotProps={{
                    input: {
                      startAdornment: (
                        <InputAdornment position="start">
                          <Tooltip title="Seleccione una clasificación para desplegar los tipos de alertas.">
                            <ErrorIcon fontSize="small" />
                          </Tooltip>
                        </InputAdornment>
                      ),
                    },
                  }}
                  SelectProps={{
                    displayEmpty: true,
                    renderValue: (selected) => {
                      if (!selected) {
                        return "Seleccione una clasificación";
                      }
                      return clasificaciones.find((c) => c.id === selected)
                        ?.nombre;
                    },
                  }}
                  size="small"
                  fullWidth
                  variant="outlined"
                >
                  {clasificaciones.map((clasificacion) => (
                    <MenuItem key={clasificacion.id} value={clasificacion.id}>
                      {clasificacion.nombre}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl variant="outlined" size="small" fullWidth>
                <TextField
                  select
                  label="Tipo"
                  variant="outlined"
                  fullWidth
                  size="small"
                  value={configuracion.tipo_alerta._id || ""}
                  onChange={(e) => handleSelectTipoAlerta(e.target.value)} // Construye tipo_alerta
                  slotProps={{
                    input: {
                      startAdornment: (
                        <InputAdornment position="start">
                          <Tooltip
                            title="Selecciona un evento para la alerta"
                            placement="top"
                          >
                            <ErrorIcon fontSize="small" />
                          </Tooltip>
                        </InputAdornment>
                      ),
                    },
                  }}
                  SelectProps={{
                    displayEmpty: true,
                    renderValue: (selected) => {
                      if (!selected) {
                        return "Seleccionar un tipo";
                      }
                      return eventos.find((evento) => evento.id === selected)
                        ?.nombre;
                    },
                  }}
                >
                  {eventos
                    .filter((evento) => {
                      // Si hay una clasificación seleccionada, solo mostramos los eventos asignados a ella
                      if (
                        clasificacionSeleccionada &&
                        eventosFiltrados.length > 0
                      ) {
                        return eventosFiltrados.includes(evento.id);
                      }
                      // En modo edición, incluimos el evento guardado
                      if (configuracion.tipo_alerta._id) {
                        return evento.id === configuracion.tipo_alerta._id;
                      }
                      return false; // Si no hay filtro, no mostramos Nada
                    })
                    .map((evento) => (
                      <MenuItem key={evento.id} value={evento.id}>
                        {evento.nombre}
                      </MenuItem>
                    ))}
                </TextField>
              </FormControl>
            </Grid>

            {configuracion.tipo_alerta._id && (
              <>
                {EVENTOS_TIEMPO.includes(configuracion.tipo_alerta._id) && (
                  <Grid item xs={12} md={3}>
                    <TextField
                      label="Tiempo (Min.)"
                      name="tiempo"
                      variant="outlined"
                      size="small"
                      fullWidth
                      error={!!errorTiempo} // Muestra un error si hay texto en errorNombre
                      helperText={errorTiempo} // Texto de ayuda bajo el campo
                      value={configuracion.tiempo}
                      onChange={handleChange}
                      slotProps={{
                        input: {
                          startAdornment: (
                            <InputAdornment position="start">
                              <Tooltip
                                title="Tiempo que debe transcurrir para que se genere la alerta."
                                placement="top"
                              >
                                <ErrorIcon fontSize="small" />
                              </Tooltip>
                            </InputAdornment>
                          ),
                        },
                      }}
                    />
                  </Grid>
                )}
                {EVENTOS_TIPO_MARCADO.includes(
                  configuracion.tipo_alerta._id
                ) && (
                  <Grid item xs={12} md={3}>
                    <FormControl variant="outlined" size="small" fullWidth>
                      <InputLabel>Tipo Marcado</InputLabel>
                      <Select
                        label="Tipo Marcado"
                        name="tipo_marcado" // Clave en el estado
                        value={configuracion.tipo_marcado}
                        onChange={handleChange}
                        startAdornment={
                          <InputAdornment position="start">
                            <Tooltip
                              title="Selecciona un evento para la alerta"
                              placement="top"
                            >
                              <ErrorIcon fontSize="small" />
                            </Tooltip>
                          </InputAdornment>
                        }
                      >
                        <MenuItem value={0}>General</MenuItem>
                        <MenuItem value={1}>Dentro</MenuItem>
                        <MenuItem value={2}>Fuera</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                {EVENTOS_VECES_VELOCIDAD.includes(
                  configuracion.tipo_alerta._id
                ) && (
                  <>
                    <Grid item xs={12} md={3}>
                      <TextField
                        label="Veces"
                        name="veces"
                        variant="outlined"
                        size="small"
                        fullWidth
                        error={!!errorVeces} // Muestra un error si hay texto en errorNombre
                        helperText={errorVeces} // Texto de ayuda bajo el campo
                        value={configuracion.veces}
                        onChange={handleChange}
                        slotProps={{
                          input: {
                            startAdornment: (
                              <InputAdornment position="start">
                                <Tooltip
                                  title="Cantidad de veces que tiene que pasar para que se genere la alerta."
                                  placement="top"
                                >
                                  <ErrorIcon fontSize="small" />
                                </Tooltip>
                              </InputAdornment>
                            ),
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        label="Velocidad (Km/h)"
                        name="velocidad_movimiento"
                        value={configuracion.velocidad_movimiento}
                        onChange={handleChange}
                        size="small"
                        fullWidth
                        error={!!errorVelocidad} // Muestra un error si hay texto en errorNombre
                        helperText={errorVelocidad} // Texto de ayuda bajo el campo
                        slotProps={{
                          input: {
                            startAdornment: (
                              <InputAdornment position="start">
                                <Tooltip
                                  title="Velocidad que no debe ser sobrepasada."
                                  placement="top"
                                >
                                  <ErrorIcon fontSize="small" />
                                </Tooltip>
                              </InputAdornment>
                            ),
                          },
                        }}
                      />
                    </Grid>
                  </>
                )}
                {EVENTOS_DISTANCIA.includes(configuracion.tipo_alerta._id) && (
                  <Grid item xs={12} md={3}>
                    <TextField
                      label="Distancia (Metros)"
                      name="distancia_vehiculos"
                      variant="outlined"
                      value={configuracion.distancia_vehiculos}
                      onChange={handleChange}
                      size="small"
                      fullWidth
                      error={!!errorDistancia} // Muestra un error si hay texto en errorNombre
                      helperText={errorDistancia} // Texto de ayuda bajo el campo
                      slotProps={{
                        input: {
                          startAdornment: (
                            <InputAdornment position="start">
                              <Tooltip
                                title="Distancia entre los vehiculos."
                                placement="top"
                              >
                                <ErrorIcon fontSize="small" />
                              </Tooltip>
                            </InputAdornment>
                          ),
                        },
                      }}
                    />
                  </Grid>
                )}

                {/**
                 * ✅ Mostrar los campos `veces`, `tiempo` y `tipo marcado`
                 * si el `id` no está en ninguno de los grupos EVENTOS_TIEMPO, EVENTOS_TIPO_MARCADO,
                 * EVENTOS_VECES_VELOCIDAD o EVENTOS_DISTANCIA.
                 */}
                {!(
                  EVENTOS_TIEMPO.includes(configuracion.tipo_alerta._id) ||
                  EVENTOS_TIPO_MARCADO.includes(
                    configuracion.tipo_alerta._id
                  ) ||
                  EVENTOS_VECES_VELOCIDAD.includes(
                    configuracion.tipo_alerta._id
                  ) ||
                  EVENTOS_DISTANCIA.includes(configuracion.tipo_alerta._id)
                ) && (
                  <>
                    <Grid item xs={12} md={3}>
                      <TextField
                        label="Veces"
                        name="veces"
                        variant="outlined"
                        value={configuracion.veces}
                        onChange={handleChange}
                        size="small"
                        fullWidth
                        error={!!errorVeces} // Muestra un error si hay texto en errorNombre
                        helperText={errorVeces} // Texto de ayuda bajo el campo
                        slotProps={{
                          input: {
                            startAdornment: (
                              <InputAdornment position="start">
                                <Tooltip
                                  title="Cantidad de veces que tiene que pasar para que se genere la alerta."
                                  placement="top"
                                >
                                  <ErrorIcon fontSize="small" />
                                </Tooltip>
                              </InputAdornment>
                            ),
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        label="Tiempo (Min.)"
                        name="tiempo"
                        variant="outlined"
                        value={configuracion.tiempo}
                        onChange={handleChange}
                        size="small"
                        fullWidth
                        error={!!errorTiempo} // Muestra un error si hay texto en errorNombre
                        helperText={errorTiempo} // Texto de ayuda bajo el campo
                        slotProps={{
                          input: {
                            startAdornment: (
                              <InputAdornment position="start">
                                <Tooltip
                                  title="Tiempo que debe transcurrir para que se genere la alerta."
                                  placement="top"
                                >
                                  <ErrorIcon fontSize="small" />
                                </Tooltip>
                              </InputAdornment>
                            ),
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <FormControl variant="outlined" size="small" fullWidth>
                        <InputLabel>Tipo Marcado</InputLabel>
                        <Select
                          label="Tipo Marcado"
                          name="tipo_marcado"
                          value={configuracion.tipo_marcado}
                          onChange={handleChange}
                          startAdornment={
                            <InputAdornment position="start">
                              <Tooltip
                                title="Indica donde se genera la alerta en relación a las geocercas."
                                placement="top"
                              >
                                <ErrorIcon fontSize="small" />
                              </Tooltip>
                            </InputAdornment>
                          }
                        >
                          <MenuItem value={0}>General</MenuItem>
                          <MenuItem value={1}>Dentro</MenuItem>
                          <MenuItem value={2}>Fuera</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </>
                )}
              </>
            )}
          </Grid>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          gap: 2,
          p: 2,
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            if (
              !errorNombre &&
              !errorTiempo &&
              !errorVeces &&
              !errorVelocidad &&
              !errorDistancia
            ) {
              // Verifica si no hay errores antes de cualquier acción
              if (!viewNew) {
                handleOpenSaveDialog(); // Abrir modal de confirmación si viewNew es false
              } else {
                handleGuardarPrincipal(); // Guardar directamente si viewNew es true
                if (viewNew) nextComponent(0);
              }
            }
          }}
          startIcon={<SaveIcon />}
          disabled={isFormEmpty}
          sx={{ width: "150px", padding: 2 }}
        >
          {viewNew ? "Siguiente" : "Guardar"}
        </Button>
        {/** Botón NUEVO */}
        {!viewNew && ( // ✅ Ajuste: Mostrar el botón si viewNew es false
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenCancelDialog} // Abrir modal de cancelar
            startIcon={<CancelIcon />}
            sx={{ width: "150px", padding: 2 }}
          >
            Cancelar
          </Button>
        )}
        {/* // Modal de confirmación para cancelar */}
        <Dialog open={cancelDialogOpen} onClose={handleCloseCancelDialog}>
          <DialogTitle>Confirmar cancelación</DialogTitle>
          <DialogContent>
            <DialogContentText>
              ¿Estás seguro de que deseas cancelar esta operación? Los cambios
              realizados no se guardarán.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseCancelDialog} color="primary">
              No
            </Button>
            <Button
              onClick={() => {
                resetForm(); // Lógica al confirmar la cancelación
                setCancelDialogOpen(false); // Cerrar modal
              }}
              color="secondary"
              autoFocus
            >
              Sí, cancelar
            </Button>
          </DialogActions>
        </Dialog>
        {/* // Modal para guardar si view es false */}
        <Dialog open={saveDialogOpen} onClose={handleCloseSaveDialog}>
          <DialogTitle>Confirmar guardado</DialogTitle>
          <DialogContent>
            <DialogContentText>
              ¿Estás seguro de que deseas guardar los cambios realizados?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseSaveDialog} color="primary">
              Cancelar
            </Button>
            <Button
              onClick={() => {
                handleGuardarPrincipal(); // Guardar cambios
                setSaveDialogOpen(false); // Cerrar modal
              }}
              color="secondary"
              autoFocus
            >
              Guardar
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
};

export default NuevaAlertaForm;
