// Monitor.js
import React, { useState,useEffect } from 'react'; 
import { Container, CssBaseline, Typography, Box, IconButton, useMediaQuery, useTheme, AppBar, Toolbar, Paper,Menu, MenuItem,Divider } 
from '@mui/material';
import { FilterList, List as ListIcon, Map as MapIcon,HelpOutline as HelpOutlineIcon  } 
from '@mui/icons-material';

import FilterBar from '../monitor/FilterBar'; 
import AlertList from '../monitor/AlertList';
import MapView from '../monitor/MapView';
import GoogleMapView from '../monitor/GoogleMapView';
// import FilterListIcon from '@mui/icons-material/FilterList';

import { FilterList as FilterListIcon, FilterListOff as FilterListOffIcon } from '@mui/icons-material';


import { motion, AnimatePresence } from 'framer-motion';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useLoading } from '../../contexts/LoadingContext';
import { OpenInNew as OpenInNewIcon } from '@mui/icons-material';
import Joyride, { STATUS } from 'react-joyride';
import ClonedAlertOverlay from '../monitor/ClonedAlertOverlay'; 
import { MapOutlined as SwitchMapIcon } from '@mui/icons-material';
import { DirectionsCar, Place } from '@mui/icons-material'; // Agregar imports
import { userService } from '../../services/apiService';
// const sharedIconButtonStyle = {
//   position: 'absolute',
//   top: 10,
//   backgroundColor: 'white',
// };

const sharedIconButtonStyle = {
  position: 'absolute',
  top: 10,
  backgroundColor: theme => theme.palette.background.paper,
  '&:hover': {
    backgroundColor: theme => theme.palette.action.hover,
  },
};

const Monitor = () => {
  const [showAlertList, setShowAlertList] = useState(true);
  const [showFilterBar, setShowFilterBar] = useState(true);
  const [expandMap, setExpandMap] = useState(false);
  const [mapCenter, setMapCenter] = useState([21.1619, -86.8515]);
  const [alerts, setAlerts] = useState([]);
  const [activeAlert, setActiveAlert] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [runTour, setRunTour] = useState(false);
  const [clonedAlert, setClonedAlert] = useState(null);
  const [tourAlertId, setTourAlertId] = useState(null);
  const { setIsLoading, setLoadingMessage } = useLoading(); 
  const [mapProvider, setMapProvider] = useState('leaflet'); 
  const [mapMenuAnchor, setMapMenuAnchor] = useState(null);
  const [viewMode, setViewMode] = useState('geocercas');

  const [filters, setFilters] = useState({
    horaInicial: null,
    horaFinal: null,
    vehiculo: '',
    tipoAlerta: '',
  });
  const [componentsLoaded, setComponentsLoaded] = useState({
    map: false,
    alerts: false
  });


  const toggleAlertList = () => setShowAlertList(!showAlertList);
  const toggleFilterBar = () => setShowFilterBar(!showFilterBar);
  const toggleExpandMap = () => setExpandMap(!expandMap);
 

  const handleComponentLoad = (component) => {
    setComponentsLoaded(prev => {
      const newState = { ...prev, [component]: true };
      
      // Si todos los componentes están cargados
      if (Object.values(newState).every(loaded => loaded)) {
        setIsLoading(false);
      }
      
      return newState;
    });
  };

  useEffect(() => {
    setIsLoading(true);
    setLoadingMessage('Cargando módulo de monitoreo...');
  }, []);

  useEffect(() => {
    const isNewUser = localStorage.getItem('nuevo_usuario') === '1';
    if (isNewUser) {
      startTour();
      // Reset the new user flag after starting tour
      //localStorage.setItem('nuevo_usuario', '0');
      userService.firsttimeoff().catch(error => {
        console.error('Error updating new user status:', error);
      });
      localStorage.setItem('nuevo_usuario', '0');

    }
  }, []); // Empty dependency array means this runs once on mount

  const handleMapIconClick = (location, alert) => {
    setMapCenter([location.lat, location.lng]);
    setActiveAlert(alert);
  };

  const handleClearActiveAlert = () => {
    setActiveAlert(null);
  };

  const handleMapMenuOpen = (event) => {
    setMapMenuAnchor(event.currentTarget);
  };

  const handleMapMenuClose = () => {
    setMapMenuAnchor(null);
  };

  const handleMapProviderChange = (provider) => {
    setMapProvider(provider);
    handleMapMenuClose();
  };

  const handleNewAlert = (alert) => {
    setAlerts(prevAlerts => {
      const newAlerts = {
        ...prevAlerts,
        [alert.UID_Vehiculo]: alert
      };
      
      // Mantener la alerta del tour si existe
      if (clonedAlert) {
        newAlerts[clonedAlert.UID_Vehiculo] = clonedAlert;
      }
      
      return newAlerts;
    });
  };


  const handleDetach = () => {
    const width = window.innerWidth * 0.8;
    const height = window.innerHeight * 0.8;
    const left = (window.screen.width - width) / 2;
    const top = (window.screen.height - height) / 2;
  
    const newWindow = window.open(
      '/monitor-standalone',
      'MonitorWindow',
      `width=${width},
       height=${height},
       left=${left},
       top=${top},
       menubar=no,
       toolbar=no,
       location=no,
       status=no`
    );
  
    // Opcional: Establecer comunicación entre ventanas
    window.addEventListener('message', (event) => {
      if (event.data.type === 'ALERT_UPDATE') {
        // Manejar actualizaciones de alertas
        handleNewAlert(event.data.alert);
      }
    });
  };

  const startTour = () => {

    setMapProvider('leaflet');

    if (Object.keys(alerts).length === 0) {
      // Crear una alerta dummy si no hay alertas
      const sampleAlert = {
        UID_Vehiculo: 'tour-vehicle', // Asegúrate de usar 'UID_Vehiculo' en lugar de 'id'
        unidad: 'Unidad Dummy',
        chofer: 'Chofer Dummy',
        telefono: '1234567890',
        fecha: new Date().toLocaleString(),
        alerta: 'Alerta Dummy',
        ubicacion: { lat: 21.1619, lng: -86.8515, direccion: 'Dirección Dummy' },
        adicional: { mensaje: 'Mensaje Dummy' },
        estado: 'verde'
      };
      //setAlerts({ [dummyAlert.UID_Vehiculo]: dummyAlert });
      setAlerts(prevAlerts => ({
        ...prevAlerts,
        [sampleAlert.UID_Vehiculo]: sampleAlert
      }));

      setClonedAlert(sampleAlert); // Establecer la alerta clonada
      setTourAlertId(sampleAlert.UID_Vehiculo);
    } else {
      // Clonar la primera alerta
      const firstKey = Object.keys(alerts)[0];
      const firstAlert = alerts[firstKey];
      const clonedAlert = { 
        ...firstAlert, 
        UID_Vehiculo: `${firstAlert.UID_Vehiculo}-clone` 
      };
      setAlerts(prevAlerts => ({
        ...prevAlerts,
        [clonedAlert.UID_Vehiculo]: clonedAlert
      }));
      setClonedAlert(clonedAlert); // Establecer la alerta clonada
      setTourAlertId(clonedAlert.UID_Vehiculo);
      setMapCenter([clonedAlert.ubicacion.lat, clonedAlert.ubicacion.lng]);
    }
    setRunTour(true);
  };

  const steps = [
    
    {
      target: '.detach-button',
      content: 'Puedes abrir el monitor en una nueva ventana para tener mejor visibilidad.',
      disableBeacon: true,
    },
    {
      target: '.filter-bar-button',
      content: 'Aquí puedes filtrar las alertas por fecha, unidad y tipo de alerta.',
      disableBeacon: true,
    },
       {
      target: '.expand-map',
      content: 'Puedes expandir el mapa.',
      disableBeacon: true,
    },    
    {
      target: '.Switch-MapIcon',
      content: 'Cambiar de proveedor de mapa.',
      disableBeacon: true,
    },
    {
      target: '.alert-list',
      content: 'Cada alerta muestra información importante como el color en los bordes izquierdo y superior que nos dicen la importancia de la misma.',
      disableBeacon: true,
    },        
    {
      target: '.alert-iconoalert',
      content: 'Icono que nos muestra el tipo de alerta.',
      placement: 'bottom',
      disableBeacon: true,
    },
    {
      target: '.alert-unidad',
      content: 'Esta es la unidad involucrada en la alerta.',
      placement: 'bottom',
      disableBeacon: true,
    },
    {
      target: '.alert-chofer',
      content: 'Este es el chofer asignado a la unidad.',
      placement: 'bottom',
      disableBeacon: true,
    },
    {
      target: '.alert-telefono',
      content: 'Este es el numero de telefono en que se generó la alerta.',
      placement: 'bottom',
      disableBeacon: true,
    },
    {
      target: '.alert-fecha',
      content: 'Esta es la fecha y hora en que se generó la alerta.',
      placement: 'bottom',
      disableBeacon: true,
    },
    {
      target: '.alert-mapredirect',
      content: 'Boton para ir al mapa y nos muestre el lugar exacto de la alerta.',
      placement: 'bottom',
      disableBeacon: true,
    },
    {
      target: '.alert-alerta',
      content: 'Este es el evento que se genera.',
      placement: 'bottom',
      disableBeacon: true,
    },
    {
      target: '.alert-mensaje',
      content: 'Este es el mensaje detallado de la alerta.',
      placement: 'bottom',
      disableBeacon: true,
    },
    {
      target: '.alert-direccion',
      content: 'Esta es la dirección donde se generó la alerta.',
      placement: 'bottom',
      disableBeacon: true,
    },

    {
      target: '.map-view',
      content: 'El mapa muestra la ubicación de todas las alertas en tiempo real.',
      disableBeacon: true,
    },
    
  ];


  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>

    <Joyride
        steps={steps}
        run={runTour}
        continuous
        showSkipButton
        disableScrolling={true}
        disableBeacon={true}
        styles={{
          options: {
            zIndex: 10000,
          },
        }}
        locale={{
          back: 'Atrás',
          close: 'Cerrar',
          last: 'Finalizar',
          next: 'Siguiente',
          skip: 'Saltar',
        }}
        callback={(data) => {
          const { index, status, type } = data;
          if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
            setRunTour(false);
            setTourAlertId(null);
            
            setAlerts(prevAlerts => {
              const newAlerts = { ...prevAlerts };
              if (clonedAlert) {
                delete newAlerts[clonedAlert.UID_Vehiculo];
              }
              return newAlerts;
            });
            setClonedAlert(null);

          }

          if (type === 'step:before') {
            const currentStep = steps[index];
            if (currentStep.target === '.alert-mapredirect') {
              if (clonedAlert) {
                setMapCenter([clonedAlert.ubicacion.lat, clonedAlert.ubicacion.lng]);
                setActiveAlert(clonedAlert);
                setTimeout(() => {
                  setActiveAlert(null);
                }, 5000);
              }
            } else if (currentStep.target.startsWith('.alert-')) {
            }
          }

          // if (type === 'step:after') {
          //   const currentStep = steps[index];
          //   if (currentStep.target === '.alert-mapredirect') {
          //     if (clonedAlert) {                
          //       setTimeout(() => {
          //         setActiveAlert(null);
          //       }, 5000);
          //     }
          //   } else if (currentStep.target.startsWith('.alert-')) {
          //   }
          // }

        }}
      />
      <AnimatePresence>
        <motion.div
          initial={{ height: 0, opacity: 0 }}
          animate={{ height: "auto", opacity: 1 }}
          exit={{ height: 0, opacity: 0 }}
          transition={{ duration: 0.3 }}
        >
          <Box sx={{ 
           height: '100vh', 
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden'
          }}>


                    {/* <Paper elevation={3} sx={{ p: 1, mb: 2 }}> */}
          <Paper elevation={3} sx={{ 
            // height: '100%',
            // display: 'flex',
            // flexDirection: 'column',
            // overflow: 'hidden'
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden'
          }}>
            <CssBaseline />           

            <AppBar 
              position="static" 
              sx={{ 
                mb: 0,
                backgroundColor: 'transparent',
                boxShadow: 'none'
              }}
            >
              <Toolbar
                sx={{ 
                  minHeight: '48px', // Reduce toolbar height
                  p: 0,
                  mb: 0
                }}
              >
                <Typography 

                  variant="h4" gutterBottom
                  // component="h1"
                  sx={{ 
                    flexGrow: 1,
                    // fontWeight: 500,
                    color: (theme) => theme.palette.text.primary,
                    py: 0,
                     mb: 0 // Remove bottom margin
                  }}
                >
                  Monitor en Tiempo Real
                </Typography>
              </Toolbar>
                {/* <Typography variant="h4" gutterBottom>
                      Formulario Contacto
                    </Typography> */}

              <Divider sx={{ my: 0.5, borderColor: "red" }} />

            </AppBar>

            {/* {showFilterBar && 
            <FilterBar 
            onFilter={setFilters}
            />
            } */}

<AnimatePresence>
  {showFilterBar && (
    <motion.div
      initial={{ opacity: 0, height: 0 }}
      animate={{ opacity: 1, height: 'auto' }}
      exit={{ opacity: 0, height: 0 }}
      transition={{ duration: 0.3, ease: "easeInOut" }}
    >
      <FilterBar 
        onFilter={setFilters}
      />
    </motion.div>
  )}
</AnimatePresence>

            <Box
              my={1}
              display="flex"
              flexDirection={isMobile ? 'column' : 'row'}
              alignItems="stretch"
              sx={{ width: '100%',
                overflow: 'hidden',
                // pt: 0,
               }}              
            >
              <Box
                flex={expandMap ? 0 : 1.2}
                mr={2}
                sx={{ 
                  display: expandMap ? 'none' : 'block',
                  height: '100%', // Añadir altura completa
                  //minHeight: '600px' // Altura mínima para mantener consistencia
                  overflow: 'hidden'
                }}
                // sx={{ display: expandMap ? 'none' : 'block' }}
                className="alert-list"
              >
                <AlertList
                  onMapIconClick={handleMapIconClick}
                  onNewAlert={handleNewAlert}
                  onLoadComplete={() => handleComponentLoad('alerts')}
                  tourAlertId={tourAlertId}
                  filters={filters} 
                />
                {clonedAlert && (
                <ClonedAlertOverlay 
                  alert={clonedAlert} 
                  onClose={() => setClonedAlert(null)}                 
                  className="ClonedAlertOverlay"
                />
              )}

              </Box>
              <Box
                flex={expandMap ? 1 : 1.8}
                ml={showAlertList && !expandMap ? 2 : 0}
                style={{ 
                  position: 'relative' ,
                    height: '100%',
                    transition: 'all 0.3s ease'
                }}
                sx={{ 
                  width: '100%' ,
                  height: 'calc(100vh -200px)',// Añadir altura completa
                  //minHeight: 'calc(100vh - 120px)',  // Altura mínima para mantener consistencia
                  overflow: 'hidden'
                  
                }}
                className="map-view"
              >
              
                {mapProvider === 'leaflet' ? (
                  <MapView
                    center={mapCenter}
                    alerts={Object.values(alerts)}
                    activeAlert={activeAlert}
                    onClearActiveAlert={handleClearActiveAlert}
                    expandMap={expandMap} 
                    onLoadComplete={() => handleComponentLoad('map')}
                    viewMode={viewMode}
                  />
                ) : (
                  <GoogleMapView
                    center={mapCenter}
                    alerts={Object.values(alerts)}
                    activeAlert={activeAlert}
                    onClearActiveAlert={handleClearActiveAlert}
                    expandMap={expandMap}
                    onLoadComplete={() => handleComponentLoad('map')}
                    viewMode={viewMode}
                  />
                )}

                <Box
                  sx={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    zIndex: 1000,
                    padding: 1,
                    display: 'flex',
                    gap: 1,
                    //pointerEvents: 'none' // Allows clicking through to map
                  }}
                >


                  <IconButton
                    onClick={handleDetach}
                    title="Abrir en nueva ventana"
                    className="detach-button"
                    sx={{ 
                      ...sharedIconButtonStyle, 
                      right: 160,
                      color: theme => theme.palette.text.primary 
                    }}
                  >
                    <OpenInNewIcon />
                  </IconButton>
                    
                  <IconButton
                    onClick={toggleFilterBar}
                    title={showFilterBar ? "Ocultar filtros" : "Mostrar filtros"}
                    className="filter-bar-button"
                    sx={{ 
                      ...sharedIconButtonStyle, 
                      right: 130,
                      color: theme => theme.palette.text.primary 
                    }}
                  >
                    {showFilterBar ? <FilterListOffIcon /> : <FilterListIcon />}
                  </IconButton>                  
                                  
                    <IconButton 
                      onClick={handleMapMenuOpen}
                      title="Cambiar proveedor de mapas" 
                      className="Switch-MapIcon"
                      sx={{ 
                        ...sharedIconButtonStyle, 
                        right: 100,
                        color: theme => theme.palette.text.primary 
                      }}
                    >
                      <SwitchMapIcon />
                    </IconButton>
                    
                    <Menu
                      anchorEl={mapMenuAnchor}
                      open={Boolean(mapMenuAnchor)}
                      onClose={handleMapMenuClose}
                      PaperProps={{
                        sx: {
                          backgroundColor: theme => theme.palette.background.paper,
                          color: theme => theme.palette.text.primary
                        }
                      }}
                    >
                      <MenuItem 
                        onClick={() => handleMapProviderChange('leaflet')}
                        selected={mapProvider === 'leaflet'}
                      >
                        OpenStreetMap
                      </MenuItem>
                      <MenuItem 
                        onClick={() => handleMapProviderChange('google')}
                        selected={mapProvider === 'google'}
                      >
                        Google Maps
                      </MenuItem>
                    </Menu>

                    <IconButton
                    onClick={() => setViewMode(prev => prev === 'geocercas' ? 'vehiculos' : 'geocercas')} 
                    title={viewMode === 'geocercas' ? "Cambiar a vista de vehículos" : "Cambiar a vista de geocercas"}
                    sx={{ 
                      ...sharedIconButtonStyle, 
                      right: 70,
                      color: theme => theme.palette.text.primary 
                    }}
                  >
                    {viewMode === 'geocercas' ? <DirectionsCar /> : <Place />}
                  </IconButton>

                    <IconButton
                      onClick={toggleExpandMap}
                      title="Expandir mapa"
                      className="expand-map"
                      sx={{ 
                        ...sharedIconButtonStyle, 
                        right: 40,
                        color: theme => theme.palette.text.primary 
                      }}
                    >
                      {expandMap ? <ListIcon /> : <MapIcon />}
                    </IconButton>

                    
                    <IconButton
                      onClick={startTour}
                      title="Ayuda"
                      sx={{ 
                        ...sharedIconButtonStyle, 
                        right: 10,
                        color: theme => theme.palette.text.primary 
                      }}
                    >
                      <HelpOutlineIcon />
                    </IconButton>
                </Box>
              </Box>
            </Box>
          </Paper>
          </Box>
        </motion.div>
      </AnimatePresence>
    </LocalizationProvider>
  );
};

export default Monitor;