// src/config/apiConfig.js
export const API_CONFIG = {
    BASE_URL: process.env.REACT_APP_API_BASE_URL || 'http://localhost:5241/api',
    X_API_KEY: process.env.REACT_APP_X_API_KEY || '67606bc32fddba293489a9b4',
    TIMEOUT: 10000,
    HEADERS: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
  };
