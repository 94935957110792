import { Box } from "@mui/material";
import React from "react";

export default function ImageContainer({ src }) {
  return (
    <Box
      sx={{
        maxWidth: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        component="img"
        src={src}
        alt="not-found"
        sx={{
          maxWidth: "100%",
          maxHeight: "250px",
          objectFit: "contain",
        }}
      />
    </Box>
  );
}
