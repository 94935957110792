import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Checkbox,
  Tooltip,
  TextField,
  Paper,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import TelegramIcon from "@mui/icons-material/Telegram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { toast } from "react-toastify";
import { isEmptyArray } from "formik";
import { useTheme } from "@mui/material/styles";
import {
  contactoService,
  telegramService,
} from "../../../../services/apiService";
import AgregarContactoModal from "./AgregarContactoModal";

export default function ModalListaContactos({
  configuracion,
  actualizarComponente,
  handleGuardarPrincipal,
  idAlerta,
}) {
  const [contactos, setContactos] = useState([]);
  const [selectedContactos, setSelectedContactos] = useState([]);
  const [searchName, setSearchName] = useState("");
  const [filteredContactos, setFilteredContactos] = useState(contactos);
  const [checkedState, setCheckedState] = useState({});
  const [telegramLink, setTelegramLink] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const theme = useTheme();

  const cargarContactos = async () => {
    try {
      const response = await contactoService.getContactos("", 1);
      if (response.statusCode === 200) setContactos(response.data);
    } catch (error) {
      console.error("Error cargando contactos: ", error);
    }
  };
  const crearContacto = async (contacto) => {
    try {
      const nuevoContacto = await contactoService.createContacto(contacto);
      setContactos((prev) => [...prev, nuevoContacto.data]);
      toast.success("Contacto creado exitosamente");
      setCheckedState((prevState) => ({
        ...prevState,
        [nuevoContacto.id]: {
          notificacionSms: nuevoContacto.notificacionSms,
          notificacionWhatsapp: nuevoContacto.notificacionWhatsapp,
          notificacionCorreo: nuevoContacto.notificacionCorreo,
          notificacionTelegram: nuevoContacto.notificacionTelegram,
          notificacionVoz: nuevoContacto.notificacionVoz,
        },
      }));
    } catch (error) {
      toast.error("Ha ocurrido un error al crear el contacto");
    }
  };
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleSelectedContacto = (contacto, propiedad = "") => {
    setSelectedContactos((prevState) => {
      // Verificar si ya existe un contacto con el mismo ID en el estado
      const contactoExistente = prevState.find((c) => c._id === contacto.id);

      if (propiedad === "id") {
        if (contactoExistente) {
          return prevState.filter((c) => c._id !== contacto.id);
        } else {
          // Agregar el contacto solo si la propiedad seleccionada es "id"
          return [
            ...prevState,
            {
              _id: contacto.id,
              sms: checkedState[contacto.id]?.notificacionSms,
              whatsapp: checkedState[contacto.id]?.notificacionWhatsapp,
              telegram: checkedState[contacto.id]?.notificacionTelegram,
              correo: checkedState[contacto.id]?.notificacionCorreo,
              voz: checkedState[contacto.id]?.notificacionVoz,
            },
          ];
        }
      } else {
        // Si la propiedad no es "id", actualizar el contacto existente si ya está en el estado
        if (contactoExistente) {
          return prevState.map((c) =>
            c._id === contacto.id
              ? {
                  ...c,
                  [propiedad]: !c[propiedad],
                }
              : c
          );
        }
      }
      // Retornar el estado sin cambios si no se cumplen las condiciones anteriores
      return prevState;
    });
  };

  function isEnableButton() {
    return selectedContactos.length > 0 && idAlerta.length > 0;
  }

  const handleCheckboxChange = (id, propiedad) => {
    setCheckedState((prevState) => {
      const updatedState = {
        ...prevState,
        [id]: {
          ...prevState[id],
          [propiedad]: !prevState[id]?.[propiedad],
        },
      };
      if (propiedad === "notificacionTelegram") {
        handleTelegramChange(id);
      }
      return updatedState;
    });
  };

  const handleGenerateLink = async (contactoId) => {
    try {
      const linkResponse = await telegramService.getLink(contactoId);
      const link = linkResponse.data.link;
      if (link) {
        setTelegramLink((prevLinks) => ({
          ...prevLinks,
          [contactoId]: link,
        }));
        return link;
      } else {
        toast.error("Ha ocurrido un error generando el link de telegram");
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleTelegramChange = async (contactoId) => {
    const responseContacto = await contactoService.getContactos(contactoId, 1);
    const dataContacto = responseContacto.data;
    if (dataContacto[0].telegram.length === 0) {
      const response = await telegramService.getLink(contactoId);
      const link = response.data.link;
      setTelegramLink((prevLinks) => ({
        ...prevLinks,
        [contactoId]: link,
      }));
    }
  };

  useEffect(() => {
    cargarContactos();
    setSelectedContactos((prevSelected) =>
      contactos.filter((contacto) =>
        prevSelected.some((selected) => selected._id === contacto.id)
      )
    );
  }, []);

  useEffect(() => {
    const filtered = contactos.filter((contacto) => {
      const matchesName = contacto.nombreContacto
        .toLowerCase()
        .includes(searchName.toLowerCase());
      return matchesName;
    });
    setFilteredContactos(filtered);
  }, [contactos, searchName]);

  useEffect(() => {
    actualizarComponente("contactos", selectedContactos);
  }, [selectedContactos]);

  useEffect(() => {
    if (!isEmptyArray(configuracion.contactos)) {
      //Cargamos la data recuperada del API
      if (Object.keys(checkedState).length === 0) {
        const initCheckedState = {};
        configuracion.contactos.forEach((contacto) => {
          initCheckedState[contacto._id] = {
            id: true,
            notificacionSms: contacto.sms || false,
            notificacionWhatsapp: contacto.whatsapp || false,
            notificacionCorreo: contacto.correo || false,
            notificacionTelegram: contacto.telegram || false,
            notificacionVoz: contacto.voz || false,
          };
        });
        setCheckedState(initCheckedState);
        setSelectedContactos(
          configuracion.contactos.map((contacto) => ({
            _id: contacto._id,
            ...contacto,
          }))
        );
      }
    }
  }, [configuracion.contactos]);

  const hasTelegramActive = Object.values(checkedState).some(
    (state) => state?.notificacionTelegram
  );
  return (
    <>
      <Grid container spacing={3} height={550}>
        <Grid item xs={12}>
          <Paper sx={{ padding: 3 }}>
            {/*Titulo y buscador*/}
            <Box
              sx={{
                marginBottom: 2,
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              Contactos
              {/* Filtros */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <TextField
                  label="Buscar por nombre"
                  variant="outlined"
                  size="small"
                  value={searchName}
                  onChange={(e) => setSearchName(e.target.value)}
                  onClick={(e) => e.stopPropagation()}
                />
              </Box>
            </Box>

            {/* Tabla de contactos*/}
            <TableContainer
              sx={{
                maxHeight: contactos.length > 5 ? 355 : "auto",
                overflowY: contactos.length > 5 ? "auto" : "visible",
                "@media (max-width: 600px)": {
                  maxHeight: "auto", // Ajusta el alto en pantallas pequeñas
                },
              }}
            >
              <Table
                sx={{
                  tableLayout: "fixed",
                  width: "100%",
                  "& th, & td": {
                    whiteSpace: "nowrap", // Evita el desbordamiento de texto
                    textOverflow: "ellipsis", // Trunca texto largo con puntos suspensivos
                    overflow: "hidden",
                  },
                  "& th": {
                    fontWeight: "bold",
                    position: "sticky",
                    top: 0,
                    zIndex: 1,
                    backgroundColor: theme.palette.background.paper,
                  },
                  "@media (max-width: 600px)": {
                    "& td, & th": {
                      fontSize: "12px", // Tamaño de texto más pequeño en pantallas pequeñas
                      padding: "8px",
                    },
                  },
                }}
                // Fija el encabezado al hacer scroll
                aria-label="contact table"
              >
                <TableHead>
                  <TableRow>
                    {/* Nombre del contacto */}
                    <TableCell style={{ width: "auto" }}></TableCell>

                    {/* Telefono */}
                    <TableCell style={{ width: "auto" }} align="center">
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: 1,
                          flexDirection: {
                            xs: "column", // Ícono encima del texto en pantallas pequeñas
                            md: "row",
                            sm: "column", // Ícono al lado del texto en pantallas grandes
                          },
                        }}
                      >
                        <PhoneIcon color="error" />
                        <Typography variant="body2">SMS</Typography>
                      </Box>
                    </TableCell>

                    {/* Correo */}
                    <TableCell style={{ width: "auto" }} align="center">
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: 1,
                          flexDirection: {
                            xs: "column", // Ícono encima del texto en pantallas pequeñas
                            md: "row",
                            sm: "column", // Ícono al lado del texto en pantallas grandes
                          },
                        }}
                      >
                        <EmailIcon color="error" />
                        <Typography variant="body2">Correo</Typography>
                      </Box>
                    </TableCell>

                    {/* Telegram */}
                    <TableCell style={{ width: "auto" }} align="center">
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: 1,
                          flexDirection: {
                            xs: "column", // Ícono encima del texto en pantallas pequeñas
                            md: "row",
                            sm: "column", // Ícono al lado del texto en pantallas grandes
                          },
                        }}
                      >
                        <TelegramIcon color="info" />
                        <Typography variant="body2">Telegram</Typography>
                      </Box>
                    </TableCell>

                    {/* WhatsApp */}
                    <TableCell style={{ width: "auto" }} align="center">
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: 1,
                          flexDirection: {
                            xs: "column", // Ícono encima del texto en pantallas pequeñas
                            md: "row",
                            sm: "column", // Ícono al lado del texto en pantallas grandes
                          },
                        }}
                      >
                        <WhatsAppIcon color="success" />
                        <Typography variant="body2">WhatsApp</Typography>
                      </Box>
                    </TableCell>

                    {/* Voz */}
                    <TableCell align="center" style={{ width: "auto" }}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: 1,
                          flexDirection: {
                            xs: "column", // Ícono encima del texto en pantallas pequeñas
                            md: "row",
                            sm: "column", // Ícono al lado del texto en pantallas grandes
                          },
                        }}
                      >
                        <KeyboardVoiceIcon color="primary" />
                        <Typography variant="body2">Voz</Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredContactos.map((contacto) => (
                    <TableRow key={contacto.id}>
                      {/* Nombre del Contacto */}
                      <TableCell>
                        <Box display="flex" alignItems="center" gap={1}>
                          <Checkbox
                            checked={checkedState[contacto.id]?.id || false}
                            onChange={() => {
                              handleCheckboxChange(contacto.id, "id");
                              handleSelectedContacto(contacto, "id");
                            }}
                          />
                          {contacto.nombreContacto}
                        </Box>
                      </TableCell>

                      {/*Celda telefono */}
                      <TableCell align="center">
                        <Checkbox
                          checked={
                            checkedState[contacto.id]?.notificacionSms || false
                          }
                          onChange={() => {
                            handleCheckboxChange(
                              contacto.id,
                              "notificacionSms"
                            );
                            handleSelectedContacto(contacto, "sms");
                          }}
                        />
                      </TableCell>

                      {/*Celda correo */}
                      <TableCell align="center">
                        <Checkbox
                          checked={
                            checkedState[contacto.id]?.notificacionCorreo ||
                            false
                          }
                          onChange={() => {
                            handleCheckboxChange(
                              contacto.id,
                              "notificacionCorreo"
                            );
                            handleSelectedContacto(contacto, "correo");
                          }}
                        />
                      </TableCell>

                      {/* Telegram */}
                      <TableCell align="center">
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          sx={{
                            flexDirection: "row",
                            gap: 1,
                          }}
                        >
                          <Checkbox
                            checked={
                              checkedState[contacto.id]?.notificacionTelegram ||
                              false
                            }
                            onChange={() =>
                              handleCheckboxChange(
                                contacto.id,
                                "notificacionTelegram"
                              )
                            }
                          />
                          {/* Link de telegram */}
                          {/* Celda condicional o marcador de posición */}
                          {checkedState[contacto.id]?.notificacionTelegram && (
                            <Box
                              display="flex"
                              flexDirection="column"
                              alignItems="center"
                              sx={{ gap: 0.5 }}
                            >
                              <Tooltip
                                title={
                                  contacto.telegram
                                    ? "Telegram vinculado"
                                    : "Únete al bot"
                                }
                                placement="right"
                              >
                                <TelegramIcon
                                  color="info"
                                  style={{ cursor: "pointer" }}
                                  onClick={async () => {
                                    let url;
                                    if (contacto.telegram) {
                                      url = "https://web.telegram.org/";
                                    } else {
                                      url = await handleGenerateLink(
                                        contacto.id
                                      );
                                    }
                                    window.open(
                                      url,
                                      "_blank",
                                      "noopener,noreferrer"
                                    );
                                  }}
                                />
                              </Tooltip>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  gap: 0.5,
                                  flexDirection: {
                                    md: "row",
                                    xs: "column", // Ícono encima del texto en pantallas pequeñas
                                    sm: "column", // Ícono al lado del texto en pantallas grandes
                                  },
                                }}
                              >
                                <Typography variant="caption" color="primary">
                                  {
                                    <a
                                      href={"https://web.telegram.org/"}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      style={{
                                        textDecoration: "none",
                                        color: "#1976d2",
                                      }}
                                      onClick={async (e) => {
                                        e.preventDefault();
                                        if (contacto.telegram) {
                                          window.open(
                                            "https://web.telegram.org/",
                                            "_blank",
                                            "noopener,noreferrer"
                                          );
                                        } else {
                                          const link = await handleGenerateLink(
                                            contacto.id
                                          );
                                          window.open(
                                            link,
                                            "_blank",
                                            "noopener,noreferrer"
                                          );
                                        }
                                      }}
                                    >
                                      {contacto.telegram
                                        ? "Ir a telegram"
                                        : "Únete al bot"}
                                    </a>
                                  }
                                </Typography>
                              </Box>
                            </Box>
                          )}
                        </Box>
                      </TableCell>

                      {/*Celda Whatsapp */}
                      <TableCell align="center">
                        <Checkbox
                          checked={
                            checkedState[contacto.id]?.notificacionWhatsapp ||
                            false
                          }
                          onChange={() => {
                            handleCheckboxChange(
                              contacto.id,
                              "notificacionWhatsapp"
                            );
                            handleSelectedContacto(contacto, "whatsapp");
                          }}
                        />
                      </TableCell>

                      {/* Voz */}
                      <TableCell align="center">
                        <Checkbox
                          checked={
                            checkedState[contacto.id]?.notificacionVoz || false
                          }
                          onChange={() => {
                            handleCheckboxChange(
                              contacto.id,
                              "notificacionVoz"
                            );
                            handleSelectedContacto(contacto, "voz");
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            {/*Botones agregar contacto y guardar */}
            <Box
              sx={{
                marginTop: 2,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Button
                variant="contained"
                color="error"
                sx={{ paddingX: 3, paddingY: 2 }}
                onClick={openModal}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 2,
                  }}
                >
                  Agregar Contacto
                  <AddCircleIcon />
                </Box>
              </Button>
              <Button
                sx={{ width: "150px", padding: 2 }}
                variant="contained"
                startIcon={<SaveIcon />}
                disabled={!isEnableButton()}
                onClick={() => handleGuardarPrincipal()}
              >
                Guardar
              </Button>
            </Box>

            {/* Componente Modal */}
            <AgregarContactoModal
              open={isModalOpen}
              onClose={closeModal}
              crearContacto={crearContacto}
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
