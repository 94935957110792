import { Buffer } from 'buffer';
//process.env.REACT_APP_ENCRYPTION_KEY || 
const SECRET_KEY = '!&fbf7w2oex$nbv@car@!a_n36t10mk)!@gp&i=jpc*anxs%-j';

const base64UrlEncode = (str) => {
    return Buffer.from(str, 'utf-8').toString('base64')
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=+$/, '');
  };
  
  const base64UrlDecode = (str) => {
    str = str.replace(/-/g, '+').replace(/_/g, '/');
    while (str.length % 4) {
      str += '=';
    }
    return Buffer.from(str, 'base64').toString('utf-8');
  };
  
  export const encrypt = (text) => {
    let b64 = Buffer.from(text, 'utf-8').toString('base64');
    let encrypted = '';
    for (let i = 0; i < b64.length; i++) {
      encrypted += String.fromCharCode(b64.charCodeAt(i) ^ SECRET_KEY.charCodeAt(i % SECRET_KEY.length));
    }
    return base64UrlEncode(encrypted);
  };
  
  export const decrypt = (encryptedText) => {
    let encrypted = base64UrlDecode(encryptedText);
    let b64 = '';
    for (let i = 0; i < encrypted.length; i++) {
      b64 += String.fromCharCode(encrypted.charCodeAt(i) ^ SECRET_KEY.charCodeAt(i % SECRET_KEY.length));
    }
    return Buffer.from(b64, 'base64').toString('utf-8');
  };