import React from 'react';
import { Box, Button, Typography, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo
    });
    // Aquí podrías enviar el error a un servicio de logging
    console.error('Error caught by boundary:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <ErrorFallback onReset={() => this.setState({ hasError: false })} />;
    }

    return this.props.children;
  }
}

// Componente que se muestra cuando hay un error
const ErrorFallback = ({ onReset }) => {
  const navigate = useNavigate();

  return (
    <Container>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
          textAlign: 'center',
          gap: 2
        }}
      >
        <Typography variant="h4" gutterBottom>
          ¡Oops! Algo salió mal
        </Typography>
        <Typography variant="body1" color="text.secondary" paragraph>
          Lo sentimos, ha ocurrido un error inesperado.
        </Typography>
        <Box sx={{ mt: 2, display: 'flex', gap: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              onReset();
              navigate('/');
            }}
          >
            Volver al inicio
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              window.location.reload();
            }}
          >
            Recargar página
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default ErrorBoundary;